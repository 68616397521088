import { observable, action, computed, autorun, makeObservable } from 'decorators';
import media from 'services/media';
import router from 'services/router';
import globalEvents from 'services/globalEvents';
import SearchStore from './search/search-store';
import auth from 'services/auth';

class SubNavStore {
  @observable mode = 'search';
  @observable showMode;

  @observable hasLobby = false;
  @observable unreadMessages = 0;

  @observable telehealthAppId;
  @observable telehealthNickname;
  @observable telehealthExpanded = false;

  constructor() {
    makeObservable(this);
    this.searchStore = new SearchStore({ onHide: () => this.hideMode() });
    this.showMode = this.isWide;

    this._eventDispose = globalEvents.on('oldframe.click', this.hideMode, this);
    this._unreadMessagesDispose = globalEvents.on('local.telehealth.chat.unread', this._unreadMessagesChange, this);
    this._hasLobbyChangedDispose = globalEvents.on('local.telehealth.lobby', this._hasLobbyChanged, this);
    this._telehealthSelectDispose = globalEvents.on('appointments.telehealth.select', this._showTelehealthRoom, this);

    this._searchSyncDispose = autorun(() => this._attachDetachSearch());

    // Telehealth could be running, but we might have lost the state
    globalEvents.emitLocal('appointments.telehealth.wakeup');
  }

  @computed get isSmall() {
    return media.is('xs');
  }

  @computed get isWide() {
    // This is a custom width just for this feature
    // PS: Older mac book airs have a max width of 1366, so 1400 wasn't quite small enough
    return media.currentWidth > 1350;
  }

  @computed get isSearch() {
    return this.mode === 'search';
  }

  @computed get isTelehealth() {
    return this.mode === 'telehealth';
  }

  @computed get telehealthRoute() {
    return {
      name: 'telehealth.waiting',
      params: { id: this.telehealthAppId },
      queryParts: { name: this.telehealthNickname }
    };
  }

  @computed get hasSideMenuAccess() {
    return auth.hasMenuAccess && !auth.isLockedOut;
  }

  @computed get sidePadding() {
    const { hasSideMenuAccess } = this;
    let sidePadding = hasSideMenuAccess ? (8 * 6) : 0;
    if (hasSideMenuAccess && this.showMode && this.isWide) {
      sidePadding = 8 * 46;
    }
    return sidePadding;
  }

  toggleSearch() {
    this._toggle('search');
    if (this.showMode) {
      this.searchStore.focus();
    }
  }

  toggleTelehealth() {
    this._toggle('telehealth');
  }

  @action onExpanded(e) {
    this.telehealthExpanded = e;
  }

  @action hideMode() {
    if (!this.isWide) {
      this.showMode = false;
    }
  }

  dispose() {
    this._eventDispose();
    this._hasLobbyChangedDispose();
    this._unreadMessagesDispose();
    this._telehealthSelectDispose();

    this._searchSyncDispose();
    this.searchStore.dispose();
  }

  @action _showTelehealthRoom(d) {
    if (d.appointmentId !== this.telehealthAppId) {
      if (!media.up('md') && d.appointmentId) {
        // If we are in a small screen then we need to open this up in a new page
        router.navigateToNewWindow('telehealth.waiting', { id: d.appointmentId }, { name: d.name || '' });
        return;
      }

      this.telehealthAppId = d.appointmentId;
      this.telehealthNickname = d.name;
    }

    this.showMode = true;
    this.mode = 'telehealth';
  }

  @action _toggle(mode) {
    if (this.mode !== mode) {
      this.mode = mode;
      this.showMode = false;
    }
    this.showMode = !this.showMode;
  }

  _attachDetachSearch() {
    if (this.showMode && this.isSearch) {
      this.searchStore.attachListeners();
    } else {
      this.searchStore.detachListeners();
    }
  }

  @action _unreadMessagesChange(data) {
    this.unreadMessages = data.unread;
  }

  @action _hasLobbyChanged(d) {
    this.hasLobby = d.hasLobby;
  }
}

export default new SubNavStore();