import { Fragment } from 'react';
import { Grid, Typography, ListItem, ListItemText, ListItemAvatar } from '@material-ui/core';
import Gravitar from 'components/gravitar';
import Button from 'components/button';
import NavLink from 'components/navLink';
import api from 'services/api';
import auth from 'services/auth';
import { toQueryString } from 'utils/query';
import { getLastGiven } from 'utils/name';
import { shortDate, longDate, getAge } from 'utils/date';

import { Pencil } from 'mdi-material-ui';

export const genderIdentityOptions = [
  'Male',
  'Female',
  'Transgender man/trans man/female-to-male (FTM)',
  'Transgender woman/trans woman/male-to-female (MTF)',
  'Genderqueer/gender nonconforming neither exclusively male nor female',
  'Decline to answer'
].map(m => ({ name: m, value: m }));

export const pronounsOptions = [
  'He/him',
  'She/her',
  'They/them'
].map(m => ({ name: m, value: m }));

export function clientNameMapping(client) {
  if (!client) { return ''; }
  return searchClientNameMapping(client).name;
}

export function clientSearchFields({ ignore } = {}) {
  return {
    loadOptions: search => searchClients(search, { ignore }),
    rowHeight: 72,
    variableHeight: true,
    renderItem: searchClient => renderClientSearchItem(searchClient.data, { hideAge: true })
  };
};

export function getAgeGender(client, { showDate, hideAge, refDate, onlyGender } = {}) {
  if (!client) { return undefined; }
  const { dob, dateOfBirth, gender, pronouns, adjustedDob } = client;
  if (!dob && !dateOfBirth && !gender && !pronouns) {
    return undefined;
  }

  const dobb = dob || dateOfBirth;
  const parts = [];
  const showPronouns = pronouns && !onlyGender;
  if (showPronouns) { parts.push(pronouns); }
  if (gender && !showPronouns) { parts.push(auth.enums.gender[gender]); }
  if (dobb) {
    const ageStr = hideAge ? '' : `(${(getAge(dobb, { adjusted: adjustedDob, refDate }))})`;
    parts.push(`${showDate ? longDate(dobb) + ' ' : ''}${ageStr}`);
  }
  return parts.join(' - ');
}

export function renderClientSearchItem(client, { hideAge, onEdit: handleEdit, isEditing, showLink, orgHint } = {}) {
  const { id, name, externalId, clientPhoto, preferredName, orgId } = client;
  const ageGender = getAgeGender(client, { showDate: true, hideAge });
  const primaryText = typeof name === 'string' ? name : (getLastGiven(name) + (preferredName ? ` (${preferredName})` : ''));
  const hasEdit = !!handleEdit;
  const inner = <Fragment>
    <ListItemAvatar>
      <Gravitar
        file={clientPhoto && `clients/${client.id}/photo/${clientPhoto.fileSystemIndex}/${clientPhoto.id}`}
        email={client.email || client.contactEmail}
      />
    </ListItemAvatar>
    <ListItemText
      disableTypography
      primary={
        <Typography variant="subtitle1" display="block">
          { primaryText }
        </Typography>
      }
      secondary={
        <Typography variant="caption" display="block" noWrap>
          { externalId && `${externalId}` }
          { externalId && ageGender && <br /> }
          { ageGender }
          { (externalId || ageGender) && !!orgId && <br /> }
          { !!orgId && <strong>{ (auth.user.otherOrgs.find(o => o.orgId === orgId) || { orgName: 'Unknown' }).orgName }</strong> }
        </Typography>
      }
      style={hasEdit ? { flexGrow: 0 } : undefined}
    />
    { hasEdit &&
      <Grid item xs style={{ paddingLeft: 8 }}>
        <Button icon onClick={handleEdit} isLoading={isEditing}><Pencil /></Button>
      </Grid>
    }
  </Fragment>;

  if (!showLink) { return inner; }

  return <NavLink key={id} view={ListItem} button component="a" name="clients.client" params={{ id }} orgHint={orgHint}>
    { inner }
  </NavLink>;
}

function searchClients(search, { ignore } = {}) {
  if (!search) { return Promise.resolve({ options: [] }); }

  return api.get(`clients?${toQueryString({ search })}`)
    .then(res => {
      let options = res.data.results.map(searchClientNameMapping);
      if (ignore) {
        options = options.filter(c => !ignore.includes(c.value) && !ignore.includes(c.data.patientId));
      }
      return { options };
    });
}

function searchClientNameMapping(client) {
  let name = typeof client.name === 'string' ? client.name : (getLastGiven(client.name) + (client.preferredName ? ` (${client.preferredName})` : ''));
  if (client.externalId) { name = client.externalId + ' - ' + name; }
  if (client.dob || client.dateOfBirth) { name = name + ' (' + shortDate(client.dob || client.dateOfBirth) + ')'; }
  return { value: client.id, name, data: client };
}