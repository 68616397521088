import { observable, action, autorun, makeObservable } from 'decorators';
import axios from 'axios';
import moment from 'moment';
import { UserChangedError } from 'services/auth';
import offline from 'services/offline';
import { formats } from 'utils/date';
import config from 'config';
import auth from './auth';
import log from './log';
import theme from 'theme';
import Error from 'errors';
import baseApi from 'utils/baseApi';

export class NotFoundError extends Error {};
export class ForbiddenError extends Error {};

class Api {
  @observable _currentSession = null;
  _openSources = [];

  constructor() {
    makeObservable(this);
  }

  get(url, config) {
    return this._wrap(config, c => {
      return axios.get(url, c);
    });
  }

  getAnon(url, conf) {
    return Promise.resolve(axios(Object.assign({}, conf || {}, {
      method: 'get',
      baseURL: this._currentSession?.endpoint || config.anon_path,
      url,
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    })));
  }

  post(url, data, config) {
    return this._wrap(config, c => {
      return axios.post(url, data, c);
    });
  }

  put(url, data, config) {
    return this._wrap(config, c => {
      return axios.put(url, data, c);
    });
  }

  delete(url, config) {
    return this._wrap(config, c => {
      return axios.delete(url, c);
    });
  }

  openFile(url, currentWindow) {
    const fullUrl = this.openFileUrl(url);
    if (currentWindow) {
      window.location.href = fullUrl;
    } else {
      window.open(fullUrl);
    }
  }

  openFileUrl(url) {
    return this._getSessionFileUrl(url) || this._getTokenFileUrl(url, false);
  }

  getSessionUrlAsync(url) {
    const fullUrl = this._getSessionFileUrl(url);
    if (fullUrl) { return Promise.resolve(fullUrl); }

    return baseApi.get(this._getTokenFileUrl(url, true))
      .then(res => res.data.url);
  }

  websocket(options) {
    return new AuthedWebSocket(options);
  }

  // If our current endpoint/token is still good then use it
  _getSessionFileUrl(url) {
    const session = this._currentSession;
    if (session && session.exp.isAfter(formats.now.clone().add(30, 's'))) {
      const c = url.includes('?') ? '&' : '?';
      const baseUrl = theme.isBranded ? `${theme.brand.uiPath}/proxy/${session.endpointPath}/` : session.endpoint;
      return `${baseUrl}${url}${c}session_token=${encodeURIComponent(session.sessionId + ':' + session.verification)}`;
    }
    return null;
  }

  // Other use server side fallback to get these at the time of the request
  _getTokenFileUrl(url, json) {
    let fullUrl = `token?apifile=${encodeURIComponent(url)}`;
    if (auth.sessionToken) {
      fullUrl = `${fullUrl}&session_token=${auth.sessionToken}`;
    } else if (auth.accessToken) {
      fullUrl = `${fullUrl}&access_token=${auth.accessToken}`;
    } else if (auth.calendarToken) {
      fullUrl = `${fullUrl}&calendar_token=${auth.calendarToken}`;
    }
    if (json) {
      fullUrl += '&json=true';
    } else {
      fullUrl = `${config.kalix_auth_path}/${fullUrl}`;
    }
    return fullUrl;
  }

  _wrap(config, func, retryCount) {
    retryCount = (retryCount || 0);
    retryCount++;
    const authPromise = new Promise((resolve, reject, onCancel) => {
      offline.whenUp()
        .then(() => auth.getSessionToken())
        .then(t => {
          const cancelToken = axios.CancelToken.source();
          const fullConfig = Object.assign({}, config, {
            baseURL: t.endpoint,
            cancelToken: cancelToken.token
          });

          this._setTokenValue({ endpoint: t.endpoint, endpointPath: t.endpointPath, sessionId: t.sessionId, verification: t.verification, exp: moment(t.exp) });
          fullConfig.headers = Object.assign({}, fullConfig.headers || {}, {
            'X-Requested-With': 'XMLHttpRequest',
            'X-Timezone': auth.userTimezone,
            Authorization: `Session ${t.sessionId}:${t.verification}`
          });

          onCancel(() => {
            cancelToken.cancel();
          });

          // Server requests shouldn't take more than 30s
          if (fullConfig.timeout == null) {
            fullConfig.timeout = 30000;
          }
          return func(fullConfig);
        })
        .then(res => {
          // Make sure the authentication stays alive if it needs to
          auth.authPing();
          return res;
        })
        .catch(UserChangedError, () => {
          // Just cancel and ignore completely since we switched users
          authPromise.cancel();
          return null;
        })
        .catch(r => {
          // Just ignore cancellations
          if (axios.isCancel(r)) { return null; }

          // Treat 404 status with a proper error so easier to catch higer up
          if (r.response?.status === 404) {
            throw new NotFoundError('This resource could not be found');
          }

          // Also treat forbidden as a proper error
          if (r.response?.status === 403) {
            throw new ForbiddenError('You are not allowed to access this record');
          }

          // If the request returns a 401, then go through auth again
          // But only if we are in 'retry token mode'
          if (retryCount <= 3 && r.response?.status === 401 && auth.hasRetryToken) {
            this._setTokenValue(null);
            return auth.retry(true).then(() => this._wrap(config, func, retryCount));
          } else if (retryCount <= 3 && r.message === 'Network Error') {
            // Retry on a network error
            offline.forceCheck();
            return this._wrap(config, func, retryCount);
          } else {
            throw r;
          }
        })
        .then(resolve, reject)
        .done();
    });

    return authPromise;
  }

  @action _setTokenValue(session) {
    this._currentSession = session;
  }
}

const websocketPath = config.websockets_path + '/';
class AuthedWebSocket {
  @observable isConnected = false;
  @observable isErrored = false;
  @observable _retryCount = 0;

  /*
  * options:
  * url: required
  * allowAnon: the url does not require auth/user
  * onError: If an error occurs will call this
  * onMessage: If there is a message will call this
  */
  constructor(options) {
    makeObservable(this);
    if (!options || !options.url) {
      throw new Error('options.url is required');
    }

    this._options = options;
    this._connectDispose = autorun(() => this._connect());
  }

  dispose() {
    this._connectDispose();
    this._close();
  }

  _connect(deps) {
    if (!this._options.allowAnon && !auth.user) {
      this._reset();
      return;
    }

    // If not anonymous we need to make sure to make a new connection if the user changes
    if (!this._options.allowAnon) {
      const authSessionId = auth.user.auth.sessionId;
      if (!this._currentSession || this._currentSession !== authSessionId) {
        this._close();
      }
      this._currentSession = authSessionId;
    }

    if (this.isConnected) { return; }

    this._close();

    // Add a bit of timing extension to prevent instant cascade of errors
    this._initPromise = Promise
      .delay((this._retryCount > 30 ? 30 : this._retryCount) * 1000)
      .then(() => {
        return new Promise((resolve, reject) => {
          // We wrap this so that the cancellation does not propagate down
          (this._options.allowAnon ? Promise.resolve() : auth.getSessionToken()).then(resolve, reject).done();
        });
      });

    this._initPromise
      .then(t => {
        const p = this._options.url.includes('?') ? '&' : '?';
        const url = this._options.allowAnon ? this._options.url : `${websocketPath}${this._options.url}${p}session_token=${encodeURIComponent(t.sessionId + ':' + t.verification)}`;

        this._socket = new WebSocket(url);
        this._socket.onerror = this._onError.bind(this);
        this._socket.onopen = this._onOpen.bind(this);
        this._socket.onmessage = this._onMessage.bind(this);
        this._socket.onclose = this._socketClose.bind(this, this._socket);
        return null;
      })
      .catch(this._onError.bind(this))
      .done();
  }

  _onMessage(msg) {
    if (this._options.onMessage) {
      this._options.onMessage(msg);
    }
    log.trackEvent(`events.received.${msg.type || 'unknown'}`);
  }

  @action _reset() {
    this.isErrored = false;
    this._retryCount = 0;
  }

  @action _onOpen() {
    this.isConnected = true;
    this._retryCount = 0;
    this.isErrored = false;
    if (this._pingInterval) {
      clearInterval(this._pingInterval);
      this._pingInterval = null;
    }
    this._pingInterval = setInterval(() => this._ping(), 60000);
  }

  @action _onError() {
    // We might have disconnected because of being offline
    offline.forceCheck();
    this.isErrored = true;
    this._retryCount++;
    this.isConnected = false;
    if (this._pingInterval) {
      clearInterval(this._pingInterval);
      this._pingInterval = null;
    }
    return null;
  }

  @action _close(doNotClearError) {
    if (this._pingInterval) {
      clearInterval(this._pingInterval);
      this._pingInterval = null;
    }

    if (this._initPromise) {
      this._initPromise.cancel();
      this._initPromise = null;
    }

    if (this._socket) {
      this._socket.close();
      this._socket = null;
    }

    this.isConnected = false;
    if (!doNotClearError) {
      this.isErrored = false;
    }
  }

  _socketClose(socket) {
    // We might have a delayed action here
    if (socket === this._socket) {
      this._close();
    }
  }

  _ping() {
    this._socket?.send('');
  }
}

export default new Api();