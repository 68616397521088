import { observable, action, makeObservable } from 'decorators';
import Form from 'components/form';
import log from 'services/log';

export default class CancelStore {
  @observable isCanceling = false;

  constructor({ onCancel, onClose }) {
    makeObservable(this);
    this._onCancel = onCancel;
    this._onClose = onClose;

    this.form = new Form(this._createFields());
  }

  dispose() {}

  close() {
    this._onClose();
  }

  @action cancel() {
    this.isCanceling = true;
    this._onCancel(this.form.values())
      .catch(log.catchAndNotify)
      .finally(action(() => (this.isCanceling = false)))
      .done();
  }

  _createFields() {
    const fields = [ 'reason', 'info' ];

    const labels = {
      info: 'Additional Information (Optional)'
    };

    const values = {
      reason: null,
      info: ''
    };

    const types = {
      reason: 'radios',
      info: 'textarea'
    };

    const extra = {
      reason: {
        direction: 'column',
        options: reasonOptions
      }
    };

    return { fields, labels, values, types, extra };
  }
}

const reasonOptions = [
  { value: 'left_practice', name: 'I am no longer in private practice' },
  { value: 'missing_features', name: 'Missing features I need' },
  { value: 'hard_to_use', name: 'It\'s too hard to use' },
  { value: 'too_expensive', name: 'It\'s too expensive' },
  { value: 'technical', name: 'Technical issues' },
  { value: 'support', name: 'I never get the support I need' },
  { value: 'other', name: 'Other' }
];