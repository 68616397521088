import { forwardRef, useCallback, useRef } from 'react';
import { observer } from 'decorators';
import { makeStyles, useResizeDetector, combineRefs } from 'hooks';
import { Typography, Select as MaterialSelect, Input, CircularProgress, Checkbox, MenuItem, ListItem, ListItemText, ListItemIcon, InputAdornment } from '@material-ui/core';

import SelectMultiButtonComponent from './multiButton';
import SelectAsyncComponent from './async';
import SelectTextComponent from './text';

import { Close } from 'mdi-material-ui';

export const SelectMultiButton = SelectMultiButtonComponent;
export const SelectAsync = SelectAsyncComponent;
export const SelectText = SelectTextComponent;

function safeValue(value) {
  return value == null ? '' : value + '';
}

const useStyles = makeStyles(theme => ({
  progress: {
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(0.5)
  }
}));

export default observer(forwardRef(function Select({ options, value, valueKey, nameKey, isLoading, multiple, onChange, disabled, readOnly, endAdornment, MenuProps, dropdownRef, renderItem, placeholder, useMaxMenuWidth, children, ...other }, ref) {
  const classes = useStyles();
  const { width, ref: resizeRef } = useResizeDetector({ handleHeight: false });

  valueKey = valueKey || 'value';
  nameKey = nameKey || 'name';

  const renderValue = useCallback(v => {
    const wrappedPlaceholder = <Typography color="textSecondary">{placeholder}</Typography>;
    if (!options) { return wrappedPlaceholder || ''; }
    return multiple
      ? options.filter(o => v.map(vv => safeValue(vv)).includes(safeValue(o[valueKey]))).map(o => o[nameKey]).join(', ') || wrappedPlaceholder || ''
      : (options.find(o => safeValue(o[valueKey]) === safeValue(v)) || {})[nameKey] || wrappedPlaceholder || '';
  }, [ multiple, nameKey, valueKey, options, placeholder ]);

  const stopClickRef = useRef(false);
  const handleChange = useCallback(e => {
    if (stopClickRef.current) {
      stopClickRef.current = false;
      return;
    }
    if (!onChange || !options) { return; }

    const v = e.target.value;
    const result = multiple
      ? options.filter(o => v.includes(safeValue(o[valueKey])))
      : options.find(o => safeValue(o[valueKey]) === v);

    onChange(result);
  }, [ onChange, multiple, options, valueKey ]);

  // This is only used for multiple select
  const handleClear = useCallback(e => {
    stopClickRef.current = true;
    onChange && onChange([]);
  }, [ onChange ]);

  value = multiple ? (value || []).map(v => safeValue(v)) : safeValue(value);
  if (readOnly) {
    const label = renderValue(value);
    return <Input ref={ref} value={label} readOnly {...other} />;
  }

  // TODO: This control seems to be better without native right now
  const isNative = false; // !multiple && media.isTouchDevice;

  return <MaterialSelect
    ref={combineRefs(ref, resizeRef)}
    value={options ? value : (multiple ? [] : '')}
    native={isNative}
    renderValue={renderValue}
    multiple={multiple}
    disabled={isLoading || disabled}
    displayEmpty={!isNative && !!placeholder}
    endAdornment={isLoading ? <InputAdornment position="end"><CircularProgress size={15} className={classes.progress} /></InputAdornment> : endAdornment}
    onChange={handleChange}
    // If we want to keep reference to dropdown, we need to keep it mounted
    MenuProps={Object.assign({
      disableScrollLock: true,
      keepMounted: !!dropdownRef,
      TransitionProps: { ref: dropdownRef },
      style: useMaxMenuWidth ? { maxWidth: width } : undefined
    }, MenuProps || {})}
    {...other}
  >
    { options && options.map((o, i) => {
      const isSelected = multiple ? value.includes(safeValue(o[valueKey])) : safeValue(o[valueKey]) === value;

      if (isNative) {
        return <option key={i} value={safeValue(o[valueKey])}>{o[nameKey] || ''}</option>;
      } else {
        return <MenuItem key={i} selected={isSelected} value={safeValue(o[valueKey])}>
          { multiple ? <ListItemIcon><Checkbox checked={isSelected} style={{ pointerEvents: 'none' }} /></ListItemIcon> : null }
          { renderItem && renderItem(o) }
          { !renderItem && <ListItemText disableTypography primary={<Typography display="block" noWrap>{o[nameKey] || '(Blank)'}</Typography>} /> }
        </MenuItem>;
      }
    })}
    { !isNative && multiple && !!onChange &&
      <ListItem button onClick={handleClear} disabled={!value.length}>
        <ListItemIcon><Close /></ListItemIcon>
        <ListItemText primary="Clear Selection" />
      </ListItem>
    }
    { !isNative && children }
  </MaterialSelect>;
}));