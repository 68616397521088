export default [
  { name: 'admin', path: '/admin', view: () => import('./admin') }
];

export function nav(user) {
  if (user?.roles?.isAdmin) {
    return [
      { name: 'admin', title: 'Admin', navPath: 'Admin' }
    ];
  }

  return [];
};