import { computed, observable, action, reaction, makeObservable } from 'decorators';
import auth from 'services/auth';
import log from 'services/log';
import router from 'services/router';
import api from 'services/api';
import { round } from 'utils/number';
import baseApi from 'utils/baseApi';
import { getSubscriptionText, getDedicatedNumbersPricing, getRepugenPricing } from './utils';
import userService from 'services/data/user';

import { getStripe } from 'pages/billing/viewBill/stripePayment.store';

import PlanStore from './plan.store';
import CancelStore from './cancel.store';

export const getPlanInfo = details => {
  if (!details) { return null; }
  const { plan, planNumber } = details;

  let type, isAnnual;
  switch (plan) {
    case 'yearly':
      type = 'standard';
      isAnnual = true;
      break;
    case 'yearly_pro':
      type = 'pro';
      isAnnual = true;
      break;
    case 'monthly':
      type = 'standard';
      isAnnual = false;
      break;
    case 'monthly_pro':
      type = 'pro';
      isAnnual = false;
      break;
  }

  return {
    number: planNumber,
    type,
    isAnnual
  };
};

export default class SubscriptionStore {
  @observable isUpdatingCard = false;
  @observable additionalDetails = null;
  @observable showCancel = null;
  @observable currentUsage = [];

  @observable planStore = null;

  constructor() {
    makeObservable(this);
    // Preload stripe stuff...
    this._loadStripe().done();
    this._planStoreDispose = reaction(() => ({ name: router.name, d: this.details, ad: this.additionalDetails }), () => this._refreshPlanStore());
    this._syncDispose = reaction(() => this.details?.stripeKey, () => this._setupStripe());
    this._couponDispose = reaction(() => auth.user, () => this._refreshAdditionalDetails());
    this._refreshAdditionalDetails();
  }

  @computed get reminderSettings() {
    return userService.reminderSettings.value;
  }

  @computed get details() {
    return userService.orgPaymentDetails.value;
  }

  @computed get nextPaymentDateText() {
    if (!auth.user) { return null; }

    const { isExpired, hasPaid, hasSubscription } = auth.user;
    if (isExpired) { return null; }

    if (hasSubscription) {
      return 'Next Payment Date';
    } else if (!hasPaid) {
      return 'Trial End Date';
    } else {
      return 'Cancelation Date';
    }
  }

  @computed get plan() {
    return getPlanInfo(this.details);
  }

  @computed get canChangeCreditCard() {
    return auth.user.isVerified && auth.user.hasSubscription;
  }

  @computed get subscription() {
    const { hasSubscription } = auth.user;
    if (!hasSubscription) { return 'No subscription selected'; }
    if (!this.plan) { return ''; }

    const { number, type, isAnnual } = this.plan;
    let sub = getSubscriptionText(number, type, isAnnual);
    if (type !== 'pro' && auth.hasFeature(f => f.pro)) {
      sub = `${sub} (Legacy Full Plan)`;
    }

    return sub;
  }

  @computed get dedicatedNumbersPricing() {
    if (!this.details) { return ''; }
    const { phoneNumberPrice } = this.details;
    const { isAnnual } = this.plan;
    return getDedicatedNumbersPricing(phoneNumberPrice, isAnnual);
  }

  @computed get repugenPricing() {
    const { repugenPackage, repugenNumber } = this.details;
    const { isAnnual } = this.plan;
    return getRepugenPricing(repugenPackage, repugenNumber, isAnnual);
  }

  @computed get currentUsagePaid() {
    return (this.details?.planNumber || 0) * 25;
  }

  @computed get currentUsageText() {
    const current = this.currentUsage;
    const paid = this.currentUsagePaid;
    if (!paid && !current.length) {
      return 'You currently have no usage data and no subscription is selected';
    }

    const parts = [];
    if (paid) {
      parts.push(`You are subscribed for ${paid} appointment hours per week.`);
    }
    if (current.length) {
      const average = current.reduce((acc, a) => acc + a.hours, 0) / current.length;
      parts.push(`Over the last ${current.length} weeks you have averaged ${round(average, 1)} appointment hours per week.`);
    }
    return parts.join(' ');
  }

  @action changeCard() {
    this.isUpdatingCard = true;
    baseApi.post('subscription/checkout/updateCard', {}, auth.getCSRFConfig())
      .then(res => this._loadStripe().then(stripe => stripe.redirectToCheckout({ sessionId: res.data.session })))
      .then(res => {
        if (res?.error?.message) { throw new Error(res.error.message); }
      })
      .catch(err => {
        log.catchAndNotify(err);
        this.isUpdatingCard = false;
      })
      .done();
  }

  @action cancelSubscription() {
    this._closeCancel();
    this.showCancel = new CancelStore({
      onCancel: (d) => this._cancelSubscription(d),
      onClose: () => this._closeCancel()
    });
  }

  dispose() {
    this._planStoreDispose();
    this._syncDispose();
    this._resetPlanSelection();
    this._closeCancel();
  }

  @action _refreshPlanStore() {
    this._resetPlanSelection();
    if (router.name !== 'subscription.select' || !this.details || !this.additionalDetails) { return; }

    const isExisting = auth.user.hasSubscription;
    this.planStore = new PlanStore({
      isAnnual: isExisting ? this.plan.isAnnual : false,
      type: isExisting ? this.plan.type : 'standard',
      number: isExisting ? this.plan.number : 1,
      coupon: this.additionalDetails.couponId,
      couponDesc: this.additionalDetails.couponDescription,
      credit: this.additionalDetails.creditBalance,
      completeSubscription: (d) => this._completeSubscription(d),
      onClose: () => router.navigateTo('subscription')
    });
  }

  _resetPlanSelection() {
    if (this.planStore) {
      this.planStore.dispose();
      this.planStore = null;
    }
  }

  async _completeSubscription({ annual, type, number, coupon, referralCode, affiliateId, useExistingCard }) {
    const subData = { type, isYearly: annual, number, coupon, referralCode, affiliateId };

    if (useExistingCard) {
      await baseApi.post('subscription', subData, auth.getCSRFConfig());
      await auth.retry(true); // After changing we should refresh
      return true;
    } else {
      const res = await baseApi.post('subscription/checkout/updateSub', subData, auth.getCSRFConfig());
      const stripe = await this._loadStripe();
      const stripeRes = await stripe.redirectToCheckout({ sessionId: res.data.session });
      if (stripeRes?.error?.message) { throw new Error(stripeRes.error.message); }
      return false;
    }
  }

  async _cancelSubscription({ reason, info }) {
    await baseApi.post('subscription/cancel', {
      Reason: reason,
      Info: info
    }, auth.getCSRFConfig());
    await auth.retry(true);
    this._closeCancel();
  }

  @action _closeCancel() {
    if (this.showCancel) {
      this.showCancel.dispose();
      this.showCancel = null;
    }
  }

  async _loadStripe() {
    if (this._stripe) { return this._stripe; }
    await getStripe();
    return this._setupStripe();
  }

  _setupStripe() {
    if (!auth.user || !this.details || !window.Stripe) { return null; }

    return (this._stripe = window.Stripe(this.details.stripeKey));
  }

  _refreshAdditionalDetails() {
    if (!auth.user) { return; }

    baseApi.get('coupon')
      .then(action(res => {
        const { couponDescription, couponId, creditBalance } = res.data || { creditBalance: 0 };
        this.additionalDetails = { couponId, couponDescription, creditBalance };
        return null;
      }))
      .done();

    api.get('appointments/last10weeks')
      .then(action(res => {
        this.currentUsage = res.data;
      }))
      .done();
  }
}