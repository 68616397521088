import globalEvents from 'services/globalEvents';
import auth from 'services/auth';
import api, { NotFoundError } from 'services/api';
import baseApi from 'utils/baseApi';
import log from 'services/log';
import { observable, reaction, action, onBecomeObserved, onBecomeUnobserved, makeObservable, computed } from 'decorators';
import Throttler from 'utils/throttler';

class UserService {
  constructor() {
    makeObservable(this);
    this.toDispose = [];
    const createObs = s => {
      const obs = new GlobalRefreshObservable(s);
      this.toDispose.push(obs);
      return obs;
    };

    this.orgPaymentDetails = createObs({
      load: () => auth.user.roles?.isDirector ? 'currentuser/payment' : null,
      isBaseApi: true,
      defaultValue: null
    });

    this.clinicians = createObs({
      load: 'clinicians',
      globalEvent: 'clinicians.refresh',
      defaultValue: []
    });

    this.allClinicians = createObs({
      load: 'clinicians?all=true',
      globalEvent: 'clinicians.refresh',
      defaultValue: []
    });

    this.locations = createObs({
      load: 'settings/addresses/locations',
      globalEvent: 'locations.refresh',
      defaultValue: []
    });

    this.postalAddresses = createObs({
      load: 'settings/addresses/postal',
      globalEvent: 'postalAddresses.refresh',
      defaultValue: []
    });

    this.appointmentTypes = createObs({
      load: 'appointment/types',
      globalEvent: 'appointmentTypes.refresh',
      defaultValue: []
    });

    this.cronometerAccess = createObs({
      load: 'cronometer',
      globalEvent: 'cronometer.refresh',
      defaultValue: null
    });

    this.nutritionixAccess = createObs({
      load: 'nutritionix',
      globalEvent: 'nutritionix.refresh',
      defaultValue: null
    });

    this.fullscriptAccess = createObs({
      load: 'fullscript',
      globalEvent: 'fullscript.refresh',
      defaultValue: null
    });

    this.rupahealthAccess = createObs({
      load: 'rupahealth',
      globalEvent: 'rupahealth.refresh',
      defaultValue: null
    });

    this.repugenAccess = createObs({
      load: () => auth.user.roles?.isDirector ? 'repugen' : null,
      globalEvent: 'repugen.refresh',
      defaultValue: null
    });

    this.appTypeGroups = createObs({
      load: 'appointment/types/groups',
      globalEvent: 'apptypes.groups.refresh',
      defaultValue: [],
      silent: true
    });

    this.locationGroups = createObs({
      load: 'settings/addresses/locations/groups',
      globalEvent: 'locations.groups.refresh',
      defaultValue: [],
      silent: true
    });

    this.clinicianGroups = createObs({
      load: 'clinicians/groups',
      globalEvent: 'clinicians.groups.refresh',
      defaultValue: [],
      silent: true
    });

    this.paymentProvider = createObs({
      load: 'paymentprovider',
      globalEvent: 'paymentprovider.refresh',
      defaultValue: null
    });

    this.clearinghouse = createObs({
      load: 'clearinghouse',
      globalEvent: 'clearinghouse.refresh',
      filter: () => auth.isUsBilling,
      defaultValue: null
    });

    this.hl7Providers = createObs({
      load: 'hl7',
      globalEvents: 'hl7.refresh',
      defaultValue: []
    });

    this.reminderSettings = createObs({
      load: 'reminders/settings',
      globalEvent: 'messaging.settings.refresh',
      defaultValue: null
    });

    this.insurerSettings = createObs({
      load: 'invoices/insurers/settings',
      globalEvent: 'insurers.settings.refresh',
      defaultValue: null
    });

    this.tentativeAppointments = createObs({
      load: 'appointments/tentative',
      globalEvent: 'appointments.refresh',
      globalEventFilter: (a, current) => a.isTentative || current.find(c => c.id === a.id),
      defaultValue: [],
      silent: true
    });

    this.notificationSettings = createObs({
      load: 'notifications/settings',
      globalEvent: 'notification.settings.refresh',
      defaultValue: null
    });

    this.videoStats = createObs({
      load: 'video/stats',
      globalEvent: 'video.stats.refresh',
      defaultValue: null
    });
  }

  openSupport(message) {
    if (window.Intercom) {
      window.Intercom('showNewMessage', message);
    }
  }

  dispose() {
    this.toDispose.forEach(a => a.dispose());
  }

  @computed get availableMessagingMethods() {
    const result = [];
    const settings = this.reminderSettings.value;

    if (settings) {
      const { channelTypes: channels } = auth.enumValues;
      [ 'email', 'sms', 'phone', 'fax' ] // custom order
        .filter(k => settings.channelSettings[k].isEnabled)
        .map(k => ({
          value: channels[k] + 1,
          name: auth.enums.channelTypes[channels[k]]
        }))
        .forEach(v => result.push(v));
    }

    return result;
  }
}

export class GlobalRefreshObservable {
  @observable.ref value;
  @observable isLoading = true;

  constructor({ load, isBaseApi, globalEvent, globalEventFilter, defaultValue, httpMethod, silent, filter }) {
    makeObservable(this);
    this._load = load;
    this._isBaseApi = isBaseApi;
    this._globalEvent = globalEvent;
    this.value = this._defaultValue = defaultValue;
    this._httpMethod = httpMethod || 'get';
    this._isDirty = true;
    this._isObserving = false;
    this._isSilent = silent;
    this._filter = filter;

    this._refreshThrottler = new Throttler();

    this._userReactionDispose = reaction(() => auth.user, () => this._dirtyFlagged());
    if (this._globalEvent) {
      this._globalEventDispose = globalEvents.on(this._globalEvent, i => (!globalEventFilter || globalEventFilter(i, this.value)) && this._dirtyFlagged());
    }

    // The act of accessing the value should trigger a load if needed
    this._observeDispose = onBecomeObserved(this, 'value', () => this._onObserve());
    this._unobserveDispose = onBecomeUnobserved(this, 'value', () => this._onUnobserve());

    // The act of accessing the isLoading property should also trigger a load
    this._observeLoadDispose = onBecomeObserved(this, 'isLoading', () => this._onObserve());
    this._unobserveLoadDispose = onBecomeUnobserved(this, 'isLoading', () => this._onUnobserve());
  }

  dispose() {
    this._observeLoadDispose();
    this._unobserveLoadDispose();
    this._observeDispose();
    this._unobserveDispose();
    this._userReactionDispose();
    if (this._globalEventDispose) { this._globalEventDispose(); }
    this._refreshThrottler.dispose();
  }

  waitForValue(force) {
    this._refresh(force, true);
    this._refreshThrottler.runNow();
    const promise = this._currentPromise || Promise.resolve();
    return promise.then(() => this.value);
  }

  _onObserve() {
    this._isObserving = true;
    setTimeout(() => this._refresh(), 1); // Get it off the current tick
  }

  _onUnobserve() {
    this._isObserving = false;
  }

  @action _dirtyFlagged() {
    this._isDirty = true;
    this._refresh();
  }

  @action _refresh(forceDirty, forceObserving) {
    // If it's not dirty, or not observing, don't bother to do anything
    if ((!this._isDirty && !forceDirty) || (!this._isObserving && !forceObserving)) { return; }

    this._isDirty = false;
    let url;
    if (!auth.user || !(url = typeof this._load === 'function' ? this._load() : this._load) || (this._filter && !this._filter())) {
      this._refreshThrottler.cancel();
      this.value = this._defaultValue;
      this.isLoading = false;
      return;
    }

    this._currentPromise = this._refreshThrottler.push(action(id => {
      this._refreshThrottleId = id;
      if (!this._isSilent) {
        this.value = this._defaultValue;
        this.isLoading = true;
      }
      return (this._isBaseApi ? baseApi : api)[this._httpMethod](url, this._httpMethod === 'get' ? undefined : {})
        .then(action(res => {
          if (id !== this._refreshThrottleId) { return null; }

          this.value = res.data;
          return null;
        }))
        .catch(NotFoundError, action(() => {
          if (id !== this._refreshThrottleId) { return null; }

          this.value = this._defaultValue;
          this._isDirty = true;
          return null;
        }))
        .catch(action((err) => {
          if (id !== this._refreshThrottleId) { return null; }

          this.value = this._defaultValue;
          this._isDirty = true;
          log.catchAndNotify(err);
          return null;
        }))
        .finally(action(() => {
          if (id !== this._refreshThrottleId) { return null; }

          this._currentPromise = null;
          this.isLoading = false;
          return null;
        }));
    }));
  }
}

export default new UserService();