import auth from 'services/auth';

export function getFirstName(name, preferredName) {
  if (preferredName) { return preferredName; }
  if (!name?.givenName) { return ''; }

  return name.givenName.split(' ')[0] || '';
}

export function getFullName(name) {
  if (!name) { return ''; }

  const nameStack = [];
  if (name.title) { nameStack.push(auth.enums.titleType[name.title]); }
  if (name.givenName) { nameStack.push(name.givenName); }
  if (name.lastName) { nameStack.push(name.lastName); }

  return nameStack.join(' ');
}

export function getFirstLast(name, preferredName) {
  if (!name) { return ''; }
  const nameStack = [];
  if (name.givenName) {
    nameStack.push(getFirstName(name, preferredName));
  }

  if (name.lastName) {
    nameStack.push(name.lastName);
  }

  return nameStack.join(' ');
}

export function getLastGiven(name, preferredName) {
  if (!name) { return preferredName || ''; }
  const nameStack = [];
  if (name.lastName) {
    nameStack.push(name.lastName);
  }

  if (preferredName) {
    nameStack.push(preferredName);
  } else if (name.givenName) {
    const givenParts = name.givenName.split(' ');
    let isFirst = true;
    const mapped = givenParts.filter(n => !!n).map(n => {
      const toReturn = isFirst ? n : n[0];
      isFirst = false;
      return toReturn;
    });
    nameStack.push(mapped.join(' '));
  }

  return nameStack.join(', ');
}