export function enumToList(en, { zeroAsEmpty, hasNull, nullLabel, exclude, doNotSort } = {}) {
  let results = Object.keys(en)
    .map(e => isNaN(e) ? e : Number(e))
    .map(e => ({ value: e, name: zeroAsEmpty && e === 0 ? '' : en[e] }));

  if (exclude) {
    results = results.filter(e => !exclude.includes(e.value));
  }

  // Always sort by the name
  if (!doNotSort) {
    results.sort((a, b) => {
      if (a.name < b.name) { return -1; }
      if (a.name > b.name) { return 1; }
      return 0;
    });
  }

  if (hasNull) { results.unshift({ value: '', name: nullLabel || '' }); }
  return results;
}