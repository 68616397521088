import { toMoney, toDurationStr } from 'utils/number';
import userService from 'services/data/user';

export const VideoHoursPerBlock = 10;

export const RepugenPackageOptions = [
  { name: 'RepuGen Core Plan', value: 'core', price: 57 },
  { name: 'RepuGen Core + Marketing Solutions', value: 'advanced', price: 87 }
];

export function getSubscriptionAmount(number, type, isAnnual) {
  const amountPerType = type === 'standard' ? 27 : 47;
  return number * amountPerType * (isAnnual ? 10 : 1);
}

export function getSubscriptionText(number, type, isAnnual) {
  const amount = getSubscriptionAmount(number, type, isAnnual);
  return `${number} x ${type === 'standard' ? 'Standard' : 'Virtual Practice'} ${isAnnual ? 'Annual' : 'Monthly'} (${toMoney(amount, '$')} per ${isAnnual ? 'year' : 'month'})`;
}

export function getDedicatedNumbersPricing(phoneNumberPrice, isAnnual) {
  return `${toMoney(phoneNumberPrice * (isAnnual ? 12 : 1), '$')} per ${isAnnual ? 'year' : 'month'}`;
}

export function getRepugenPricing(repuPackage, number, isAnnual) {
  const opt = RepugenPackageOptions.find(pp => pp.value === repuPackage);
  if (opt == null || !number) { return null; }
  const total = opt.price * number * (isAnnual ? 12 : 1);
  return `${number} x ${opt.name} ${isAnnual ? 'Annual' : 'Monthly'} (${toMoney(total, '$')} per ${isAnnual ? 'year' : 'month'})`;
}

export function getVideoBlocksPricing(number, isAnnual, hideAvailable) {
  const hours = number * VideoHoursPerBlock;
  const total = 5 * number * (isAnnual ? 12 : 1);
  const res = `${hours} hours (${toMoney(total, '$')} per ${isAnnual ? 'year' : 'month'})`;
  if (hideAvailable || hours === 0) { return res; }
  const available = userService.videoStats.value?.available;
  if (available == null) { return res; }
  return `${res} - ${toDurationStr(available)} available`;
}