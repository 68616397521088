import { useMemo } from 'react';
import { observer } from 'decorators';
import { Grid, Typography } from '@material-ui/core';
import { enumToList } from 'utils/enums';
import Input from './input';
import auth from 'services/auth';
import media from 'services/media';

export default observer(function NameInput({ form, field, label, hideTitle, disabled }) {
  const isSmall = media.is('xs');
  const titleOptions = useMemo(() => enumToList(auth.enums.titleType, { zeroAsEmpty: true }), []);

  const inner = <Grid container spacing={isSmall ? 0 : 2}>
    {!hideTitle &&
      <Grid item xs={12} sm={2}>
        <Input form={form} field={field + '.title'} options={titleOptions} disabled={disabled} />
      </Grid>
    }
    <Grid item xs={12} sm>
      <Input form={form} field={field + '.givenName'} disabled={disabled} />
    </Grid>
    <Grid item xs={12} sm>
      <Input form={form} field={field + '.lastName'} disabled={disabled} />
    </Grid>
  </Grid>;

  if (!label) { return inner; }

  return <Grid container direction="column">
    <Grid item>
      <Typography variant="subtitle1">{label}</Typography>
    </Grid>
    <Grid item>
      { inner }
    </Grid>
  </Grid>;
});