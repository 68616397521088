export function splitWindowQuery() {
  // It seems that hashes that come after the ? are included in window.location.search
  const search = window.location.search.replace('?', '');
  const parts = splitQuery(search);

  if (window.location.hash) {
    const hashParts = splitQuery(window.location.hash);
    parts.hash = Object.assign(parts.hash, hashParts.hash);
  }

  return parts;
}

export function splitQuery(query) {
  query = query || '';
  const parts = query.split('#');
  const pl = /\+/g; // Regex for replacing addition symbol with a space
  let search = /([^&=]+)=?([^&]*)/g;
  const decode = (s) => decodeURIComponent(s.replace(pl, ' '));

  const queryParts = {};
  let match;
  while ((match = search.exec(parts[0]))) {
    // We don't want to extract the access_token
    const key = decode(match[1]);
    queryParts[key] = decode(match[2]);
  }

  const hashParts = {};
  if (parts.length > 1) {
    search = /([^&=]+)=?([^&]*)/g; // Reset the regex search
    while ((match = search.exec(parts[1]))) {
      hashParts[decode(match[1])] = decode(match[2]);
    }
  }

  return {
    query: queryParts,
    hash: hashParts
  };
}

export function toQueryString(obj) {
  const str = [];
  let p;
  for (p in obj) {
    const val = obj[p];
    if (Object.prototype.hasOwnProperty.call(obj, p) && val !== undefined && val !== null && val !== '') {
      if (Array.isArray(val)) {
        val.forEach(v => {
          str.push(encodeURIComponent(p) + '=' + encodeURIComponent(v));
        });
      } else {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(val));
      }
    }
  }
  return str.join('&');
}