import auth from 'services/auth';

export default [
  { name: 'clients.new', path: '/clients/new', sameView: 'clients.client' },
  { name: 'contacts.new', path: '/contacts/new', sameView: 'contacts.contact' },
  { name: 'referrals.new', path: '/referrals/new', sameView: 'referrals.referral' },
  { name: 'clients', path: '/clients', view: () => import('./clients') },
  { name: 'contacts', path: '/contacts', view: () => import('./contacts') },
  { name: 'referrals', path: '/referrals', view: () => import('./referrals') },
  { name: 'referrals.waitinglist', path: '/referrals/waitinglist', sameView: 'referrals' },
  { name: 'clients.client', path: '/clients/:id', view: () => import('./client') },
  { name: 'clients.client.details', path: '/clients/:id/details', sameView: 'clients.client' },
  { name: 'clients.client.messages', path: '/clients/:id/messages', sameView: 'clients.client' },
  { name: 'clients.client.nutritionix', path: '/clients/:id/nutritionix', sameView: 'clients.client' },
  { name: 'clients.client.rupahealth', path: '/clients/:id/rupahealth', sameView: 'clients.client' },
  { name: 'clients.client.fullscript', path: '/clients/:id/fullscript', sameView: 'clients.client' },
  { name: 'clients.client.cronometer', path: '/clients/:id/cronometer', sameView: 'clients.client' },
  { name: 'contacts.contact', path: '/contacts/:id', view: () => import('./contact') },
  { name: 'contacts.contact.details', path: '/contacts/:id/details', sameView: 'contacts.contact' },
  { name: 'contacts.contact.messages', path: '/contacts/:id/messages', sameView: 'contacts.contact' },
  { name: 'referrals.referral', path: '/referrals/:id', view: () => import('./referral') },
  { name: 'referrals.referral.tasks', path: '/referrals/:id/tasks', sameView: 'referrals.referral' }
];

export function nav(user) {
  if (!user) { return []; }

  return [
    { name: 'clients.new', title: 'New Client', navPath: 'Clients.New.Client', showNav: true },
    { name: 'contacts.new', title: 'New Contact', navPath: 'Clients.New.Contact', showNav: true },
    { name: 'referrals.new', title: auth.isUsBilling ? 'New Referral/Diagnosis' : 'New Referral', navPath: auth.isUsBilling ? 'Clients.New.Referral/Diagnosis' : 'Clients.New.Referral', showNav: true },
    { name: 'clients', title: 'Clients', navPath: 'Clients.Clients', showNav: true, isNavRoot: true },
    { name: 'contacts', title: 'Contacts', navPath: 'Clients.Contacts', showNav: true },
    { name: 'referrals', title: auth.isUsBilling ? 'Referrals & Diagnosis' : 'Referrals', navPath: auth.isUsBilling ? 'Clients.Referrals & Diagnosis' : 'Clients.Referrals', showNav: true },
    { name: 'referrals.waitinglist', title: 'Waiting List', navPath: 'Clients.Waiting List', showNav: true },
    { name: 'clients.client', title: 'Client', navPath: 'Clients.Clients' },
    { name: 'clients.client.details', title: 'Client', navPath: 'Clients.Clients' },
    { name: 'clients.client.messages', title: 'Client', navPath: 'Clients.Clients' },
    { name: 'clients.client.nutritionix', title: 'Client', navPath: 'Clients.Clients' },
    { name: 'clients.client.cronometer', title: 'Client', navPath: 'Clients.Clients' },
    auth.rupahealthSupported ? { name: 'clients.client.rupahealth', title: 'Client', navPath: 'Clients.Clients' } : null,
    auth.fullscriptSupported ? { name: 'clients.client.fullscript', title: 'Client', navPath: 'Clients.Clients' } : null,
    { name: 'contacts.contact', title: 'Contact', navPath: 'Clients.Contacts' },
    { name: 'contacts.contact.details', title: 'Contact', navPath: 'Clients.Contacts' },
    { name: 'contacts.contact.messages', title: 'Contact', navPath: 'Clients.Contacts' },
    { name: 'referrals.referral', title: 'Referral', navPath: auth.isUsBilling ? 'Clients.Referrals & Diagnosis' : 'Clients.Referrals' },
    { name: 'referrals.referral.tasks', title: 'Referral', navPath: auth.isUsBilling ? 'Clients.Referrals & Diagnosis' : 'Clients.Referrals' }
  ].filter(r => r);
};