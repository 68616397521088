import './promiseSetup';
import moment from 'moment';
import 'moment-timezone';
import { configure } from 'mobx';

// Polyfill origin
if (!window.location.origin) {
  window.location.origin = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
}

// mobx strict setting
configure({ enforceActions: 'observed' });

// Add functions to moment
moment.parseJson = function(dateStr) {
  return dateStr ? moment(dateStr) : null;
};