import XXH64 from 'xxhashjs/lib/xxhash64';
import stableStringify from 'json-stable-stringify';
import { observable, computed, action, makeObservable } from 'decorators';
import { toJS } from 'mobx';

const hasher = XXH64(0x5AC3);

export function hashObj(obj) {
  if (!obj) { return 0; }
  const str = stableStringify(obj);
  return hashStr(str);
}

export function hashStr(str) {
  return hasher.update(str).digest().toNumber();
}

export class HashTracker {
  @observable _initialHash;
  @observable _secondaryHash;
  @observable _modelFunc;

  constructor(modelFunc, isNew) {
    makeObservable(this);
    this._modelFunc = modelFunc;
    const model = toJS(modelFunc());
    this._initialHash = this._secondaryHash = isNew ? 0 : hashObj(model);
  }

  @computed get isDirty() {
    const current = toJS(this._modelFunc());
    const hash = hashObj(current);
    return this._initialHash !== hash;
  }

  @computed get isDirtySecondary() {
    const current = toJS(this._modelFunc());
    const hash = hashObj(current);
    return this._secondaryHash !== hash;
  }

  @action reset() {
    const current = toJS(this._modelFunc());
    this._initialHash = this._secondaryHash = hashObj(current);
  }

  @action resetSecondary() {
    const current = toJS(this._modelFunc());
    this._secondaryHash = hashObj(current);
  }
}