import { observable, action, makeObservable, flow } from 'decorators';
import log from 'services/log';

import AlertIcon from 'mdi-material-ui/Alert';
import InfoIcon from 'mdi-material-ui/InformationOutline';

class Toastr {
  @observable currentToast = null;
  @observable currentDelete = null;
  @observable isRestoring = false;

  constructor() {
    makeObservable(this);
  }

  error({ message, title, linkName }) {
    const opts = { message, title, linkName, color: 'error', icon: AlertIcon };
    this._createToast(opts);
  }

  info({ message, title, linkName }) {
    const opts = { message, title, linkName, color: 'default', icon: InfoIcon };
    this._createToast(opts);
  }

  warning({ message, title, linkName }) {
    const opts = { message, title, linkName, color: 'secondary', icon: AlertIcon };
    this._createToast(opts);
  }

  delete(message, restore) {
    this.currentDelete = { message, restore };
  }

  @action clearDelete() {
    this.currentDelete = null;
  }

  @flow *completeDelete() {
    if (!this.currentDelete) { return; }

    this.isRestoring = true;
    try {
      yield Promise.resolve(this.currentDelete.restore());
      this.clearDelete();
    } catch (err) {
      log.catchAndNotify(err);
    }
    this.isRestoring = false;
  }

  @action clear() {
    this.currentToast = null;
  }

  @action _createToast(opts) {
    this.currentToast = opts;
  }
}

export default new Toastr();