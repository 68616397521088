export default {
  'address.city': 'suburb',
  'address.zip': 'postcode',
  'address.state': 'state',
  'bill.bill': 'invoice',
  'bill.billing': 'invoicing',
  'bill.bills': 'invoices',
  'bill.check': 'cheque',
  'bill.insurer': 'insurer',
  'bill.npi': 'provider number',
  'bill.locnpi': 'location provider number',
  'bill.units': 'quantity',
  'bill.tax': 'tax',
  cellphone: 'mobile',
  'client.healthFund': 'insurance details',
  'client.ssn': 'medicare number',
  color: 'colour',
  'contact.qualifications': 'qualifications',
  'org.bsb': 'bank code',
  'org.org': 'organisation',
  'org.npi': 'business number',
  'org.taxId': 'tax number',
  'users.role.administrator': 'administrative',
  'users.role.administrator.description': 'As an administrative user, you can create and modify appointments for all users. You can view all user documents, but only modify your own. Administrative users cannot edit account settings, other users\' account access or modify doc templates.',
  'users.role.normal': 'clinical',
  'users.role.normal.description': 'As a clinical user, you can edit your own calendar, but you are unable to see or modify other users\' appointments. You can view all user documents, but only edit your own. Clinical users cannot edit account settings, other users\' account access or modify doc templates.',
  'users.role.director': 'director',
  'users.role.director.description': 'As a director, you control all account settings and can manage, add and remove users. You can modify and delete all users\' appointments, bills and documents.',
  'users.role.supervisor': 'supervisor',
  'users.role.supervisor.description': 'As a supervisor, you can view all users\' appointments and documents, but you can only modify your own. Supervisors cannot edit account settings, other users\' account access or modify doc templates.',
  'users.role.admin': 'admin',
  'users.role.admin.description': 'Internal Kalix user role'
};