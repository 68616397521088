import auth from 'services/auth';

export default [
  { name: 'docs.new', path: '/docs/new', view: () => import('./new') },
  { name: 'templates.new', path: '/templates/new', sameView: 'templates.template' },
  { name: 'customfields.new', path: '/customelements/new', sameView: 'customfields.customfield' },
  { name: 'docs', path: '/docs', view: () => import('./docs') },
  { name: 'uploads', path: '/uploads', view: () => import('./uploads') },
  { name: 'uploads.video', path: '/uploads/videos', sameView: 'uploads' },
  { name: 'templates', path: '/templates', view: () => import('./templates') },
  { name: 'customfields', path: '/customelements', view: () => import('./customfields') },
  { name: 'quicktext', path: '/quicktext', view: () => import('./quicktext') },
  { name: 'doclayout', path: '/doclayout', view: () => import('./doclayout') },
  { name: 'templates.library', path: '/templates/library', view: () => import('./templates/library') },
  { name: 'customfields.customfield', path: '/customelements/:id', view: () => import('./customfield') },
  { name: 'docs.multi', path: '/docs/multi', view: () => import('./multi'), hideFooter: true },
  { name: 'docs.doc', path: '/docs/:id', view: () => import('./doc'), hideFooter: true },
  { name: 'docs.multi.internal', path: '/docs/multi/:id', sameView: 'docs.multi', hideFooter: true },
  { name: 'docs.doc.messages', path: '/docs/:id/messages', sameView: 'docs.doc' },
  { name: 'docs.doc.tasks', path: '/docs/:id/tasks', sameView: 'docs.doc' },
  { name: 'templates.template', path: '/templates/:id', view: () => import('./template'), hideFooter: true }
];

export function nav(user) {
  if (!user) { return []; }

  const paths = [];
  if (user.roles.isClinician) {
    const hasDocs = auth.hasFeature(f => f.docs);
    const hasTemplates = auth.hasFeature(f => f.docTemplates);
    if (hasDocs) {
      paths.push({ name: 'docs.new', title: 'New Document', navPath: 'Documents.New Document', showNav: true });
    }

    if (hasTemplates) {
      paths.push({ name: 'templates.new', title: 'New Document Template', navPath: 'Documents.Document Templates' });
      paths.push({ name: 'customfields.new', title: 'New Custom Element', navPath: 'Documents.Custom Elements' });
    }

    if (hasDocs) {
      paths.push({ name: 'docs', title: 'Documents', navPath: 'Documents.Saved Documents', showNav: true, isNavRoot: true });
      paths.push({ name: 'docs.doc', title: 'Document', navPath: 'Documents' });
      paths.push({ name: 'docs.doc.messages', title: 'Document', navPath: 'Documents' });
      paths.push({ name: 'docs.doc.tasks', title: 'Document', navPath: 'Documents' });
      paths.push({ name: 'docs.multi.internal', title: 'Document', navPath: 'Documents' });
    }

    if (hasDocs || hasTemplates) {
      paths.push({ name: 'uploads', title: 'Shared Uploads', navPath: 'Documents.Shared Uploads', showNav: true });
      paths.push({ name: 'uploads.video', title: 'Shared Uploads', navPath: 'Documents.Shared Uploads' });
    }

    if (hasTemplates) {
      paths.push({ name: 'templates', title: 'Document Templates', navPath: 'Documents.Document Templates', showNav: true });
      paths.push({ name: 'customfields', title: 'Custom Elements', navPath: 'Documents.Custom Elements', showNav: true });
    }

    if (hasDocs || hasTemplates) {
      paths.push({ name: 'quicktext', title: 'Quick Snippets', navPath: 'Documents.Quick Snippets', showNav: true });
    }

    if (hasTemplates) {
      paths.push({ name: 'doclayout', title: 'Document Layout', navPath: 'Documents.Document Layout', showNav: true });
      paths.push({ name: 'templates.library', title: 'Template Library', navPath: 'Documents.Document Templates' });
      paths.push({ name: 'customfields.customfield', title: 'Custom Element', navPath: 'Documents.Custom Elements' });
      paths.push({ name: 'templates.template', title: 'Template', navPath: 'Documents' });
    }
  } else if (user.roles.role === 'Document') {
    paths.push({ name: 'docs.doc', title: 'Document', navPath: 'Documents' });
  } else if (user.roles.role === 'MultiDoc') {
    paths.push({ name: 'docs.multi', title: 'Documents', navPath: 'Documents' });
  }

  return paths;
};