import AuthRoutes, { nav as AuthNavRoutes } from 'pages/auth/routes';
import AppointmentRoutes, { nav as AppointmentNavRoutes } from 'pages/appointments/routes';
import ClientRoutes, { nav as ClientNavRoutes } from 'pages/clients/routes';
import DocRoutes, { nav as DocNavRoutes } from 'pages/docs/routes';
import BillingRoutes, { nav as BillNavRoutes } from 'pages/billing/routes';
import MessagingRoutes, { nav as MessagingNavRoutes } from 'pages/messaging/routes';
import OrgRoutes, { nav as OrgNavRoutes } from 'pages/organization/routes';
import TelehealthRoutes, { nav as TelehealthNavRoutes } from 'pages/telehealth/routes';
import AdminRoutes, { nav as AdminNavRoutes } from 'pages/admin/routes';

function defaultNavigate(router) {
  router.navigateTo(AppointmentRoutes[1].name);
  return Promise.resolve();
}

const Routes = [
  ...AuthRoutes,
  ...AppointmentRoutes,
  ...ClientRoutes,
  ...DocRoutes,
  ...BillingRoutes,
  ...MessagingRoutes,
  ...OrgRoutes,
  ...TelehealthRoutes,
  ...AdminRoutes,
  { name: 'home', path: '/', func: defaultNavigate }
];

export function nav(user) {
  return [
    { name: 'home', navPath: AppointmentRoutes[0].navPath },
    ...AuthNavRoutes(user),
    ...AppointmentNavRoutes(user),
    ...ClientNavRoutes(user),
    ...DocNavRoutes(user),
    ...BillNavRoutes(user),
    ...MessagingNavRoutes(user),
    ...OrgNavRoutes(user),
    ...TelehealthNavRoutes(user),
    ...AdminNavRoutes(user)
  ];
}

export default Routes;