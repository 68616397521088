import { InputAdornment, CircularProgress } from '@material-ui/core';
import MobxReactForm, { Field as MobxField } from 'mobx-react-form';
import { action } from 'decorators';
import vjf from 'mobx-react-form/lib/validators/VJF';
import validator from 'validator';
import moment from 'moment';
import { ValidationError } from 'errors';
import log from 'services/log';
import { pwnedPassword } from 'hibp';

const plugins = { vjf: vjf({ package: validator }) };
const defaultOpts = {
  validateOnChangeAfterSubmit: true,
  validateOnChangeAfterInitialBlur: true
};

export default
class Form extends MobxReactForm {
  constructor(fields, opts) {
    const options = Object.assign({ }, defaultOpts, opts);
    super(fields, { plugins, options });

    // We have to fix up our binding
    this.state.bindings.rewriters = {};

    // Monkey patch to complete promise correctly
    const baseSubmit = this.submit;
    this.submit = action((e) => {
      Promise.resolve(baseSubmit.call(this, e)).catch(err => log.error('Form submit internal error', err)).done();
    });

    // Update is just super strange when it comes to array
    // try and make it sane
    const baseUpdate = this.update;
    this.update = (updates) => {
      for (const [ key, value ] of Object.entries(updates)) {
        // If the type is an array, pre-emptively clear the field before the update
        // This makes the array type behave
        if (Array.isArray(value)) {
          // Might be updating field that doesn't exist yet...
          let field;
          try { field = this.$(key); } catch {}
          if (field) {
            field.clear();
            const size = field.fields.size;
            if (size > value.length) {
              for (const k of field.fields.keys()) {
                if (Number(k) >= value.length) {
                  field.del(k);
                }
              }
            }
            // Add then set seems to be the most reliable way of changing array values
            value.forEach((v, i) => {
              const f = field.fields.has(i + '') ? field.$(i) : field.add();
              f.set(v);
            });

            delete updates[key];
          }
        }
      }

      baseUpdate.call(this, updates);
    };
  }

  makeField(props) {
    return new MobxField(props);
  }

  submitPromise(e) {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    return new Promise((resolve, reject) => {
      this.submit({
        onSuccess: resolve,
        onError: () => {
          const err = new ValidationError('Validation error');
          err.form = this;
          reject(err);
        }
      });
    });
  }

  getFullFieldData(field, other, defaults) {
    let fieldData;
    try { fieldData = this.$(field); } catch { return null; }
    const props = Object.assign(defaults || { fullWidth: true, margin: 'dense' }, fieldData.bind(), other || {});

    // Join everything in extra into the props as well
    const extra = fieldData.extra || {};
    const { calcFields, inputProps: extraInputProps, ...otherExtra } = extra;
    const { inputProps: calcInputProps, ...otherCalcExtra } = (calcFields ? calcFields(fieldData, this) : null) || {};
    const finalInputProps = Object.assign({}, props.inputProps || {}, calcInputProps || {}, extraInputProps || {});
    const finalProps = Object.assign({}, props, otherExtra, otherCalcExtra, { inputProps: finalInputProps });

    // If we are readonly, then force disable underline
    if (finalProps.readOnly) {
      finalProps.disableUnderline = true;
    }
    return finalProps;
  }

  getOptionValue(field, other, defaults) {
    const props = this.getFullFieldData(field, other, defaults);
    if (!props) { return null; }
    let { options, valueKey, nameKey, value } = props;
    if (!options) { return null; }
    valueKey = valueKey || 'value';
    nameKey = nameKey || 'name';

    const val = options.find(o => o[valueKey] === value);
    return val ? val[nameKey] : null;
  }

  bindings() {
    return {
      default: ({ $try, field, props }) => {
        const error = $try(props.error, field.error);
        const inputProps = Object.assign({}, props.InputProps);
        if (field.validating) {
          inputProps.endAdornment = <InputAdornment position="end">
            <CircularProgress size={15} />
          </InputAdornment>;
        }

        let helperText = error || props.helperText;
        if (helperText === ' ') { helperText = ''; }
        return {
          type: $try(props.type, field.type),
          id: $try(props.id, field.id),
          name: $try(props.name, field.name),
          value: $try(props.value, field.value),
          label: $try(props.label, field.label),
          placeholder: $try(props.placeholder, field.placeholder),
          error: !!error,
          helperText,
          disabled: $try(props.disabled, field.disabled),
          onChange: $try(props.onChange, field.onChange),
          onBlur: $try(props.onBlur, field.onBlur),
          onFocus: $try(props.onFocus, field.onFocus),
          autoFocus: $try(props.autoFocus, field.autoFocus),
          InputProps: inputProps
        };
      }
    };
  }
}

export function isEmail({ field, validator }) {
  // If empty then ignore
  if (!field.value) { return [ true, null ]; }
  return [ validator.isEmail(field.value), 'Enter a valid email address' ];
};

export function isRequired({ field, validator }) {
  if (Array.isArray(field.value)) {
    return [ !!field.value.length, 'Choose an option' ];
  }
  const result = typeof field.value === 'string' ? !!(field.value || '').trim() : (!!field.value || field.value === 0);
  return [ result, 'Enter a value' ];
}

export function isAlphaNumeric({ field, validator }) {
  // If empty then ignore
  if (!field.value) { return [ true, null ]; }
  return [ validator.isAlphanumeric(field.value), 'Text should only use numbers and letters' ];
}

export function isNumber(opts) {
  return ({ field, validator }) => {
    let val = field.value;
    if (!val && val !== 0) { return [ true, null ]; }

    val = val + '';
    if ((opts.lt || opts.lt === 0) && val >= opts.lt) {
      return [ false, `Enter a number less than ${opts.lt}` ];
    }
    if ((opts.lte || opts.lte === 0) && val > opts.lte) {
      return [ false, `Enter a number less than or equal to ${opts.lte}` ];
    }
    if ((opts.gt || opts.gt === 0) && val <= opts.gt) {
      return [ false, `Enter a number greater than ${opts.gt}` ];
    }
    if ((opts.gte || opts.gte === 0) && val < opts.gte) {
      return [ false, `Enter a number greater than or equal to ${opts.gte}` ];
    }
    return [ true, null ];
  };
}

export function isInt(opts) {
  return (items) => {
    const { field, validator } = items;
    let val = field.value;
    if (!val && val !== 0) { return [ true, null ]; }

    val = val + '';
    if (!validator.isInt(val)) {
      return [ false, 'Enter a valid number' ];
    }
    return isNumber(opts)(items);
  };
}

export function isEqualToField(fieldName) {
  return ({ field, form, validator }) => {
    const result = validator.equals(field.value, form.$(fieldName).value);
    return [ result, `Enter the same value as the '${form.$(fieldName).label}' field` ];
  };
}

export function isGreaterThanField(fieldName, { isDate } = {}) {
  return ({ field, form, validator }) => {
    const a = field.value;
    const b = form.$(fieldName).value;
    if (a == null || a === '' || b == null || b === '') { return [ true, '' ]; }

    const result = isDate ? moment(a).isAfter(b) : a > b;
    return [ result, `Enter a value higher than the '${form.$(fieldName).label}' field` ];
  };
}

export function dateGreaterToEqualField(fieldName) {
  return ({ field, form, validator }) => {
    const date = field.value ? moment(field.value) : null;

    const otherStr = form.$(fieldName).value;
    const otherDate = otherStr ? moment(otherStr) : null;

    // Don't worry about invalid dates
    if (date && otherDate && !date.isSameOrAfter(otherDate)) {
      return [ false, `This should not be less than the '${form.$(fieldName).label}' field` ];
    }

    return [ true, null ];
  };
}

export function maxLength(length, { min } = {}) {
  return ({ field, validator }) => {
    return [ validator.isLength(field.value, { min: min || 0, max: length }), `Value should not exceed ${length} characters` ];
  };
}

// Min length 8 and check haveibeenpwned if the password is too common
export async function isValidPassword({ field, validator }) {
  if (!field.value) { return [ true, null ]; }

  const minLength = validator.isLength(field.value, { min: 8 });
  if (!minLength) { return [ false, 'Passwords should be at least 8 characters long' ]; }

  const numPwns = await pwnedPassword(field.value, { addPadding: true });
  return [ !numPwns, 'This password is too common and is often tried in hack attempts' ];
}

export function isUrl(opts) {
  return ({ field, validator }) => {
    // If empty then ignore
    if (!field.value) { return [ true, null ]; }
    return [ validator.isURL(field.value, opts), opts.label || 'Url must be valid' ];
  };
}

export function isMatches(regex, label) {
  return ({ field, validator }) => {
    // If empty then ignore
    if (!field.value) { return [ true, null ]; }
    return [ validator.matches(field.value, regex), label ];
  };
}

export function checkIf(predicate, checks) {
  return m => {
    const isReq = !!predicate(m);
    if (!isReq) { return [ true, null ]; }

    for (let i = 0; i < checks.length; i++) {
      const result = checks[i](m);
      if (!result[0]) { return result; }
    }
    return [ true, null ];
  };
}

export function customError(check, errMessage) {
  return (c) => {
    const result = check(c);
    return [ result[0], errMessage ];
  };
}