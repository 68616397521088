import { observable, action, reaction, computed, makeObservable } from 'decorators';
import api from 'services/api';
import router from 'services/router';
import auth from 'services/auth';
import globalEvents from 'services/globalEvents';
import userService from 'services/data/user';
import { getAgeGender } from 'services/data/clients';

export default class SubNavSearchState {
  @observable isLoading = false;
  @observable.shallow currentClient;
  @observable autoFocus = false;

  @observable shareDoc = false;

  constructor({ onHide }) {
    makeObservable(this);
    this.onHide = onHide;
  }

  @computed get user() {
    return auth.user;
  }

  @computed get ageGender() {
    return getAgeGender(this.currentClient);
  }

  @computed get showCronometer() {
    return !userService.nutritionixAccess.isLoading
      && !userService.cronometerAccess.isLoading
      && (!userService.nutritionixAccess.value?.connected || userService.cronometerAccess.value?.connected);
  }

  @computed get showNutritionix() {
    return !userService.nutritionixAccess.isLoading
      && !userService.cronometerAccess.isLoading
      && userService.nutritionixAccess.value?.connected;
  }

  @computed get hasNutritionix() {
    return auth.hasFeature(f => f.pro)
      && this.currentClient
      && userService.nutritionixAccess.value
      && userService.nutritionixAccess.value.connected;
  }

  @computed get rupahealthLinked() {
    return auth.rupahealthSupported
      && !userService.rupahealthAccess.isLoading
      && !!userService.rupahealthAccess.value?.publishableKey;
  }

  @action onChange(option) {
    if (!option) { return; }
    router.navigateTo('clients.client', { id: option.value });
    router.setCurrentClient(option.value);
  }

  @action focus() {
    this.autoFocus = true;
  }

  @action clear() {
    this.currentClient = null;
    this.shareDoc = false;
    router.setCurrentClient(null);
  }

  @action showShareDoc() {
    this.shareDoc = true;
  }

  @action closeShareDoc() {
    this.shareDoc = false;
    this.onHide && this.onHide();
  }

  attachListeners() {
    this.detachListeners();
    this._syncDispose = reaction(() => ({ clId: router.currentClientId, c: auth.isClinician }), () => this._syncClient());
    this._eventDispose = globalEvents.on('client.updated', this._syncClient, this);
    this._syncClient();
  }

  detachListeners() {
    if (this._syncDispose) {
      this._syncDispose();
      this._syncDispose = null;
    }

    if (this._eventDispose) {
      this._eventDispose();
      this._eventDispose = null;
    }

    if (this._getClientPromise) {
      this._getClientPromise.cancel();
      this._getClientPromise = null;
    }
  }

  dispose() {
    this.detachListeners();
  }

  @action _syncClient(data) {
    const clientId = router.currentClientId;
    if (clientId !== this._oldClientId) {
      this.currentClient = null;
      this.shareDoc = false;
    }
    this._oldClientId = clientId;
    if (!clientId || !auth.isClinician) {
      if (this._getClientPromise) {
        this._getClientPromise.cancel();
        this._getClientPromise = null;
      }
      this._oldClientId = null;
      this.currentClient = null;
      this.shareDoc = false;
      return;
    }
    if (data && data.id && data.id !== clientId) { return; }

    if (this._getClientPromise) {
      this._getClientPromise.cancel();
      this._getClientPromise = null;
    }

    if (!this.currentClient) {
      this.isLoading = true;
    }
    this._getClientPromise = api.get(`clients/${clientId}/summary`)
      .then(action((res) => {
        this.currentClient = res.data;
        return null;
      }))
      .catch(action(() => {
        this.currentClient = null;
        this.shareDoc = false;
        return null;
      }))
      .finally(action(() => {
        this.isLoading = false;
      }));

    this._getClientPromise.done();
  }
}