import { observable, action, makeObservable } from 'decorators';
import moment from 'moment';

class Formats {
  @observable shortDateFormat;
  @observable shortshortDateFormat;
  @observable longDateFormat;
  @observable shortTimeFormat;
  @observable datePlaceholder;
  @observable.shallow datePickerParsing = [];
  @observable.shallow timePickerParsing = [];
  @observable.ref now = moment();

  constructor() {
    makeObservable(this);
  }
}

export const formats = new Formats();

// Update 'now' on formats so that things that use it will update over time
setInterval(action(() => {
  formats.now = moment();
}), 1000);

export function rebindDateFormats(countryCode, isUSCulture) {
  formats.shortDateFormat = isUSCulture ? 'MMM D, YYYY' : 'D MMM YYYY';
  formats.shortshortDateFormat = isUSCulture ? 'M/D/YYYY' : 'D/M/YYYY';
  formats.longDateFormat = isUSCulture ? 'MMMM Do, YYYY' : 'Do MMMM YYYY';
  formats.shortTimeFormat = 'h:mm a';
  formats.datePlaceholder = isUSCulture ? 'MM/DD/YYYY' : 'DD/MM/YYYY';
  formats.datePickerParsing.replace(isUSCulture ? [ 'M-D-YY', 'M-D-YYYY', 'MMM D, YYYY' ] : [ 'D-M-YY', 'D-M-YYYY', 'D MMM YYYY' ]);
  formats.timePickerParsing.replace([ 'hmm', 'hmmA', 'H:m', 'h:ma', 'h:m a', 'h:mA', 'h:m A' ]);
}
rebindDateFormats('US', true);

export function fromNow(date, { hideSuffix, fixupRecent } = {}) {
  // Set a dependency on the current now, but actually use the current moment when calculating
  const now = formats.now && moment();
  date = moment(date);
  // Sometimes a recent time might be after the current now, possible clock skew?
  if (fixupRecent && date.isAfter(now)) {
    date = now.subtract(1, 'seconds');
  }
  return date.from(now, hideSuffix);
}

export function shortDate(date, { incDay, tz } = {}) {
  let format = formats.shortDateFormat;
  if (incDay) { format = 'ddd, ' + format; }
  return date ? (tz ? moment.tz(date, tz) : moment(date)).format(format) : '';
}

export function shortshortDate(date, { tz } = {}) {
  return date ? (tz ? moment.tz(date, tz) : moment(date)).format(formats.shortshortDateFormat) : '';
}

export function longDate(date, incDay) {
  let format = formats.longDateFormat;
  if (incDay) { format = 'dddd, ' + format; }
  return date ? moment(date).format(format) : '';
}

export function shortTime(date, { tz } = {}) {
  return date ? (tz ? moment.tz(date, tz) : moment(date)).format(formats.shortTimeFormat) : '';
}

export function shortTimeSpan(timespan) {
  return timespan ? moment(timespan, 'H:m').format(formats.shortTimeFormat) : '';
}

export function datesSame(first, second) {
  // Just compare the date part, not time
  return (first && second) ? moment(first).startOf('day').isSame(moment(second).startOf('day')) : !first === !second;
}

export function dateRangeShort(start, end, { tz } = {}) {
  if (!end) { return shortshortDate(start, { tz }); }
  if (!start) { return shortshortDate(end, { tz }); }
  if (moment(start).isSame(end)) { return shortshortDate(start, { tz }); }
  return `${shortshortDate(start, { tz })} - ${shortshortDate(end, { tz })}`;
}

export function dateRange(start, end, { incDay, tz } = {}) {
  if (!end) { return shortDate(start, { incDay, tz }); }
  if (!start) { return shortDate(end, { incDay, tz }); }
  if (moment(start).isSame(end)) { return shortDate(start, { incDay, tz }); }
  return `${shortDate(start, { incDay, tz })} - ${shortDate(end, { incDay, tz })}`;
}

export function rangeSame(first, second) {
  return datesSame(first.start, second.start) && datesSame(first.end, second.end);
}

export function fixupRange(range) {
  const needsFixup = range.start && range.end && range.start.isAfter(range.end);
  return {
    start: needsFixup ? range.end : range.start,
    end: needsFixup ? range.start : range.end
  };
}

export function timeRange(start, end, { incDay, hideDay, long } = {}) {
  const dateFunc = long ? longDate : shortDate;
  const parts = [];
  if (!hideDay) { parts.push(dateFunc(start, incDay)); }
  parts.push(shortTime(start));
  parts.push('-');
  if (!hideDay && dateFunc(start) !== dateFunc(end)) {
    parts.push(dateFunc(end, incDay));
  }
  parts.push(shortTime(end));
  return parts.join(' ');
}

export function canShowAdjustedDate(dob, refDate) {
  if (!dob) { return false; }
  const now = refDate ? moment(refDate) : moment();
  const years = now.diff(moment(dob), 'years');
  return years < 2 && years >= 0;
};

export function getAge(dob, { adjusted, hidePostfix, refDate } = {}) {
  if (!dob) { return ''; }

  const now = refDate ? moment(refDate) : moment();
  const duration = moment.duration(now.diff(dob));

  const years = Math.abs(duration.years());
  let age = years === 0 ? '' : `${years} year${(years === 1 ? '' : 's')} `;

  // If less than 5 years old then show months
  if (years < 5) {
    const months = Math.abs(duration.months());
    if (years > 0 || months > 0) { age = age + `${months} month${(months === 1 ? '' : 's')} `; }
    // If less than 1 year, also show days
    if (years === 0) {
      const days = Math.abs(duration.days());
      age = age + `${days} day${(days === 1 ? '' : 's')} `;
    }
  }

  if (duration.years() < 0 || duration.months() < 0 || duration.days() < 0) {
    age = '-' + age;
  }

  if (!hidePostfix) {
    age = age + 'old';
  }

  // If less than 2 years, then show adjusted age as well, if exists
  if (adjusted && years < 2) {
    const adjAge = getAge(adjusted, { hidePostfix: true, refDate });
    if (adjAge) {
      age = `${age} - ${adjAge} adjusted`;
    }
  }

  return age.trim();
}

export function dateToTime(date) {
  let hourMinValue = null;
  if (date) {
    // Try to parse in expected format
    let mom = moment(date, [ 'H:m:s', 'H:m' ], true);

    // Fallback to parsing full dates as well
    if (!mom.isValid()) { mom = moment(date); }

    // If we can parse it then change back to expected simplified format
    // we don't care about seconds (for Firefox)
    if (mom.isValid()) {
      hourMinValue = mom.format('HH:mm');
    }
  }
  return hourMinValue;
}

export function dateToJson(date) {
  if (!date) { return null; }
  return moment(date).format('YYYY-MM-DD');
}

export function dateToIso(date) {
  if (!date) { return null; }
  return moment(date).toISOString();
}

/*************************************
 * Nth day functions, handles 'from end of month' too when n is negative
 * ************************************/
export const reoccurDayOptions = Array(7).fill(null).map((_, i) => ({ value: i, name: moment().day(i).format('dddd') }));
export const reoccurNthOptions = [
  { value: 1, name: '1st' },
  { value: 2, name: '2nd' },
  { value: 3, name: '3rd' },
  { value: 4, name: '4th' },
  { value: -1, name: 'last' },
  { value: -2, name: '2nd last' },
  { value: -3, name: '3rd last' },
  { value: -4, name: '4th last' }
];

export function getNthAndDayFromPeriod(period) {
  if (!period || period > 28 || period < -28) { return null; }
  const sign = period < 0 ? -1 : 1;
  period = Math.abs(period) - 1;
  return { nth: sign * (Math.floor(period / 7) + 1), day: period % 7 };
}

export function nthAndDaysToPeriod(nth, day) {
  if (nth == null || day == null) { return 0; }
  const period = (Math.abs(nth) - 1) * 7 + day + 1;
  return nth < 0 ? -period : period;
}

export function getNthAndDaysOfDate(date, useLast, { tz } = {}) {
  if (!date) { return { nth: null, day: null }; }
  date = tz ? moment.tz(date, tz) : moment(date);
  const month = date.month();
  const day = date.day();
  if (useLast) {
    let nth = -1;
    while (date.add(1, 'w').month() === month) {
      nth--;
    }
    if (nth < -4) { nth = -4; }
    return { nth, day };
  } else {
    let nth = 1;
    while (date.add(-1, 'w').month() === month) {
      nth++;
    }
    if (nth > 4) { nth = 4; }
    return { nth, day };
  }
}

export function getNthDescription(period) {
  const n = getNthAndDayFromPeriod(period);
  if (!n) { return null; }
  const { nth, day } = n;
  const dayOfWeek = moment().day(day).format('dddd');
  const opt = reoccurNthOptions.find(n => n.value === nth).name;
  return `${opt} ${dayOfWeek} of the month`;
}

export function getNthWeekdayAfterTimes(start, period, times, { tz } = {}) {
  const n = getNthAndDayFromPeriod(period);
  if (!n) { return null; }

  const { nth, day } = n;
  let result = getNthWeekdayAfter(start, nth, day, { tz });
  for (let i = 0; i < times - 1; i++) {
    if (!result) { break; }
    result.add(1, 'M');
    result = getNthWeekdayOfMonth(result, nth, day, { tz });
  }
  return result;
}

export function findNthTimesBetweenDates(start, end, period, { tz } = {}) {
  if (!start || !end) { return null; }

  const n = getNthAndDayFromPeriod(period);
  if (!n) { return null; }

  const { nth, day } = n;
  const first = getNthWeekdayAfter(start, nth, day, { tz });
  const last = getNthWeekdayBeforeOrEqual(end, nth, day, { tz });
  if (first.isAfter(last)) { return 0; }
  // Take the difference in months, then add 1 as start is the first reoccur time
  return last.startOf('month').diff(first.startOf('month'), 'months') + 1;
}

// Sunday = 0 day, nth is 1 - 4
function getNthWeekdayAfter(date, nth, day, { tz } = {}) {
  const d = tz ? moment.tz(date, tz) : moment(date);
  let result = getNthWeekdayOfMonth(d, nth, day, { tz });
  if (result.isSameOrBefore(d)) {
    d.add(1, 'M'); // This is fine if the number of total days in the month decreases
    result = getNthWeekdayOfMonth(d, nth, day, { tz });
  }
  return result;
}

// Sunday = 0 day, nth is 1 - 4
function getNthWeekdayBeforeOrEqual(date, nth, day, { tz } = {}) {
  const d = tz ? moment.tz(date, tz) : moment(date);
  let result = getNthWeekdayOfMonth(d, nth, day, { tz });
  if (result.isAfter(d)) {
    d.add(-1, 'M'); // This is fine if the number of total days in the month decreases
    result = getNthWeekdayOfMonth(d, nth, day, { tz });
  }
  return result;
}

// Sunday = 0 day, nth is 1 - 4
function getNthWeekdayOfMonth(date, nth, day, { tz } = {}) {
  const result = tz ? moment.tz(date, tz) : moment(date);
  const hour = result.hour();
  const min = result.minute();
  const sec = result.second();

  const month = result.month();
  if (nth > 0) {
    // Find the first day of the month
    result.startOf('month').startOf('week').add(day, 'd');
    if (result.month() !== month) { result.add(1, 'w'); }

    // Now find the nth occurance
    result.add(nth - 1, 'w');
  } else {
    // Find the last day of the month
    result.endOf('month').startOf('week').add(day, 'd');
    if (result.month() !== month) { result.subtract(1, 'w'); }

    // Now find the nth occurance
    result.add(nth + 1, 'w');
  }

  return result.add(hour, 'h').add(min, 'm').add(sec, 's');
}