import config from 'config';
import { observable, reaction, action, computed, makeObservable } from 'decorators';
import auth from 'services/auth';
import { hashObj } from 'services/hasher';
import log from 'services/log';
import axios from 'axios';

const here = config.here;
export default class AddressAutoFiller {
  @observable isSearching;

  constructor(getAddress, onAddressChange, disableTracking) {
    makeObservable(this);
    this._getAddress = getAddress;
    this._onChange = onAddressChange;
    this._currentHash = this.getAddressHash();
    if (!disableTracking) {
      this._changeDispose = reaction(() => this.getAddressHash(), () => this.refresh());
    }
  }

  dispose() {
    this._changeDispose && this._changeDispose();
  }

  @computed get address() {
    return this._getAddress();
  }

  @action refresh() {
    const hash = this.getAddressHash();
    if (hash === this._currentHash) { return; }

    const currentHash = this._currentHash = hash;

    const streetAdd = [];
    const address = this.address;
    if (!address) { return; }

    if (address.street1) { streetAdd.push(address.street1); }
    if (address.street2) { streetAdd.push(address.street2); }
    if (address.street3) { streetAdd.push(address.street3); }

    if (streetAdd.length === 0 || (!address.suburb && !address.postcode)) {
      address.latitude = null;
      address.longitude = null;
      this._onChange && this._onChange(address);
      return;
    }

    const qqObj = {
      street: streetAdd.join(','),
      city: address.suburb,
      state: address.state,
      postalCode: address.postcode,
      country: address.country || auth.user.country
    };
    const qq = encodeURIComponent(Object.keys(qqObj).filter(k => qqObj[k]).map(k => `${k}=${qqObj[k]}`).join(';'));

    /*
    const data = {
      street: streetAdd.join(','),
      city: address.suburb,
      state: address.state,
      postalcode: address.postcode,
      country: address.country || auth.user.country,
      apiKey: here.apiKey,
      gen: 6,
      language: 'en-US',
      locationattributes: 'ar' // address types only in output
    };

    const cc = data.country.toLowerCase();
    if (cc === 'us' || cc === 'usa' || cc === 'united states') {
      data.additionaldata = 'IncludeZipAddon,true';
    } */

    this.isSearching = true;
    Promise.resolve(axios({
      method: 'get',
      url: `https://geocode.search.hereapi.com/v1/geocode?qq=${qq}&types=address&apiKey=${here.apiKey}`
    }))
      .then(action(({ data: res }) => {
        if (this._currentHash !== currentHash) { return null; }

        const loc = res.items[0];
        if (loc) {
          address.latitude = loc.position.lat;
          address.longitude = loc.position.lng;

          const locAdd = loc.address;
          if (!address.suburb && locAdd.city) {
            address.suburb = locAdd.city;
          }

          if (!address.state && locAdd.stateCode) {
            address.state = locAdd.stateCode;
          }

          if (!address.postcode && locAdd.postalCode) {
            address.postcode = locAdd.postalCode;
          }
        } else {
          address.latitude = null;
          address.longitude = null;
        }
        return null;
      }))
      .catch(action((err) => {
        address.latitude = null;
        address.longitude = null;
        log.log(err);
        return null;
      }))
      .finally(action(() => {
        this._onChange && this._onChange(address);
        this.isSearching = false;
        return null;
      }))
      .done();
  }

  getAddressHash() {
    // Only hash on to non-lat/long fields
    const a = this.address;
    const obj = a ? { c: a.country, p: a.postcode, s: a.state, s1: a.street1, s2: a.street2, s3: a.street3, su: a.suburb } : null;
    return hashObj(obj);
  }
}