import { forwardRef } from 'react';
import { observer } from 'decorators';
import { makeStyles } from 'hooks';
import DropDownButton from 'components/dropdown';
import { Checkbox, MenuList, MenuItem, ListItemText } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  checkbox: {
    pointerEvents: 'none',
    paddingLeft: 0
  },
  list: {
    maxHeight: 450,
    overflow: 'auto'
  }
}));

function safeValue(value) {
  return value === null || value === undefined ? '' : value + '';
}

export default observer(forwardRef(function SelectMultiButton({ options, value, valueKey, nameKey, isLoading, onChange, buttonContent, ...other }, ref) {
  const classes = useStyles();

  valueKey = valueKey || 'value';
  nameKey = nameKey || 'name';
  value = value.map(v => safeValue(v));

  const handleClick = (e, val) => {
    e.preventDefault();
    e.stopPropagation();

    if (!onChange) { return; }

    const newValue = value.slice(0);
    if (newValue.includes(val)) {
      newValue.splice(newValue.indexOf(val), 1);
    } else {
      newValue.push(val);
    }

    onChange(options.filter(o => newValue.includes(o[valueKey])));
  };

  return <DropDownButton ref={ref} buttonContent={buttonContent} elementClass={classes.list} {...other}>
    <MenuList disablePadding>
      { options && options.map((o, i) => {
        const isSelected = value.includes(safeValue(o[valueKey]));
        return <MenuItem key={i} selected={isSelected} value={safeValue(o[valueKey])} onClick={e => handleClick(e, o[valueKey])}>
          <Checkbox checked={isSelected} className={classes.checkbox} />
          <ListItemText primary={o[nameKey]} />
        </MenuItem>;
      })}
    </MenuList>
  </DropDownButton>;
}));