import { forwardRef, useCallback, useId, useRef } from 'react';
import cc from 'classnames';
import { Grid, Typography, Paper, Modal } from '@material-ui/core';
import SaveButton from 'components/button/save';
import { observer } from 'decorators';
import { useMount } from 'hooks';
import media from 'services/media';

import subNavStore from 'layout/subnav/subnav.store';

import useStyles from './modal.styles';
import CloseIcon from 'mdi-material-ui/Close';

let lastScrollTop = 0;
let isFixed = null;

export default observer(forwardRef(function ModalView(props, ref) {
  const {
    // Extended properties
    label, onClose: onCloseOuter, children, width, hashTracker, open, PaperComponent, scrollRef,
    gridClassName, modalClassName, contentClassName, headerClassName, disableBackdropClick, showNav, elevation, variant, BackdropProps,
    // Ignored properties
    onRequestClose, contentLabel, style, overlayClassName, onRendered,
    // Everything else
    ...other
  } = props;

  const classes = useStyles();
  const modalId = useId();
  const lastOpen = useRef(open);

  const onClose = useCallback((doNotBubble) => {
    // Return the scrolling
    if (isFixed === modalId) {
      const wrap = document.getElementById('root');
      const body = document.body;

      body.style.position = '';
      wrap.style.top = '';

      if (lastScrollTop) {
        window.scrollTo(0, lastScrollTop);
        lastScrollTop = 0;
      }
      isFixed = null;
    }

    if (!doNotBubble) {
      onCloseOuter && onCloseOuter(false);
    }
  }, [ onCloseOuter, modalId ]);
  const handleCloseBubble = useCallback((e, reason) => {
    if (disableBackdropClick && reason === 'backdropClick') { return; }
    onClose();
  }, [ onClose, disableBackdropClick ]);

  // Fix the background while the modal is up
  const onOpen = useCallback(() => {
    if (!isFixed) {
      const body = document.body;

      if (window.pageYOffset) {
        const wrap = document.getElementById('root');

        if (window.pageYOffset) {
          lastScrollTop = window.pageYOffset;
          wrap.style.top = `-${lastScrollTop}px`;
        }
      }

      body.style.position = 'fixed';
      isFixed = modalId;

      onRendered && onRendered();
    }
  }, [ onRendered, modalId ]);

  const onRef = useCallback(r => {
    if (ref) { if (typeof ref === 'function') { ref(r); } else { ref.current = r; } }
    if (r && open) {
      onOpen();
    }

    // iOS hack... sometimes need to make the modal disapear and reappear to get the scroll to work
    if (media.iOS && r) {
      const display = r.style.display;
      r.style.display = 'none';
      setTimeout(() => (r.style.display = display), 1);
    }
  }, [ open, onOpen, ref ]);

  useMount(() => {
    return () => {
      onClose(true);
    };
  });

  // Call onOpen/onClose as open prop changes...
  if (open !== lastOpen.current) {
    if (open) {
      onOpen();
    } else {
      onClose(true);
    }
  }
  lastOpen.current = open;

  let containerStyle;
  if (showNav && !media.is('xs')) {
    const top = media.topPadding;
    const left = subNavStore.sidePadding;
    containerStyle = { top, left };
  }

  const backProps = BackdropProps || {};
  if (containerStyle) {
    backProps.style = Object.assign({}, backProps.style || {}, containerStyle);
  }
  const Component = PaperComponent || Paper;
  const labelId = 'modalLabel_' + modalId;
  return <Modal
    open={open}
    ref={onRef}
    onClose={handleCloseBubble}
    disableScrollLock
    disableEnforceFocus
    style={containerStyle}
    BackdropProps={{ style: containerStyle }}
    {...other}
  >
    <div className={classes.modalContainer} tabIndex={-1} ref={scrollRef}>
      <Grid container alignItems="center" direction="column" spacing={2} className={classes.modelNoOutline}>
        <Grid item xs className={classes.noPadding} />
        <Grid item className={cc(classes.maxWidth, gridClassName)}>
          <Component
            role="dialog"
            className={cc(classes.modal, modalClassName)}
            style={width ? { maxWidth: width } : null}
            elevation={elevation}
            variant={variant}
            aria-labelledby={label ? labelId : undefined}
          >
            { label &&
              <div className={cc(classes.modalHeader, headerClassName)}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs id={labelId}>
                    { typeof label === 'string' ? <Typography variant="h6">{label}</Typography> : label}
                  </Grid>
                  {onCloseOuter &&
                    <Grid item>
                      <SaveButton icon cancel aria-label="Close" onClick={handleCloseBubble} hashTracker={hashTracker}>
                        <CloseIcon className={classes.modalHeaderClose} />
                      </SaveButton>
                    </Grid>
                  }
                </Grid>
              </div>
            }
            <div className={cc(classes.modalContent, contentClassName)}>
              { children }
              { !label && onCloseOuter &&
                <div className={classes.closeContainer}>
                  <SaveButton icon cancel aria-label="Close" onClick={handleCloseBubble} hashTracker={hashTracker}>
                    <CloseIcon className={classes.modalHeaderClose} />
                  </SaveButton>
                </div>
              }
            </div>
          </Component>
        </Grid>
        <Grid item xs className={classes.noPadding} />
      </Grid>
    </div>
  </Modal>;
}));