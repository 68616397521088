export default class Thottler {
  constructor(delay) {
    this._delay = delay == null ? 300 : delay;
    this._id = 0;
  }

  push(func) {
    this.cancel();

    if (this._throttleTimeout) { clearTimeout(this._throttleTimeout); }
    this._throttleTimeout = setTimeout(() => this.runNow(), this._delay);
    this._promiseFunc = func;
    this._promise = new Promise((resolve, reject) => {
      this._promiseResolve = resolve;
      this._promiseReject = reject;
      return null;
    });

    return this._promise;
  }

  runNow() {
    this._throttleTimeout = null;
    if (this._promiseFunc) {
      Promise.resolve(this._promiseFunc(this._id++)).then(this._promiseResolve, this._promiseReject).done();
    }
    this._promise = null;
    this.cancel();
  }

  cancel() {
    if (this._promise) { this._promise.cancel(); this._promise = null; }
    if (this._throttleTimeout) { clearTimeout(this._throttleTimeout); this._throttleTimeout = null; }
    this._promiseFunc = null;
    this._promiseResolve = null;
    this._promiseReject = null;
  }

  dispose() {
    this.cancel();
  }
}