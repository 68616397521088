import { PureComponent, createRef } from 'react';
import EmptyFiller from 'components/emptyFiller';
import NavLink from 'components/navLink';
import log from 'services/log';

import ErrorIcon from 'mdi-material-ui/AlertCircleOutline';

const handleReload = () => window.location.reload();

// https://reactjs.org/docs/error-boundaries.html
export default
class ErrorBoundary extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this._errorRef = createRef();
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    if (!this.props?.doNotLog) {
      log.error('Rendering Error', error, info);
    }
    const { onError, onRefresh } = this.props;
    onError && onError(error, info);
    if (onRefresh && this._errorRef.current) {
      onRefresh(this._errorRef.current.offsetHeight);
    }
  }

  render() {
    if (this.state.hasError) {
      const { small } = this.props;
      return <EmptyFiller
        ref={this._errorRef}
        center
        small={small}
        icon={<ErrorIcon />}
        header="Something Went Wrong"
        subheader={ small
          ? undefined
          : <span>
            An issue occured while loading this page. The error was logged and will be investigated further. <br />
            <NavLink onClick={handleReload}>Please refresh your browser to continue.</NavLink>
          </span>
        }
      />;
    }

    return this.props.children;
  }
}