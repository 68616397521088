import { Fragment, forwardRef, useCallback, useImperativeHandle } from 'react';
import { observer, observable, action, makeObservable } from 'decorators';
import { makeStyles, useDisposable } from 'hooks';
import cc from 'classnames';
import { Grid, Tooltip } from '@material-ui/core';
import Button from './index';
import { ValidationError } from 'errors';
import Modal from 'components/modal';
import log from 'services/log';
import globalEvents, { CancelPageChangeError } from 'services/globalEvents';
import { DialogClosedError } from 'services/dialog';

import { Alert, Check } from 'mdi-material-ui';

const useStyles = makeStyles(theme => ({
  success: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.dark
    }
  },
  error: {
    color: theme.palette.getContrastText(theme.palette.error.main),
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  }
}));

class SaveCancelButtonStore {
  @observable success = false;
  @observable error = null;
  @observable isSaving = false;
  @observable isPageExiting = false;

  constructor(trackPage, hashTracker, showGeneralErrors) {
    makeObservable(this);
    this.hashTracker = hashTracker;
    this.showGeneralErrors = showGeneralErrors;
    this._pageTracking = trackPage;
    if (trackPage) { this._setupPageTracking(); }
  }

  dispose() {
    this._isDisposed = true;
    this._cleanupPageTracking();
  }

  @action executeSave(isSwitchingPages, e, saveFunc) {
    this.isSaving = true;
    this.success = false;
    this.error = false;
    return new Promise((resolve, reject) => {
      try {
        Promise.resolve(saveFunc(isSwitchingPages, e)).then(resolve).catch(reject).done();
      } catch (err) {
        reject(err);
      }
    })
      .then(action(() => {
        if (this._isDisposed) { return; }

        this.success = true;
        if (this.hashTracker) { this.hashTracker.reset(); }
      }))
      .catch(DialogClosedError, () => {}) // Do nothing on cancel dialog, since it was cancelled
      .catch(action(err => {
        if (this._isDisposed) { return; }

        // Handle validation error automatically
        if (err?.name === ValidationError.name) {
          this.error = 'Complete all required fields and try again';
        } else if (err.response?.data?.error) {
          this.error = err.response.data.error.message;
        } else if (this.showGeneralErrors) {
          this.error = err.message;
        } else {
          log.catchAndNotify(err);
          this.error = 'An unexpected error happened, please try again';
        }

        if (this.hashTracker) { this.hashTracker.resetSecondary(); }
        throw err;
      }))
      .finally(action(() => {
        if (this._isDisposed) { return; }
        this.isSaving = false;
      }));
  }

  @action completePageExit(cancel, save, saveFunc) {
    if (this._pageResolveReject) {
      if (cancel) {
        this._pageResolveReject.reject(new CancelPageChangeError('Cancelled close'));
        this.isPageExiting = false;
      } else {
        if (save && saveFunc) {
          // Note: Unhandled exceptions etc are already logged by this point
          this._startedPageSave = true;
          this.executeSave(true, null, saveFunc)
            .then(action(() => {
              this._pageResolveReject.resolve();
              this.isPageExiting = false;
              return null;
            }))
            .catch(() => {
              this._pageResolveReject.reject(new CancelPageChangeError('Cancelled close due to error'));
            })
            .finally(() => (this._startedPageSave = false))
            .done();
        } else {
          this._pageResolveReject.resolve();
          this.isPageExiting = false;
        }
      }
    } else {
      this.isPageExiting = false;
    }
  }

  @action executeExit() {
    const { hashTracker } = this;
    if (!this._pageTracking || !hashTracker || !hashTracker.isDirty) { return Promise.resolve(); }

    this.isPageExiting = true;
    this._pageExitPromise = this._pageExitPromise || new Promise((resolve, reject) => {
      this._pageResolveReject = { resolve, reject };
    })
      .finally(() => (this._pageExitPromise = null));

    return this._pageExitPromise;
  }

  _setupPageTracking() {
    this._cleanupPageTracking();
    this._pageDispose = globalEvents.onPageChange(() => this.executeExit());

    this._windowDispose = globalEvents.onUnload(() => {
      const { hashTracker } = this;
      if (!hashTracker || !hashTracker.isDirty) { return; }

      return 'Discard changes?';
    });
  }

  _cleanupPageTracking() {
    if (this._pageDispose) {
      this._pageDispose();
      this._pageDispose = null;
    }

    if (this._windowDispose) {
      this._windowDispose();
      this._windowDispose = null;
    }

    if (!this._startedPageSave && this._pageResolveReject) {
      const err = new Error('KALIX_CLOSING');
      this._pageResolveReject.reject(err);
      this._pageResolveReject = null;
    }
  }
}

export default observer(forwardRef(function SaveCancelButton({ className, saveFunc, cancel, onClick, children, hashTracker, trackPage, showGeneralErrors, isLoading, discardButtonContent, instanceRef, error: externalError, ...other }, ref) {
  const classes = useStyles();
  const store = useDisposable(() => new SaveCancelButtonStore(trackPage, hashTracker, showGeneralErrors), [ trackPage, hashTracker, showGeneralErrors ]);

  const { isSaving, success, error, isPageExiting } = store;

  const isDirty = hashTracker && hashTracker.isDirty;
  const isSuccess = success && !isDirty && !externalError;
  const isError = externalError || (error && (!hashTracker || !hashTracker.isDirtySecondary));
  const allClasses = cc(className, isSuccess && classes.success, isError && classes.error);

  const cLabel = 'Discard Changes?';
  const cMessage = 'There are unsaved changes. Do you wish to discard them?';

  const handleClick = useCallback(e => {
    if (cancel) {
      onClick && onClick(e);
    } else {
      // Note: Unhandled exceptions etc are already logged by this point
      store.executeSave(false, e, saveFunc).catch(() => {}).done();
    }
  }, [ cancel, onClick, store, saveFunc ]);

  useImperativeHandle(instanceRef, () => ({
    executeExit: () => store.executeExit()
  }), [ store ]);

  const startIcon = isError ? <Alert /> : (isSuccess ? <Check /> : null);
  const iconProp = startIcon ? { startIcon } : {};
  const button = <Button
    ref={ref}
    className={allClasses}
    isLoading={isLoading || isSaving}
    confirmLabel={cancel && isDirty ? cLabel : undefined}
    confirm={cancel && isDirty ? cMessage : undefined}
    confirmButtonPrimaryLabel={cancel && isDirty ? 'Discard' : undefined}
    onClick={handleClick}
    {...iconProp}
    {...other}
  >
    { children }
  </Button>;

  const completeButton = isError && !isSaving ? <Tooltip title={externalError || error}>{button}</Tooltip> : button;

  return <Fragment>
    { completeButton }
    { isPageExiting && <PageExitingView store={store} saveFunc={saveFunc} cLabel={cLabel} cMessage={cMessage} discardButtonContent={discardButtonContent} /> }
  </Fragment>;
}));

const PageExitingView = observer(function PageExitingView({ store, saveFunc, cLabel, cMessage, discardButtonContent }) {
  const { isSaving, success, error } = store;

  const hasSave = !!saveFunc;
  const sLabel = hasSave ? 'Save Changes?' : cLabel;
  const sMessage = hasSave ? 'There are unsaved changes. Do you want to save them before continuing?' : cMessage;

  const handleDiscard = useCallback(() => store.completePageExit(false, false, null), [ store ]);
  const handleSave = useCallback(() => store.completePageExit(false, true, saveFunc), [ store, saveFunc ]);
  const handleCancel = useCallback(() => store.completePageExit(true, false, null), [ store ]);

  // We might use this component in two different places depending on whether we have save option...
  const discardButton = <Button color={hasSave ? 'secondary' : 'primary'} onClick={handleDiscard}>{ discardButtonContent || 'Discard' }</Button>;

  const startIcon = error ? <Alert /> : (success ? <Check /> : null);
  const iconProp = startIcon ? { startIcon } : {};
  let saveButton = hasSave &&
    <Button color="primary" onClick={handleSave} isLoading={isSaving} {...iconProp}>
      Save
    </Button>;

  if (hasSave && error && !isSaving) {
    saveButton = <Tooltip key="saveTooltip" title={error}>{saveButton}</Tooltip>;
  }

  return <Modal key="modal" open label={sLabel} onClose={handleCancel}>
    <Grid container direction="column" spacing={2}>
      <Grid item>
        { sMessage }
      </Grid>
      <Grid item>
        <Grid container>
          { hasSave &&
            <Grid item>
              { discardButton }
            </Grid>
          }
          <Grid item xs />
          <Grid item>
            <Button onClick={handleCancel}>Cancel</Button>
            { !hasSave && discardButton }
            { saveButton }
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Modal>;
});