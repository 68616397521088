import { useCallback } from 'react';
import cc from 'classnames';
import { observer } from 'decorators';
import { makeStyles, useDisposable, useResizeDetector } from 'hooks';
import { Grid, Typography } from '@material-ui/core';
import Map from 'components/map';
import AddressAutoFiller from './addressAutoFiller';
import Input from './input';
import media from 'services/media';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative'
  },
  mapHolder: {
    position: 'absolute',
    top: theme.spacing(1),
    left: theme.spacing(1),
    right: theme.spacing(1),
    bottom: theme.spacing(1)
  }
}));

export default observer(function AddressInput({ form, field, onUpdated, label, autoComplete, forceSmall, disabled, onRefresh: handleReflow }) {
  const classes = useStyles();
  const filler = useDisposable(() => new AddressAutoFiller(() => form.$(field).values(), a => {
    form.$(field).set('value', a);
    onUpdated && onUpdated(a);
  }, true), [ form, field, onUpdated ]);

  const handleRefresh = useCallback(() => filler.refresh(), [ filler ]);
  const { height, ref: resizeRef } = useResizeDetector();

  const isSmall = media.is('xs');
  const inner = <Grid container alignItems="stretch" spacing={2}>
    <Grid item xs={12} sm={forceSmall ? 12 : 7}>
      <Input shrink form={form} field={field + '.street1'} onBlur={handleRefresh} autoComplete={autoComplete ? 'address-line1' : undefined} disabled={disabled} onRefresh={handleReflow} />
      <Input shrink form={form} field={field + '.street2'} onBlur={handleRefresh} autoComplete={autoComplete ? 'address-line2' : undefined} disabled={disabled} onRefresh={handleReflow} />
      <Input shrink form={form} field={field + '.street3'} onBlur={handleRefresh} autoComplete={autoComplete ? 'address-line3' : undefined} disabled={disabled} onRefresh={handleReflow} />
      <Input shrink form={form} field={field + '.suburb'} onBlur={handleRefresh} autoComplete={autoComplete ? 'address-level2' : undefined} disabled={disabled} onRefresh={handleReflow} />
      <Grid container spacing={isSmall ? 0 : 1}>
        <Grid item xs={12} sm>
          <Input shrink form={form} field={field + '.state'} onBlur={handleRefresh} autoComplete={autoComplete ? 'address-level1' : undefined} disabled={disabled} onRefresh={handleReflow} />
        </Grid>
        <Grid item xs={12} sm>
          <Input shrink form={form} field={field + '.postcode'} onBlur={handleRefresh} autoComplete={autoComplete ? 'postal-code' : undefined} disabled={disabled} onRefresh={handleReflow} />
        </Grid>
      </Grid>
      <Input shrink form={form} field={field + '.country'} onBlur={handleRefresh} autoComplete={autoComplete ? 'country' : undefined} disabled={disabled} onRefresh={handleReflow} />
    </Grid>
    <Grid item xs={12} sm={forceSmall ? 12 : 5} className={classes.container}>
      <div ref={resizeRef} className={cc(!isSmall && !forceSmall && classes.mapHolder)}>
        <Map address={filler.address} isLoading={filler.isSearching} height={isSmall || forceSmall ? undefined : height} onRefresh={handleReflow} />
      </div>
    </Grid>
  </Grid>;

  if (!label) { return inner; }

  return <Grid container direction="column">
    <Grid item>
      <Typography variant="subtitle1">{label}</Typography>
    </Grid>
    <Grid item>
      { inner }
    </Grid>
  </Grid>;
});