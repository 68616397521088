import auth from 'services/auth';

export default [
  { name: 'billtemplates.new', path: '/billtemplates/new', sameView: 'billtemplates.template' },
  { name: 'bills', path: '/bills', view: () => import('./bills') },
  { name: 'bills.overdue', path: '/bills/overdue', sameView: 'bills' },
  { name: 'bills.payments', path: '/bills/payments', view: () => import('./payments') },
  { name: 'billtemplates', path: '/billtemplates', view: () => import('./templates') },
  { name: 'billitems', path: '/billitems', view: () => import('./billItems') },
  { name: 'insurers', path: '/insurers', view: () => import('./insurers') },
  { name: 'batches', path: '/batches', view: () => import('./batches') },
  { name: 'batches.incoming', path: '/batches/incoming', sameView: 'batches' },
  { name: 'bills.new', path: '/bills/new', sameView: 'bills.bill' },
  { name: 'bills.bill', path: '/bills/:id', view: () => import('./bill') },
  { name: 'bills.bill.details', path: '/bills/:id/details', sameView: 'bills.bill' },
  { name: 'bills.bill.messages', path: '/bills/:id/messages', sameView: 'bills.bill' },
  { name: 'bills.bill.tasks', path: '/bills/:id/tasks', sameView: 'bills.bill' },
  { name: 'bills.bill.batches', path: '/bills/:id/batches', sameView: 'bills.bill' },
  { name: 'billtemplates.template', path: '/billtemplates/:id', view: () => import('./template') },
  { name: 'batches.new', path: '/batches/new', sameView: 'batches.batch' },
  { name: 'batches.batch', path: '/batches/:id', view: () => import('./batch') },
  { name: 'bills.bill.view', path: '/bills/:id/view', view: () => import('./viewBill'), hideFooter: true }
];

export function nav(user) {
  if (!user || !auth.hasFeature(f => f.bills)) { return []; } // Lock the whole section out if no access to bills

  const bills = user.lang.firstUpper('bill.bills');
  const bill = user.lang.firstUpper('bill.bill');
  const billing = user.lang.firstUpper('bill.billing');

  const billsNavPath = `${billing}.${billing}.${bills}`;
  const paths = [
    { name: 'bills.new', title: `New ${bill}`, navPath: `${billing}.New.${bill}`, showNav: true },
    { name: 'bills', title: bills, navPath: billsNavPath, showNav: true, isNavRoot: true },
    { name: 'bills.overdue', title: `Overdue ${bills}`, navPath: `${billing}.${billing}.Overdue`, showNav: true },
    { name: 'bills.payments', title: 'Payments', navPath: `${billing}.${billing}.Payments`, showNav: true },
    { name: 'bills.bill', title: bill, navPath: billsNavPath },
    { name: 'bills.bill.details', title: bill, navPath: billsNavPath },
    { name: 'bills.bill.messages', title: bill, navPath: billsNavPath },
    { name: 'bills.bill.tasks', title: bill, navPath: billsNavPath },
    { name: 'bills.bill.view', title: bill, navPath: billsNavPath }
  ];

  if (user.roles.isAdministrator && user.isUsBilling) {
    paths.push({ name: 'batches.new', title: 'New Batch', navPath: `${billing}.New.Batch`, showNav: true });
    paths.push({ name: 'batches', title: 'Batches', navPath: `${billing}.Batches.Claims`, showNav: true, isNavRoot: true });
    paths.push({ name: 'batches.incoming', title: 'Batches', navPath: `${billing}.Batches.Remittance`, showNav: true });
    paths.push({ name: 'batches.batch', title: 'Batch', navPath: `${billing}.Batches.Claims` });
    paths.push({ name: 'bills.bill.batches', title: bill, navPath: billsNavPath });
  }

  if (user.roles.isDirector) {
    paths.push({ name: 'billtemplates.new', title: 'New Template', navPath: `${billing}.New.Template`, showNav: true });
  }

  if (user.roles.isAdministrator) {
    paths.push({ name: 'billtemplates', title: `${bill} Templates`, navPath: `${billing}.Templates`, showNav: true });
    paths.push({ name: 'billtemplates.template', title: 'Template', navPath: `${billing}.Templates` });
    paths.push({ name: 'billitems', title: `${bill} Items`, navPath: `${billing}.${bill} Items`, showNav: true });
    if (user.isUsBilling) {
      paths.push({ name: 'insurers', title: 'Insurers', navPath: `${billing}.Insurers`, showNav: true });
    }
  }

  return paths;
};