const config = {
  ui_domain: 'app.kalixhealth.com',
  kalix_path: 'https://www.kalixhealth.com',
  kalix_auth_path: window.location.origin + '/authproxy', // Auth proxy is on current domain
  help_path: 'https://help.kalixhealth.com/',
  anon_path: 'https://us-api.kalixhealth.com',
  websockets_path: 'wss://ws.kalix.co',
  emailDomain: 'email.kalixhealth.com',
  templateOrgId: 490913,
  appInsights: {
    connectionString: 'InstrumentationKey=187cec50-2c0b-4f1c-9245-038025679cf6;IngestionEndpoint=https://southcentralus-0.in.applicationinsights.azure.com/'
  },
  facebook: {
    pixelId: '243697682483240'
  },
  google: {
    recaptcha: '6Lf-6p0UAAAAABgs7G8O1Oz2e__1x8remR1N0ij_',
    firebaseConfig: {
      apiKey: 'AIzaSyABwisj_6wvXSBoac-_3OjbT25b1Muu-vU',
      authDomain: 'jorkowski-com-api-project-397035845633.firebaseapp.com',
      projectId: 'jorkowski.com:api-project-397035845633',
      storageBucket: 'undefined',
      messagingSenderId: '397035845633',
      appId: '1:397035845633:web:9335cc8a4b9f175267e800',
      measurementId: 'G-TFC152RHVS'
    }
  },
  here: {
    apiKey: '_F0V9XfJ6uyJXZrHl-kJMSqYQIRJKg8BjFMmpwo9X70',
    zoom: 15
  },
  intercom: {
    key: 'a9a9b37fb75b7416962bc6e1c39a90aabce626bf'
  },
  square: {
    sandbox: false
  },
  rupa: {
    sandbox: false,
    baseUrl: 'https://labs.rupahealth.com'
  },
  telehealth: {
    appId: 'vpaas-magic-cookie-95ea1be30d17408eb8a70d14a49f7f33'
  }
};

module.exports = config;