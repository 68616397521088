import { observable, action, computed, makeObservable } from 'decorators';
import Bowser from 'bowser';
import themeService from 'theme';
import Visibility from 'visibilityjs/lib/visibility.core';
import { isWidthUp, isWidthDown } from '@material-ui/core/withWidth';
import { keys as breakpointKeys } from '@material-ui/core/styles/createBreakpoints';
import auth from './auth';

const browser = Bowser.getParser(window.navigator.userAgent);

let _canVisualizeAudio = !!(window.AudioContext || window.webkitAudioContext);
if (_canVisualizeAudio) {
  try { new MediaStream(); } catch { _canVisualizeAudio = false; } // eslint-disable-line
}

class Media {
  @observable _hasTouched = false;

  /**
   * Follows the MaterialUi sizes (xs, sm, md, lg, xl)
   */
  @observable current;
  @observable currentWidth;
  @observable currentHeight;

  /**
   * Whether the browser tab is currently visible or not
   */
  @observable isVisible = !Visibility.hidden();

  constructor() {
    makeObservable(this);
    this._calculateWidth();
    this.canVisualizeAudio = _canVisualizeAudio;
    this._canSetOutput = null;

    this._unbindId = Visibility.change(() => this._onVisibilityChanged());

    // We need to bind like this so we can unregister as well
    this._onTouchFirstTime = this._onTouchFirstTime.bind(this);
    this._calculateWidth = this._calculateWidth.bind(this);
    window.addEventListener('touchend', this._onTouchFirstTime, false);
    window.addEventListener('resize', this._calculateWidth, false);
  }

  @computed get iOS() {
    // https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
  }

  @computed get android() {
    return browser.getOSName(true) === 'android';
  }

  @computed get isTouchDevice() {
    return this.iOS || this.is('xs') || this._hasTouched;
  }

  @computed get topPadding() {
    const hasMenu = auth.hasMenuAccess && !auth.isLockedOut;
    return this.is('xs') ? (8 * 7) + (hasMenu ? 8 * 6 : 0) : 64;
  }

  get isDesktopSharingEnabled() {
    // Mobile devices currently return getDisplayMedia even though it is not implemented...
    return !!navigator.mediaDevices?.getDisplayMedia && browser.getPlatformType(true) === 'desktop' && !this.iOS;
  }

  get permissionsApiSupported() {
    return !!navigator.permissions;
  }

  get canSetAudioOutput() {
    if (this._canSetOutput != null) { return this._canSetOutput; }

    if (_canVisualizeAudio) {
      const audioDom = window.Audio && new window.Audio();
      this._canSetOutput = !!audioDom?.setSinkId;
    } else if (!_canVisualizeAudio) {
      this._canSetOutput = false;
    }
    return !!this._canSetOutput;
  }

  is(size) {
    return this.current === size;
  }

  up(size) {
    if (!size) { return true; }
    return isWidthUp(size, this.current, true);
  }

  down(size) {
    if (!size) { return true; }
    return isWidthDown(size, this.current, true);
  }

  getAudioContext() {
    if (_canVisualizeAudio && !this._audioContext) {
      if (this.iOS) {
        this._wakeUpAudioIOS();
      } else {
        this._audioContext = new (window.AudioContext || window.webkitAudioContext)();
      }
    }
    return this._audioContext;
  }

  dispose() {
    if (this._unbindId !== false) {
      Visibility.unbind(this._unbindId);
    }
    window.removeEventListener('touchend', this._onTouchFirstTime, false);
    window.removeEventListener('resize', this._calculateWidth, false);
  }

  @action _onVisibilityChanged() {
    this.isVisible = !Visibility.hidden();
  }

  @action _onTouchFirstTime() {
    this._hasTouched = true;
    if (this.iOS) { this._wakeUpAudioIOS(); }
    window.removeEventListener('touchend', this._onTouchFirstTime, false);
  }

  @action _calculateWidth() {
    const innerWidth = window.innerWidth;
    const breakpoints = themeService.defaultTheme.breakpoints;
    let width = null;

    /**
     * Start with the slowest value as low end devices often have a small screen.
     *
     * innerWidth |xs      sm      md      lg      xl
     *            |-------|-------|-------|-------|------>
     * width      |  xs   |  sm   |  md   |  lg   |  xl
     */
    let index = 1;
    while (width === null && index < breakpointKeys.length) {
      const currentWidth = breakpointKeys[index];

      // @media are inclusive, so reproduce the behavior here.
      if (innerWidth < breakpoints.values[currentWidth]) {
        width = breakpointKeys[index - 1];
        break;
      }

      index += 1;
    }

    width = width || 'xl';
    if (width !== this.current) {
      this.current = width;
    }

    this.currentWidth = innerWidth;
    this.currentHeight = window.innerHeight;
  }

  _wakeUpAudioIOS() {
    if (this._audioContext || !_canVisualizeAudio) { return; }
    // This is ONLY needed in iOS the bastards, needs to happen on a user interaction event like touchend
    // https://stackoverflow.com/questions/12517000/no-sound-on-ios-6-web-audio-api
    this._audioContext = new (window.AudioContext || window.webkitAudioContext)();

    // create a dummy sound - and play it immediately in same 'thread'
    // Note saw errors where createOscillator was null???
    if (this._audioContext?.createOscillator) {
      const oscillator = this._audioContext.createOscillator();
      oscillator.frequency.value = 400;
      oscillator.connect(this._audioContext.destination);
      oscillator.start(0);
      oscillator.stop(0);
    }
  }
}

export default new Media();