import { forwardRef, useCallback, useMemo } from 'react';
import { observer } from 'decorators';
import { Input, InputAdornment } from '@material-ui/core';

const extractFileAndPath = value => {
  return {
    filename: value.replace(/\.[^/.]+$/, ''),
    ext: '.' + value.split('.').pop()
  };
};

export default observer(forwardRef(function FilenameInput({ value, endAdornment, onChange, ...other }, ref) {
  const { filename, ext } = extractFileAndPath(value || '');
  const adornment = useMemo(() => ext ? <InputAdornment position="end">{ext}</InputAdornment> : endAdornment, [ ext, endAdornment ]);

  const handleChange = useCallback(e => {
    const newValue = e.target.value + ext;
    onChange && onChange(newValue);
  }, [ ext, onChange ]);

  return <Input ref={ref} value={filename} endAdornment={adornment} onChange={handleChange} {...other} />;
}));