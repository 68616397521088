import { observable, action, makeObservable } from 'decorators';
import Error from 'errors';

export class DialogClosedError extends Error {};

class Dialog {
  @observable open = false;
  @observable description = null;
  @observable isDescPre = false;
  @observable title = null;
  @observable primaryButton = null;
  @observable secondaryButton = null;
  @observable tertiaryButton = null;
  @observable hideSecondary = false;

  constructor() {
    makeObservable(this);
  }

  @action show({ description, title, primaryButton, secondaryButton, tertiaryButton, hideSecondary, descriptionPre }) {
    this.description = description;
    this.isDescPre = !!descriptionPre;
    this.title = title;
    this.primaryButton = primaryButton;
    this.secondaryButton = secondaryButton;
    this.tertiaryButton = tertiaryButton;
    this.hideSecondary = !!hideSecondary;
    this.open = true;

    return new Promise((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
  }

  @action success() {
    this.open = false;
    if (this._resolve) {
      this._resolve(true);
      this._resolve = null;
      this._reject = null;
    }
  }

  @action cancel() {
    this.open = false;
    if (this._reject) {
      this._reject(new DialogClosedError());
      this._resolve = null;
      this._reject = null;
    }
  }

  @action tertiary() {
    this.open = false;
    if (this._resolve) {
      this._resolve(false);
      this._resolve = null;
      this._reject = null;
    }
  }
}

export default new Dialog();