import auth from 'services/auth';

export default [
  { name: 'auth.register', path: '/register', view: () => import('./register'), external: true, anonymous: true },
  { name: 'auth.forgotpassword', path: '/forgotpassword', view: () => import('./forgotpassword'), external: true, anonymous: true },
  { name: 'auth.resetpassword', path: '/resetpassword', view: () => import('./resetpassword'), external: true, anonymous: true },
  { name: 'auth.logoutcomplete', path: '/logoutcomplete', view: () => import('./logoutcomplete'), external: true, anonymous: true },
  { name: 'auth.secure', path: '/secure/:orgId/:id', view: () => import('./secure'), external: true, anonymous: true },
  { name: 'auth.verify', path: '/verify', view: () => import('./verify'), external: true },
  { name: 'auth.verifyagain', path: '/verifyagain', view: () => import('./verifyagain'), external: true },
  { name: 'auth.logout', path: '/logout', func: () => auth.logOut() },
  { name: 'auth.oauth', path: '/oauth', view: () => import('./oauth'), external: true, anonymous: true }
];

export function nav(user) {
  return [
    { name: 'auth.register', navPath: 'Register', title: 'Register' },
    { name: 'auth.forgotpassword', navPath: 'Forgot Password', title: 'Forgot Password' },
    { name: 'auth.resetpassword', navPath: 'Reset Password', title: 'Reset Password' },
    { name: 'auth.secure', navPath: 'Secure Login', title: 'Secure Login' },
    { name: 'auth.verify', navPath: 'Verify', title: 'Verify', showExpired: true },
    { name: 'auth.verifyagain', navPath: 'Verify', title: 'Verify', showExpired: true },
    { name: 'auth.logout', navPath: 'Logout', showExpired: true },
    { name: 'auth.logoutcomplete', navPath: 'Logout', title: 'Logout Complete' },
    { name: 'auth.oauth', navPath: 'Connect Account', title: 'Connect Account' }
  ];
};