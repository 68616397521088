import config from 'config';

const here = config.here;

export function singleLineAddress(address) {
  if (!address) { return ''; }

  const parts = [];
  const street = combineStreetNames(address);
  if (street) { parts.push(street); }

  const suburbLine = getSuburbLine(address);
  if (suburbLine) {
    parts.push(suburbLine);
  }

  return parts.join(', ').trim();
}

export function multiLineAddress(address) {
  if (!address) { return []; }

  const lines = [];
  if (address.street1) { lines.push(address.street1.trim()); }
  if (address.street2) { lines.push(address.street2.trim()); }
  if (address.street3) { lines.push(address.street3.trim()); }

  const suburbLine = getSuburbLine(address);
  if (suburbLine) { lines.push(suburbLine); }

  return lines;
}

export function createStaticMapUrl(address, width, height, options) {
  if (isAddressEmpty(address) || !width || !address.latitude || !address.longitude) { return null; }
  if (width && !height) {
    // By default use hd width
    height = (width * 9 / 16) | 0;
  }

  // make sure to make it an integer
  width = Math.ceil(width);
  height = Math.ceil(height);
  let { zoom, type, features, geography } = options || {};
  zoom = zoom || here.zoom;
  type = type || 'png';
  features = features || 'pois:disabled';
  geography = geography || {
    type: 'FeatureCollection',
    features: [{
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [ address.longitude, address.latitude ]
      },
      properties: {
        size: 'large'
      }
    }]
  };

  const geoString = encodeURIComponent(JSON.stringify(geography));
  const resource = `overlay:zoom=${zoom}/${width}x${height}/${type}`;
  const url = `https://image.maps.hereapi.com/mia/v3/base/mc/${resource}?apiKey=${here.apiKey}&features=${features}&geojson=${geoString}`;
  return url;
}

export function isAddressEmpty(address) {
  return !(address && ((address.latitude && address.longitude) || (address.country && address.postcode && address.suburb)));
}

function combineStreetNames(address) {
  const addresses = [];
  if (address.street1) { addresses.push(address.street1.trim()); }
  if (address.street2) { addresses.push(address.street2.trim()); }
  if (address.street3) { addresses.push(address.street3.trim()); }

  if (addresses.length === 0) { return null; }
  return addresses.join(', ');
}

function getSuburbLine(address) {
  const secondaryParts = [];
  if (address.suburb) { secondaryParts.push(address.suburb.trim()); }
  if (address.state) { secondaryParts.push(address.state.trim()); }
  if (address.postcode) { secondaryParts.push(address.postcode.trim()); }
  return secondaryParts.join(' ');
}