import Bluebird from 'bluebird';

// Initialize bluebird as global promise lib and initialize
Bluebird.config({
  longStackTraces: true,
  cancellation: true,
  warnings: IS_DEBUG,
  monitoring: IS_DEBUG
});

// https://www.bennadel.com/blog/3289-implementing-qs-allsettled-promise-method-in-bluebird.htm
// We are making this to match native 'allSettled'
Bluebird.allSettled = function(promises) {
  // We have to "wrap" each one of these promises in a reflect() call that will allow
  // us to collection all of the results, regardless of whether or not the individual
  // promises are resolved or rejected.
  const reflectedPromsies = promises.map(
    function operator(pr) {
      return Bluebird
        .resolve(pr)
        .reflect() // Always "resolves" to state inspection.
        .then(
          function handleSettled(inspection) {
            if (inspection.isFulfilled()) {
              return ({
                status: 'fulfilled',
                value: inspection.value()
              });
            } else {
              return ({
                status: 'rejected',
                reason: inspection.reason()
              });
            }
          }
        );
    }
  );

  return Bluebird.all(reflectedPromsies);
};