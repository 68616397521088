import { observable, makeObservable } from 'decorators';

class Culture {
  @observable culture;

  constructor() {
    makeObservable(this);
  }
}

const culture = new Culture();
culture.culture = 'en-US';

export function rebindCulture(c) {
  culture.culture = c;
}

export function filterByText(arr, search, textFunc, c) {
  if (!search) { return arr; }
  const s = (search || '').toLocaleLowerCase(c || culture.culture);
  return arr.filter(a => {
    const val = textFunc ? textFunc(a) : a;
    return (val || '').toLocaleLowerCase(c || culture.culture).indexOf(s) >= 0;
  });
}

export function stringContains(str, search, c) {
  return (str || '').toLocaleLowerCase(c || culture.culture).indexOf((search || '').toLocaleLowerCase(c || culture.culture)) >= 0;
}

export function getFileExtension(filename) {
  const a = filename.split('.');
  if (a.length === 1 || (a[0] === '' && a.length === 2)) {
    return '';
  }
  return '.' + a.pop().toLowerCase();
}

export function getFileName(filename) {
  const ext = getFileExtension(filename);
  return filename.substr(0, filename.length - ext.length);
}