import axios from 'axios';
import config from 'config';
import offline from 'services/offline';

// Auth uses cookies so we need to enable credentials
const api = axios.create({
  baseURL: config.kalix_auth_path + '/',
  withCredentials: true,
  maxRedirects: 0,
  headers: {
    'X-Requested-With': 'XMLHttpRequest'
  }
});

api.interceptors.request.use(c => offline.whenUp().then(() => c));

api.get = Promise.method(api.get);
api.post = Promise.method(api.post);
api.put = Promise.method(api.put);
api.delete = Promise.method(api.delete);

export default api;