import { observable, reaction, makeObservable } from 'decorators';
import { toJS } from 'mobx';
import engine from 'store/src/store-engine';
import localStorage from 'store/storages/localStorage';
import sessionStorage from 'store/storages/sessionStorage';
import memoryStorage from 'store/storages/memoryStorage';
import expirePlugin from 'store/plugins/expire';

import auth from 'services/auth';

class Store {
  constructor() {
    this.localStore = engine.createStore([ localStorage, memoryStorage ], [ expirePlugin ]);
    this.sessionStore = engine.createStore([ sessionStorage, memoryStorage ], [ expirePlugin ]);

    this.localStore.removeExpiredKeys();
  }

  trackAndStoreLocal(key, defaultData, expireMs) {
    return new StoreTracker(this.localStore, key, defaultData, expireMs);
  }

  trackAndStoreSession(key, defaultData) {
    return new StoreTracker(this.sessionStore, key, defaultData);
  }
}

class StoreTracker {
  @observable data;

  constructor(store, key, defaultData, expireMs) {
    makeObservable(this);
    this._store = store;
    this._key = key;
    this._expireMs = expireMs;

    const initData = store.get(this._getFullKey());
    const initialData = Object.assign({}, toJS(defaultData), initData || {});
    this.data = initialData;

    this._syncDispose = reaction(() => toJS(this.data), () => this._sync());
  }

  dispose() {
    this._syncDispose();
    this._store.removeExpiredKeys();
  }

  clear() {
    this._store.remove(this._getFullKey());
  }

  _sync() {
    const data = toJS(this.data);
    const expiry = this._expireMs ? new Date().getTime() + this._expireMs : undefined;
    this._store.set(this._getFullKey(), data, expiry);
  }

  _getFullKey() {
    if (!auth.user) { return `kalix_data_anon_${this._key}`; }

    const { id, orgId } = auth.user;
    return `kalix_data_${id}_${orgId}_${this._key}`;
  }
}

export default new Store();