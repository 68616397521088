import config from 'config';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

export function startAppInsights(platformName) {
  const cs = config.appInsights.connectionString;
  if (!cs) { return; }

  const appInsights = new ApplicationInsights({
    config: {
      connectionString: cs
    }
  });
  appInsights.loadAppInsights();
  appInsights.addTelemetryInitializer(function(item) {
    // Make sure we always set the platform
    const baseData = item.baseData;
    const props = baseData.properties = baseData.properties || {};
    props.Platform = props.Platform || platformName;

    try {
      if (item.baseType === 'RemoteDependencyData') {
        // Filter out fast (<2000ms), successful request, to reduce logging
        if (baseData.success && baseData.duration < 2000) { return false; }
      } else if (item.baseType === 'ExceptionData') {
        const ex = baseData.exceptions[0];
        if (
          ex?.message?.includes('FLOW_CANCELLED')
          || ex?.message?.includes('KALIX_CLOSING')
          // We are getting this error message in telehealth from Jitsi
          || ex?.message?.includes('Failed to parse URL parameter value')
        ) {
          return false;
        }
      }
    } catch {}

    return true;
  });
  appInsights.trackPageView();

  window.appInsights = appInsights;
}