import userService from 'services/data/user';
import api from 'services/api';

export default [
  { name: 'messages', path: '/messages', view: () => import('./inbox'), hideFooter: true },
  { name: 'messages.settings', path: '/messages/settings', view: () => import('./settings') },
  { name: 'optinsuccess', path: '/optinsuccess', view: () => import('./optinsuccess'), external: true, anonymous: true },
  { name: 'messages.repugen', action: () => api.openFile('repugen/redirect') }
];

export function nav(user) {
  if (!user) { return []; }

  let paths = [
    { name: 'messages', title: 'Inbox', navPath: 'Messaging.Inbox', showNav: true, isNavRoot: true },
    { name: 'optinsuccess', navPath: 'Messaging', title: 'Opt-in Success' }
  ];

  if (user.roles.isDirector) {
    paths = paths.concat([
      { name: 'messages.settings', title: 'Settings', navPath: 'Messaging.Settings', showNav: true }
    ]);
  }

  if (userService.repugenAccess.value?.isActive) {
    paths.push({
      name: 'messages.repugen', navPath: 'Messaging.View Repugen', showNav: true
    });
  }

  return paths;
}