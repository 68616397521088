import { useEffect } from 'react';
import cc from 'classnames';
import { createStaticMapUrl } from 'utils/address';
import { observer } from 'decorators';
import { makeStyles, useResizeDetector } from 'hooks';
import Spinner from 'components/spinner';
import EmptyFiller from 'components/emptyFiller';
// import { installScript } from 'utils/dom';

// TODO: Uncomment everything when HERE maps JS lib supports CSP policy

import { Map as MapIcon } from 'mdi-material-ui';
// import InteractionIcon from 'mdi-material-ui/binoculars';

const hasAddressCheck = address => {
  return address && (address.latitude || address.latitude === 0) && (address.longitude || address.longitude === 0);
};

const useStyles = makeStyles(theme => ({
  container: {
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    width: '100%'
  },
  interact: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  img: {
    maxHeight: '100%',
    maxWidth: '100%',
    margin: '0 auto'
  }
}));

const noop = () => {};
export default observer(function MapComponent({ height, address, staticOptions, isLoading, fallbackImg, className, onRefresh }) {
  useEffect(onRefresh || noop); // eslint-disable-line

  const classes = useStyles();
  const hasAddress = hasAddressCheck(address);

  const { width, ref } = useResizeDetector({ handleHeight: false });
  if (!height && width) {
    height = Math.ceil(width * 9 / 16);
  }

  return <div ref={ref} className={cc(classes.container, className)} style={height == null ? undefined : { height }}>
    { isLoading && <Spinner text="Loading..." /> }
    { !isLoading && hasAddress &&
      <img src={createStaticMapUrl(address, width, height, staticOptions)} />
    }
    { !isLoading && !hasAddress && !fallbackImg && <EmptyFiller center icon={<MapIcon />} subheader="Address Not Found" /> }
    { !isLoading && !hasAddress && fallbackImg && <img className={classes.img} src={fallbackImg} /> }
  </div>;
});

/* const here = config.here;
let loadingMapsPromise = null;
function getMaps() {
  if (!loadingMapsPromise) {
    loadingMapsPromise = installScript('https://js.api.here.com/v3/3.0/mapsjs-core.js')
      .then(() => Promise.all([
        installScript('https://js.api.here.com/v3/3.0/mapsjs-service.js'),
        installScript('https://js.api.here.com/v3/3.0/mapsjs-ui.js'),
        installScript('https://js.api.here.com/v3/3.0/mapsjs-mapevents.js')
      ]))
      .then(() => {
        const H = window.H;
        const platform = new H.service.Platform({
          apiKey: here.apiKey,
          useHTTPS: true
        });
        const defaultLayers = platform.createDefaultLayers();

        return { H, platform, defaultLayers };
      })
      .catch((err) => {
        loadingMapsPromise = null;
        throw err;
      })
  }
  return loadingMapsPromise;
} */

/* const styles = theme => ({
  container: {
    overflow: 'hidden',
    position: 'relative',
    display: 'flex'
  },
  interact: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  img: {
    maxHeight: '100%',
    maxWidth: '100%',
    margin: '0 auto'
  }
});

export default @withStyles(styles) @observer
class MapComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { interactiveMode: false, loadingJs: false };
  }

  render() {
    return <ContainerDimensions>
      {({ width, height }) => this.renderInner(width, height) }
    </ContainerDimensions>;
  }
  */
/* componentDidUpdate(prevProps, prevState) {
    if (this.state.interactiveMode && !prevState.interactiveMode) {
      this.setState({ loadingJs: true });
      getMaps()
        .then(services => {
          this._mapServices = services;
          this.updateAddress();
          this.setState({ loadingJs: false });
        })
        .catch((err) => {
          this.setState({ loadingJs: false, interactiveMode: false });
          log.catchAndNotify(err);
        })
        .done();
    }

    if (this.state.interactiveMode) {
      this.updateAddress();
    }
  }

  componentWillUnmount() {
    this.disposeMap();
  }

  updateAddress() {
    if (!this._mapServices) { return; }
    const { address, zoom } = this.props;

    if (this.hasAddress()) {
      const loc = {
        lat: address.latitude,
        lng: address.longitude
      };

      if (this.map) {
        this.map.setCenter(loc);
        this.map.setZoom(zoom || here.zoom);
        this.marker.setPosition(loc);
      } else {
        const services = this._mapServices;
        this.map = new services.H.Map(this._mapDiv, services.defaultLayers.normal.map, {
          zoom: zoom || here.zoom,
          center: loc
        });

        this.marker = new services.H.map.Marker(loc);
        this.map.addObject(this.marker);

        this.mapUI = services.H.ui.UI.createDefault(this.map, services.defaultLayers);
        this.mapEvents = new services.H.mapevents.MapEvents(this.map);
        this.mapBehavior = new services.H.mapevents.Behavior(this.mapEvents);

        this.mapUI.getControl('mapsettings').setVisibility(false);
      }
    } else {
      this.disposeMap();
    }
  }

  disposeMap() {
    if (this.mapUI) { this.mapUI.dispose(); this.mapUI = null; }
    if (this.mapEvents) { this.mapEvents.dispose(); this.mapEvents = null; }
    if (this.mapBehavior) { this.mapBehavior.dispose(); this.mapBehavior = null; }
    if (this.marker) { this.marker.dispose(); this.marker = null; }
    if (this.map) { this.map.dispose(); this.map = null; }
  } */ /*

  renderInner(width, height) {
    const { interactiveMode, loadingJs } = this.state;
    const { fixedHeight, fluidHeight, address, staticOptions, isLoading, fallbackImg, classes, className } = this.props;

    const hasAddress = this.hasAddress();
    const allowGrow = fluidHeight && !hasAddress;

    // Make sure to round values
    if (fixedHeight || fluidHeight) {
      height = width * 9 / 16;
    }

    const isLoadingMap = loadingJs || isLoading;
    return <div className={cc(classes.container, className)} style={{ width: `${width}px`, height: allowGrow ? null : `${height}px` }} ref={r => (this._mapDiv = r)}>
      { isLoadingMap && <Spinner text="Loading..." /> }
      { !interactiveMode && !isLoadingMap && hasAddress &&
        <img src={createStaticMapUrl(address, Math.ceil(width), Math.ceil(height), staticOptions)} />
      }
      { !isLoadingMap && !hasAddress && !fallbackImg && <EmptyFiller center icon={<MapIcon />} subheader="Address Not Found" /> }
      { !isLoadingMap && !hasAddress && fallbackImg && <img className={classes.img} src={fallbackImg} /> }
      { *//* !interactiveMode && !isLoadingMap && hasAddress &&
        <IconButton className={classes.interact} onClick={() => this.setState({ interactiveMode: true })} aria-label="Interact">
          <InteractionIcon />
        </IconButton> *//*
      }
    </div>;
  }

  hasAddress() {
    const { address } = this.props;
    return address && (address.latitude || address.latitude === 0) && (address.longitude || address.longitude === 0);
  }
} */