import { observable, action, reaction, makeObservable } from 'decorators';

import 'offline-js';
import 'offline-js/themes/offline-theme-default.css';
import 'offline-js/themes/offline-language-english.css';

const Offline = window.Offline;

class OfflineService {
  @observable isOnline;

  constructor() {
    makeObservable(this);
    Offline.options = {
      interceptRequests: false,
      requests: false,
      checks: { xhr: { url: '/connection-test' } }
    };

    this.isOnline = Offline.state === 'up';

    Offline.on('up', action(() => {
      this.isOnline = true;
      this._isChecking = false;
    }));
    Offline.on('down', action(() => {
      this.isOnline = false;
      this._isChecking = true;
    }));

    this._isChecking = true;
    Offline.check();
  }

  forceCheck() {
    if (!this._isChecking) {
      this._isChecking = true;
      Offline.check();
    }
  }

  whenUp() {
    if (this.isOnline) {
      return Promise.resolve();
    } else {
      return new Promise(resolve => {
        const disposer = reaction(() => this.isOnline, () => {
          if (this.isOnline) {
            disposer();
            resolve();
          }
        }, { context: true });
      });
    }
  }
}

export default new OfflineService();