import router from 'services/router';
import auth from 'services/auth';

export default [
  { name: 'clinicians.me', path: '/clinicians/me', sameView: 'clinicians.clinician' },
  { name: 'clinicians.me.notifications', path: 'clinicians/me/notifications', sameView: 'clinicians.clinician' },
  { name: 'clinicians.me.integrations', path: 'clinicians/me/integrations', sameView: 'clinicians.clinician' },
  { name: 'organization', path: '/organization', view: () => import('./organization') },
  { name: 'organization.integrations', path: '/organization/integrations', sameView: 'organization' },
  { name: 'organization.new', path: '/organization/new', view: () => import('./new') },
  { name: 'clinicians', path: '/clinicians', view: () => import('./clinicians') },
  { name: 'clinicians.accessLogs', path: '/clinicians/accessLogs', sameView: 'clinicians' },
  { name: 'clinicians.clinician', path: '/clinicians/:id', view: () => import('./clinician') },
  { name: 'backups', path: '/backups', view: () => import('./backups') },
  { name: 'supportfiles', path: '/supportfiles', view: () => import('./supportfiles') },
  { name: 'subscription', path: '/subscription', view: () => import('./subscription') },
  { name: 'subscription.select', path: '/subscription/select', sameView: 'subscription' },
  { name: 'subscription.invoices', path: '/subscription/invoices', sameView: 'subscription' },
  { name: 'notifications', path: '/notifications', view: () => import('./notifications') },
  // Legacy routes
  { name: 'payments', path: '/payments', view: () => router.navigateTo('subscription') },
  { name: 'clinicians.invites', path: '/clinicians/invites', view: () => router.navigateTo('clinicians') },
  { name: 'clinicians.requests', path: '/clinicians/requests', view: () => router.navigateTo('clinicians') }
];

export function nav(user) {
  if (!user) { return []; }

  const name = user.orgName.replace(/\./g, '&#46;');

  let paths = [];

  if (user.roles.isAdministrator) {
    paths.push({ name: 'organization', title: 'Account Settings', navPath: `${name}.Account Settings.Settings`, showNav: true, isNavRoot: true });
  }

  paths.push({ name: 'clinicians.me', title: 'User Details', navPath: `${name}.My Details.My Profile`, showNav: true, isNavRoot: true });

  if (auth.rupahealthSupported || auth.fullscriptSupported) {
    paths.push({ name: 'clinicians.me.integrations', title: 'User Details', navPath: `${name}.My Details.Integrations`, showNav: true });
  }

  paths.push({ name: 'clinicians.me.notifications', title: 'User Details', navPath: `${name}.My Details.Notifications`, showNav: true });
  paths.push({ name: 'notifications', title: 'Notifications', showExpired: true });
  paths.push({ name: 'organization.new', title: 'New Account', navPath: `${name}.New` });

  paths.push({ name: 'clinicians', title: 'User Management', navPath: `${name}.User Management.Users`, showNav: true, showExpired: true, isNavRoot: true });
  paths.push({ name: 'clinicians.requests', showExpired: true });

  if (user.roles.isDirector) {
    paths = paths.concat([
      { name: 'organization.integrations', title: 'Account Settings', navPath: `${name}.Account Settings.Integrations`, showNav: true },
      { name: 'clinicians.invites', showExpired: true },
      { name: 'clinicians.clinician', title: 'User Details', navPath: `${name}.User Management.Users`, showExpired: true },
      { name: 'backups', title: 'Backup', navPath: `${name}.Backup`, showNav: true, showExpired: true },
      { name: 'supportfiles', title: 'Support Files', navPath: `${name}.Support Files`, showNav: true, showExpired: true },
      { name: 'clinicians.accessLogs', title: 'User Management', navPath: `${name}.User Management.Access Logs`, showExpired: true, showNav: true }
    ]);

    if (user.paymentsEnabled) {
      paths = paths.concat([
        { name: 'subscription', title: 'Subscription', navPath: `${name}.Subscription`, showNav: true, showExpired: true },
        { name: 'payments', showExpired: true },
        { name: 'subscription.select', title: 'Subscription', navPath: `${name}.Subscription`, showExpired: true },
        { name: 'subscription.invoices', title: 'Subscription', navPath: `${name}.Subscription`, showExpired: true }
      ]);
    }
  }

  return paths;
};