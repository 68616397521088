import { forwardRef, useState, useCallback, useId } from 'react';
import { observer } from 'decorators';
import cc from 'classnames';
import { makeStyles } from 'hooks';
import { Typography, Paper, Hidden, Accordion, AccordionSummary, AccordionDetails, Collapse } from '@material-ui/core';
import Grid from 'components/grid';
import media from 'services/media';

import ExpandIcon from 'mdi-material-ui/ChevronDown';

const useStyles = makeStyles(theme => ({
  pageContainer: {
    overflowX: 'hidden',
    width: '100%',
    padding: '20px 0',
    marginTop: '-18px'
  },
  section: {
    '&:not(:first-child)': {
      paddingTop: theme.spacing(5)
    }
  },
  paperItem: {
    position: 'relative',
    padding: theme.spacing(1.5, 2),
    minHeight: 65,
    display: 'flex',
    alignItems: 'center',
    '&:not(:first-child):before': {
      top: '-1px',
      left: 0,
      right: 0,
      height: '1px',
      content: "''",
      opacity: 1,
      position: 'absolute',
      transition: 'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      backgroundColor: 'rgba(0, 0, 0, 0.12)'
    }
  },
  paperInner: {
    width: '100%'
  },
  collapse: {
    paddingTop: theme.spacing(2)
  },
  errorItem: {
    border: `2px solid ${theme.palette.error.main}`
  },
  settingsBlock: {
    justifyContent: 'flex-end'
  },
  captionContainer: {
    position: 'relative',
    paddingTop: '0 !important',
    paddingBottom: '0 !important'
  },
  caption: {
    position: 'absolute',
    right: theme.spacing(1),
    top: 0
  }
}));

export const SettingsPage = observer(forwardRef(function SettingsPage({ className, ...props }, ref) {
  const classes = useStyles();
  return <div className={cc(classes.pageContainer, className)}><Grid ref={ref} container direction="column" spacing={5} {...props} /></div>;
}));

export const SettingsSection = observer(forwardRef(
  function SettingsSection({ title, subTitle, description, caption, fullHeight, disableTypography, children, className, noPad, collapseCaption, ...other }, ref) {
    const classes = useStyles();
    const sId = useId();

    const titleId = title ? 'settings-section-title-' + sId : undefined;
    if (disableTypography && typeof title === 'function') { title = title(titleId); }

    const subTitleId = subTitle ? 'settings-section-subtitle-' + sId : undefined;
    if (disableTypography && typeof subTitle === 'function') { subTitle = subTitle(subTitleId); }

    const labelledBy = (titleId && subTitleId) ? titleId + ' ' + subTitleId : ((titleId || '') + (subTitleId || '') || undefined);
    const descId = description ? 'settings-section-desc-' + sId : undefined;
    return <Grid
      ref={ref}
      item
      component="section"
      aria-labelledby={labelledBy}
      aria-describedby={descId}
      flexItem={fullHeight}
      className={cc(!noPad && classes.section, className)}
      {...other}
    >
      { title && (disableTypography ? title : <Typography id={titleId} variant="h6" paragraph={!subTitle}>{title}</Typography>) }
      { subTitle && (disableTypography ? subTitle : <Typography id={subTitleId} variant="subtitle1" paragraph>{subTitle}</Typography>) }
      <Grid container direction="column" spacing={2} flexGrow={fullHeight}>
        { description &&
          <Grid item id={descId}>
            { description }
          </Grid>
        }
        { !!children &&
          <Grid item flexItem={fullHeight} flexGrow={fullHeight}>
            { children }
          </Grid>
        }
        { caption &&
          <Grid item className={classes.captionContainer}>
            <div className={cc(collapseCaption && classes.caption)}>
              { disableTypography ?
                caption
                :
                <Typography variant="caption" align="right" display="block">
                  { caption }
                </Typography>
              }
            </div>
          </Grid>
        }
      </Grid>
    </Grid>;
  }
));

export const SettingsExpander = observer(forwardRef(
  function SettingsExpander({ title, secondary, children, fullWidth, paddedWidth, error, className, detailsClassName, readOnly, expanded, TransitionProps, disabled, onChange, ...other }, ref) {
    const classes = useStyles();
    const [ exp, setExp ] = useState(expanded || false);

    const handleChange = useCallback((e, expanded) => {
      setExp(expanded);
      onChange && onChange(expanded);
    }, [ onChange ]);

    const isExpanded = readOnly || disabled ? false : (expanded == null ? exp : expanded);
    return <Accordion
      ref={ref}
      className={cc(className, error && classes.errorItem)}
      expanded={isExpanded}
      TransitionProps={Object.assign({ unmountOnExit: true }, TransitionProps || {})}
      disabled={disabled}
      onChange={handleChange}
      {...other}
    >
      <AccordionSummary expandIcon={readOnly ? null : <ExpandIcon />}>
        <Grid container spacing={0} alignItems="center">
          <Grid item xs={12} sm={secondary ? 3 : 12} md={secondary ? 2 : 12}>
            {title}
          </Grid>
          { secondary &&
            <Grid item xs={12} sm style={{ paddingLeft: 8 }}>
              <Typography color="textSecondary" component="div">{ secondary }</Typography>
            </Grid>
          }
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={detailsClassName}>
        <Grid container spacing={2}>
          { !fullWidth && <Hidden only="xs"><Grid item sm={3} md={2} /></Hidden> }
          <Grid item xs={12} md={(fullWidth || paddedWidth) ? true : 4} sm={(fullWidth || paddedWidth) ? true : 9}>
            { children }
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>;
  }
));

export const SettingsItem = observer(forwardRef(
  function SettingsItem({ title, secondary, action, open, fullWidth, fullWidthAction, paddedWidth, error, children, alignItems, flexible, className, ...other }, ref) {
    const isSmall = media.is('xs');
    const classes = useStyles();

    const hasTopSection = !!(title || secondary || action);

    return <Paper ref={ref} {...other} square elevation={1} className={cc(classes.paperItem, error && classes.errorItem, className)}>
      <div className={classes.paperInner}>
        { hasTopSection &&
          <Grid container spacing={2} alignItems={alignItems || 'center'} className={classes.settingsBlock}>
            <Grid
              item zeroMinWidth
              style={{ order: 0, paddingRight: flexible ? '16px' : undefined }}
              xs={isSmall ? (flexible ? true : 12) : undefined}
              sm={flexible ? undefined : (secondary ? 3 : true)}
              md={flexible ? undefined : (secondary ? 2 : true)}
            >
              {title}
            </Grid>
            { secondary &&
              <Grid item xs={12} sm style={{ order: 2 }}>
                <Typography color="textSecondary" component="div">{ secondary }</Typography>
              </Grid>
            }
            { action &&
              <Grid item xs={fullWidthAction && isSmall ? 12 : undefined} sm={!!fullWidthAction} style={{ order: isSmall && flexible ? 1 : 3 }}>
                { action }
              </Grid>
            }
          </Grid>
        }
        { children &&
          <Collapse in={open}>
            <Grid container spacing={2}>
              { !fullWidth && <Hidden only="xs"><Grid item sm={3} md={2} /></Hidden> }
              <Grid item xs={12} md={(fullWidth || paddedWidth) ? true : 4} sm={(fullWidth || paddedWidth) ? true : 9}>
                <div className={cc(hasTopSection && classes.collapse)}>
                  { children }
                </div>
              </Grid>
            </Grid>
          </Collapse>
        }
      </div>
    </Paper>;
  }
));