import { memo, useRef, Fragment } from 'react';
import { createPortal } from 'react-dom';
import {
  useFloating,
  autoUpdate,
  offset,
  autoPlacement,
  shift
} from '@floating-ui/react';
import { useFocused, combineRefs, makeStyles } from 'hooks';

const useStyles = makeStyles(theme => ({
  container: {
    zIndex: theme.zIndex.modal
  }
}));

// Just using tether component directly...
export default memo(function Tether({ open, renderTarget, renderElement, openRefs, offset: off, placement, autoPlacement: ap }) {
  const classes = useStyles();
  const focusEnabled = open === undefined;
  const targetRef = useRef(null);
  const pickerRef = useRef(null);
  const elementRef = useRef(null);
  const [ focused ] = useFocused(focusEnabled, targetRef, pickerRef, elementRef, ...(openRefs || []));

  const isOpen = focusEnabled ? focused : open;

  const middleware = [];
  placement = placement || 'bottom-start';
  if (off) { middleware.push(offset(off)); }
  if (ap) {
    let { alignment, autoAlignment, allowedPlacements, ...other } = (typeof ap === 'object' ? ap : null) || {};
    if (alignment === undefined) { alignment = 'start'; }
    if (autoAlignment === undefined) { autoAlignment = false; }
    if (allowedPlacements === undefined) { allowedPlacements = [ 'top-start', 'bottom-start' ]; }
    middleware.push(autoPlacement({ alignment, autoAlignment, allowedPlacements, ...other }));
  }

  const { refs, floatingStyles } = useFloating({
    placement,
    open: isOpen,
    middleware: [ ...middleware, shift() ],
    whileElementsMounted: autoUpdate
  });

  return <Fragment>
    { renderTarget(combineRefs(refs.setReference, targetRef)) }
    { isOpen &&
      createPortal(
        <div ref={combineRefs(refs.setFloating, pickerRef)} className={classes.container} style={floatingStyles}>
          { renderElement(elementRef) }
        </div>,
        document.body
      )
    }
  </Fragment>;
});