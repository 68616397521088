import { forwardRef, useRef, useCallback, useEffect, useImperativeHandle } from 'react';
import { observer } from 'decorators';
import { useDisposable } from 'hooks';

import VirtualListStore from './virtualList.store';

export default observer(forwardRef(function VirtualList({ container, InnerComponent, estimatedItemHeight, items, itemHeight, itemBuffer, highlightIndex, ...props }, ref) {
  const innerRef = useRef(null);
  const store = useDisposable(() => new VirtualListStore());

  const getContainer = useCallback(() => container?.current || window, [ container ]);

  // sync state
  store.syncProps({ items, itemHeight, estimatedItemHeight, itemBuffer, highlightIndex });
  useEffect(() => store.setContainerNode(getContainer()), [ getContainer, store ]);
  useEffect(() => store.setInnerNode(innerRef.current), [ innerRef, store ]);

  // sync elements
  useEffect(() => {
    const c = getContainer();
    if (!c) { return; }

    const onScroll = () => store.onScroll();
    c.addEventListener('scroll', onScroll, { passive: true });

    return () => {
      c.removeEventListener('scroll', onScroll, { passive: true });
    };
  }, [ getContainer, store ]);

  // Scroll to the highlighted index, if we have one
  useEffect(() => {
    const c = getContainer();
    if (c && store.highlightTop != null) {
      c.scrollTop = store.highlightTop;
    }
  }, [ getContainer, store.highlightTop ]);

  // Height setting method
  const setHeight = useCallback((index, height) => store.setHeight(index, height), [ store ]);

  useImperativeHandle(ref, () => ({
    scrollToIndex: index => store.scrollToIndex(index)
  }));

  return <InnerComponent ref={innerRef} {...props} virtual={store.styles} setHeight={setHeight} highlightIndex={highlightIndex} />;
}));