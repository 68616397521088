export default {
  'address.zip': 'zip',
  'address.city': 'city',
  'bill.bill': 'bill',
  'bill.billing': 'billing',
  'bill.bills': 'bills',
  'bill.check': 'check',
  'bill.npi': 'NPI',
  'bill.locnpi': 'Location NPI',
  'bill.units': 'units',
  cellphone: 'cell phone',
  color: 'color',
  'contact.qualifications': 'credentials',
  'client.healthFund': 'private health fund',
  'client.ssn': 'SSN',
  'org.bsb': 'routing number',
  'org.org': 'organization',
  'org.npi': 'billing provider NPI',
  'org.taxId': 'tax ID'
};