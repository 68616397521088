import { observable, action, computed, makeObservable } from 'decorators';
import log from 'services/log';
import auth from 'services/auth';
import Form, { isRequired, isEmail, checkIf } from 'components/form';
import { installScript } from 'utils/dom';

import fonts from 'theme/fonts.scss';

let loadingPromise;
let cardInstanceId = 0;

export function getStripe() {
  if (!loadingPromise) {
    loadingPromise = installScript('https://js.stripe.com/v3/')
      .catch((err) => {
        loadingPromise = null;
        throw err;
      });
  }
  return loadingPromise;
}

export default class StripePaymentStore {
  @observable isReady = false;
  @observable isVisible = false;
  @observable isClosing = false;

  @observable form = null;
  @observable.shallow validationErrors = [];
  @observable additionalError = null;

  @observable card = null;
  @observable isCardOnly = false;
  @observable disableCard = false;
  @observable cardRequired = false;
  @observable amountDue = null;
  @observable _cardError = null;

  @observable requiresEmail;

  constructor({ key, userId, requiresEmail, onClose, onExecute }) {
    makeObservable(this);
    this._key = key;
    this._account = userId;
    this._onClose = onClose;
    this._onExecute = onExecute;
    this.requiresEmail = requiresEmail;
    this.cardId = 'st-card-number-' + (cardInstanceId++);

    // pre-init so the payment is faster when they click the button...
    this._configure()
      .catch(err => log.error('Problem loading square', err))
      .done();
  }

  @computed get cardOnFileMessage() {
    if (this.disableCard || !this.form || !this.form.$('saveCard').value) { return null; }

    return auth.user.roles.isClinician ?
      'Only select this if your client has explicitly authorized the saving and use of their credit card for future transactions.'
      :
      `Only select this if you authorize the saving and use of your credit card for future transactions by ${auth.user.orgPublicName}.`;
  }

  @action start({ amountDue, disableCard, cardRequired } = {}) {
    this.validationErrors = [];
    this.additionalError = null;
    this.disableCard = !!disableCard;
    this.cardRequired = !disableCard && !!cardRequired;
    if (this.cardRequired && typeof cardRequired === 'string') {
      this._cardRequiredErrorMessage = cardRequired;
    }
    this.isCardOnly = !amountDue;
    this.amountDue = amountDue;
    this.isVisible = true;
    return this._configure();
  }

  @action close(success) {
    this.isClosing = true;
    return Promise.resolve(this._onClose && this._onClose(success))
      .then(() => this.unmount())
      .catch(err => {
        this.isClosing = false;
        throw err;
      });
  }

  @action unmount() {
    this.isVisible = false;
    this._cardError = 'Enter a value';
    if (this.card) {
      this.card.clear();
      this.card.unmount();
    }
  }

  @action payNow(e, onExecute) {
    return this.form.submitPromise(e)
      .then(() => {
        // Different places do the execution differently
        const formData = this.form.values();
        if (this.disableCard) { formData.saveCard = false; }
        if (!this.isCardOnly && !formData.saveCard) { formData.label = null; }
        const methods = this._buildExecuteObject();
        return (onExecute || this._onExecute)(methods, formData)
          .then(() => this.close(true))
          .catch(err => this._handleCardError(err));
      });
  }

  dispose() {
    this.isVisible = false;
    if (this.card) {
      this.card.destroy();
      this.card = null;
    }
    this.form = null;
  }

  _configure() {
    return getStripe().then(() => this._setup());
  }

  @action _setup() {
    if (this.form) { return; }

    this.form = new Form(this._createFormFields());
    this._stripe = window.Stripe(this._key, { stripeAccount: this._account, locale: 'en' });

    const elements = this._stripe.elements({ fonts: [{ cssSrc: fonts }] });
    const styles = {
      base: {
        fontFamily: '"Open Sans", sans-serif',
        fontSize: '16px',
        lineHeight: '19px',
        color: 'rgba(0, 0, 0, 0.87)'
      }
    };
    this.card = elements.create('card', { style: styles });
    this.card.addEventListener('ready', action(() => (this.isReady = true)));
    this.card.addEventListener('change', action(({ empty, error, complete }) => {
      this._cardError = empty ? 'Enter a value' : (complete ? error?.message : 'Information is incomplete');
      this.form.$('number').validate({ showErrors: true }).done();
    }));
    this._cardError = 'Enter a value'; // It's empty initially!

    return null;
  }

  _createFormFields() {
    const fields = [ 'name', 'label', 'number', 'email', 'saveCard' ];

    const labels = {
      name: 'Cardholder Name',
      label: 'Card Label (Optional)',
      number: 'Credit Card',
      email: 'Email'
    };

    const types = {
      email: 'email',
      saveCard: 'switch'
    };

    const values = {
      saveCard: false
    };

    const validators = {
      name: [ isRequired ],
      number: [ () => [ !this._cardError, this._cardError ] ],
      email: [ checkIf(() => this.requiresEmail, [ isRequired, isEmail ]) ],
      saveCard: [ checkIf(() => this.cardRequired, [ ({ field }) => [ !!field.value, this._cardRequiredErrorMessage || 'This card is required to be saved on file' ] ]) ]
    };

    const extra = {
      number: {
        id: this.cardId,
        inputComponent: 'div',
        shrink: true
      },
      saveCard: {
        text: 'Save card to file'
      }
    };

    return { fields, labels, types, values, validators, extra };
  }

  _buildExecuteObject() {
    return {
      handleCardSetup: ({ id, token }) => {
        return this._stripe.handleCardSetup(token, this.card, {
          payment_method_data: {
            billing_details: { name: this.form.$('name').value }
          }
        })
          .then(result => {
            if (result.error) {
              throw new Error(result.error.message);
            } else {
              return id;
            }
          });
      },
      handleCardPayment: ({ id, token }) => {
        return this._stripe.handleCardPayment(token, this.card, {
          payment_method_data: {
            billing_details: { name: this.form.$('name').value }
          }
        })
          .then(result => {
            if (result.error) {
              throw new Error(result.error.message);
            } else {
              // Add a 2 second wait to let handler do its thing on backend
              return new Promise(resolve => {
                setTimeout(() => resolve(id), 2000);
              });
            }
          });
      }
    };
  }

  @action _handleCardError(err) {
    let msg = err.response?.data?.error?.message || err.message || 'An unknown error occurred';
    const code = err.response?.data?.error?.code;

    if (err.message === 'Network Error' || err.name === 'TimeoutError') {
      msg = 'An unknown error occurred, trying again without changing any fields will prevent a double charge';
    }

    if (code === 'PaymentEmailRequired') {
      this.requiresEmail = true;
    }

    err = new Error(msg);
    this.additionalError = msg;
    throw err;
  }
}