import { createTheme as createMuiTheme } from '@material-ui/core/styles';
import { darken, lighten } from '@material-ui/core/styles/colorManipulator';
import green from '@material-ui/core/colors/green';
import { observable, computed, makeObservable, flow } from 'decorators';
import auth from 'services/auth';
import config from 'config';
import baseApi from 'utils/baseApi';

class ThemeService {
  @observable loadingBrand = true;
  @observable _domainBrand = null;

  constructor() {
    makeObservable(this);
    this._defaultTheme = createTheme({});
    this._defaultTheme.isBranded = false;
    this._refreshDomainBrand().done();
  }

  @computed get defaultTheme() {
    return this._mainTheme || this._defaultTheme;
  }

  @computed get isBranded() {
    return this.defaultTheme.isBranded;
  }

  @computed get brandName() {
    return this.brand.name;
  }

  @computed get brand() {
    // We fallback on each setting
    const userBrand = auth.user?.brand;
    const domainBrand = auth.user ? null : this._domainBrand;
    const domain = userBrand?.domain || domainBrand?.domain || config.ui_domain;
    const uiPath = (domain.includes('localhost:') ? 'http://' : 'https://') + domain;
    return {
      brandId: userBrand?.brandId || domainBrand?.brandId || 0,
      domain,
      uiPath,
      frontPageUrl: userBrand?.frontPageUrl || domainBrand?.frontPageUrl || config.kalix_path,
      logoUrl: userBrand?.logoUrl || domainBrand?.logoUrl || '/assets/images/kalix_logo.png',
      supportEmail: userBrand?.supportEmail || domainBrand?.supportEmail || 'support@kalixhealth.com',
      emailDomain: userBrand?.emailDomain || domainBrand?.emailDomain || config.emailDomain,
      name: userBrand?.name || domainBrand?.name || 'Kalix',
      docLibraryOrgId: userBrand?.docLibraryOrgId || domainBrand?.docLibraryOrgId || config.templateOrgId,
      hideHelp: (userBrand?.hideHelp == null ? domainBrand?.hideHelp : userBrand.hideHelp) || false
    };
  }

  @computed get isTemplateOrg() {
    return !!(auth.user && auth.user.orgId === this.brand.docLibraryOrgId);
  }

  @computed get _mainTheme() {
    const brand = auth.user ? auth.user.brand : this._domainBrand;
    if (!brand) { return null; }
    const theme = createTheme({ primary: brand.colorPrimary, secondary: brand.colorSecondary });
    theme.isBranded = true;
    return theme;
  }

  @computed get defaultMainColor() { return fixColor(this.defaultTheme.palette.primary.main); }
  @computed get defaultSecondaryColor() { return fixColor(this.defaultTheme.palette.secondary.main); }
  @computed get defaultBackgroundColor() { return fixColor(this.defaultTheme.palette.background.default); }
  @computed get defaultPaperColor() { return fixColor(this.defaultTheme.palette.background.paper); }

  createTheme({ primary, secondary, background, paper } = {}) {
    const mainPalette = this._mainTheme?.palette;
    const theme = createTheme({
      primary: primary || fixColor(mainPalette?.primary.main),
      secondary: secondary || fixColor(mainPalette?.secondary.main),
      background: background || fixColor(mainPalette?.background.paper),
      paper: paper || fixColor(mainPalette?.common.white)
    });
    theme.isBranded = !!mainPalette;
    return theme;
  }

  @flow *_refreshDomainBrand() {
    const host = window.location.host;
    if (host !== config.ui_domain) {
      const res = yield baseApi.get('brand');
      this._domainBrand = res.data;
    }
    this.loadingBrand = false;
  }
}

function fixColor(col) {
  if (col && col.length === 4) { return `#${col[1]}${col[1]}${col[2]}${col[2]}${col[3]}${col[3]}`; }
  return col;
}

/*********************************
 Default Theme work
**********************************/
// Purply color
const primaryCol = {
  50: '#ede8ee',
  100: '#d2c5d4',
  200: '#b59eb8',
  300: '#875a82', // '#97779b',
  400: '#805a85',
  500: '#6a3d70',
  600: '#623768',
  700: '#4e245a', // '#572f5d',
  800: '#4d2753',
  900: '#3c1a41',
  A100: '#ed82ff',
  A200: '#e64fff',
  A400: '#df1cff',
  A700: '#db03ff',
  contrastDefaultColor: 'light'
};

// Orangy color
const secondaryCol = {
  50: '#fef0e4',
  100: '#fcd9bc',
  200: '#fac090',
  300: '#f8a663',
  400: '#f79341',
  500: '#f7941e', // '#f58020',
  600: '#f4781c',
  700: '#f26d18',
  800: '#f06313',
  900: '#ee500b',
  A100: '#ffffff',
  A200: '#ffebe5',
  A400: '#ffc6b2',
  A700: '#ffb398',
  contrastDefaultColor: 'dark'
};

const defaultBackgroundColor = '#fafafa';
const defaultPaperColor = '#fff';

function generateColor(col) {
  if (!col) { return null; }

  const tonalOffset = 0.2; // Default from material-ui
  return {
    50: lighten(col, tonalOffset * 2.5),
    100: lighten(col, tonalOffset * 2),
    light: lighten(col, tonalOffset),
    main: col,
    dark: darken(col, tonalOffset * 1.5)
  };
}

function generateColorFromDefault(palette) {
  return Object.assign({ light: palette[300], main: palette[500], dark: palette[700] }, palette);
}

function createTheme({ primary, secondary, background, paper } = {}) {
  primary = generateColor(primary) || generateColorFromDefault(primaryCol);
  secondary = generateColor(secondary) || generateColorFromDefault(secondaryCol);

  return createMuiTheme({
    palette: {
      primary,
      secondary,
      success: generateColorFromDefault(green),
      background: {
        paper: paper || defaultPaperColor,
        default: background || defaultBackgroundColor
      }
    },
    typography: {
      fontFamily: "'Open Sans', sans-serif",
      fontSize: 14,
      fontWeightMedium: 600,
      subtitle1: {
        fontFamily: "'LatoLatinWebMedium', sans-serif"
      },
      h6: {
        fontFamily: "'LatoLatinWebMedium', sans-serif",
        fontWeight: 400
      },
      h5: {
        fontFamily: "'LatoLatinWebMedium', sans-serif"
      },
      h4: {
        fontFamily: "'LatoLatinWebMedium', sans-serif"
      },
      h3: {
        fontFamily: "'LatoLatinWebMedium', sans-serif"
      },
      h2: {
        fontFamily: "'LatoLatinWebMedium', sans-serif"
      },
      h1: {
        fontFamily: "'LatoLatinWebMedium', sans-serif"
      },
      button: {
        fontWeight: 600
      },
      body1: {
        fontSize: '1rem',
        fontWeight: 400
      },
      caption: {
        color: 'rgba(0, 0, 0, 0.54)'
      },
      body2: {
        fontSize: '1rem',
        fontWeight: 600
      }
    },
    overrides: {
      MuiButton: {
        text: {
          padding: '8px 16px'
        }
      },
      MuiButtonBase: {
        root: {
          '@media print': {
            display: 'none'
          }
        }
      },
      MuiAccordionSummary: {
        root: {
          padding: '0 16px'
        }
      },
      MuiAccordionDetails: {
        root: {
          padding: '8px 16px 16px'
        }
      },
      MuiFormLabel: {
        root: {
          marginTop: '24px',
          marginBottom: '4px'
        }
      },
      MuiGrid: {
        'direction-xs-column': {
          flexWrap: 'nowrap'
        }
      },
      MuiInputAdornment: {
        root: {
          maxHeight: '24px'
        }
      },
      MuiInputBase: {
        input: {
          appearance: 'textfield'
        },
        inputMarginDense: {
          paddingTop: '5px',
          paddingBottom: '5px'
        },
        inputMultiline: {
          paddingBottom: '1px'
        },
        multiline: {
          lineHeight: 1.5
        }
      },
      MuiInputLabel: {
        root: {
          margin: 0
        },
        shrink: {
          width: '130%' // It's using a transform scale of .75, so this adds the width back on...
        }
      },
      MuiListItem: {
        root: {
          '@media print': {
            paddingTop: '8px !important',
            paddingBottom: '8px !important',
            pageBreakInside: 'avoid'
          }
        },
        button: {
          '&:hover': {
            backgroundColor: `${primary[50]} !important`
          }
        }
      },
      MuiListItemText: {
        primary: {
          fontFamily: 'LatoLatinWebMedium, sans-serif',
          fontWeight: 400
        },
        secondary: {
          // Same as caption
          fontSize: '0.75rem',
          fontFamily: "'Open Sans', sans-serif",
          fontWeight: 400,
          lineHeight: 1.66,
          // Nowrap
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        }
      },
      MuiListSubheader: {
        root: {
          fontFamily: 'LatoLatinWebMedium, sans-serif',
          fontWeight: 400
        }
      },
      MuiMenuItem: {
        root: {
          '&$selected': {
            backgroundColor: `${primary[100]} !important`
          }
        }
      },
      MuiPaper: {
        root: {
          '@media print': {
            boxShadow: 'none !important'
          }
        }
      },
      MuiSelect: {
        icon: {
          position: 'static',
          marginLeft: '-1em'
        }
      },
      MuiSnackbarContent: {
        root: {
          flexWrap: 'nowrap',
          backgroundColor: primary[700]
        }
      },
      MuiSvgIcon: {
        root: {
          verticalAlign: 'middle'
        }
      },
      MuiSwitch: {
        switchBase: {
          height: 'auto'
        }
      },
      MuiTooltip: {
        tooltip: {
          display: 'inline-flex',
          verticalAlign: 'middle'
        }
      }
    }
  });
};

export default new ThemeService();