import Error from 'errors';

export class CancelPageChangeError extends Error {};

class GlobalEvents {
  constructor() {
    this._onUnloadHandlers = [];
    this._onPageChangeHandlers = [];
    this._windowOnLoadHandler = null;

    this._eventHandlers = new Set();
  }

  startEvents(api) {
    this._events = api.websocket({
      url: 'events',
      onMessage: this._onMessage.bind(this)
    });
  }

  pauseEvents(pause) {
    this._pauseEvents = !!pause;
  }

  on(filter, func, _this) {
    const handler = {
      filter,
      handler: _this ? func.bind(_this) : func
    };

    this._eventHandlers.add(handler);

    return this._removeEvent.bind(this, handler);
  }

  emitLocal(filter, data) {
    if (this._pauseEvents) { return; }

    let handled = false;
    this._eventHandlers.forEach(h => {
      if (h.filter === filter) {
        h.handler(data);
        handled = true;
      }
    });
    return handled;
  }

  // Page change is more internal, so we can use promises
  onPageChange(handler) {
    if (!handler) { return; }

    // Push to top of stack
    this._onPageChangeHandlers.unshift(handler);
    return () => {
      const index = this._onPageChangeHandlers.indexOf(handler);
      if (index > -1) {
        this._onPageChangeHandlers.splice(index, 1);
      }
    };
  }

  clearPageChange() {
    this._onPageChangeHandlers = [];
  }

  runPageHanders() {
    return Promise.all(this._onPageChangeHandlers.map(h => h()));
  }

  // Windows onunload func
  onUnload(handler) {
    if (!handler) { return; }

    // Push to top of stack
    this._onUnloadHandlers.unshift(handler);
    if (!this._windowOnLoadHandler) {
      this._windowOnLoadHandler = this._handleOnUnload.bind(this);
      window.addEventListener('beforeunload', this._windowOnLoadHandler);
    }

    return this._removeUnload.bind(this, handler);
  }

  _onMessage(e) {
    if (e.type !== 'message') { return; }

    const data = JSON.parse(e.data);
    const handled = this.emitLocal(data.type, data.data);

    if (IS_DEBUG) {
      console.log(`Event received: [${handled ? 'HANDLED' : 'NOT HANDLED'}] ${data.type}`, data.data);
    }
  }

  _removeEvent(handler) {
    this._eventHandlers.delete(handler);
  }

  _removeUnload(handler) {
    const index = this._onUnloadHandlers.indexOf(handler);
    if (index > -1) {
      this._onUnloadHandlers.splice(index, 1);
      if (this._onUnloadHandlers.length === 0 && this._windowOnLoadHandler) {
        window.removeEventListener('beforeunload', this._windowOnLoadHandler);
        this._windowOnLoadHandler = null;
      }
    }
  }

  _handleOnUnload(e) {
    let result = null;
    this._onUnloadHandlers.forEach(h => {
      // If we get back a result don't hit the other handlers
      result = result || h();
    });
    if (result) {
      if (e) { e.returnValue = result; }
      return result;
    }
  }
}

export default new GlobalEvents();