import moment from 'moment';
import globalEvents from './globalEvents';
import { observable, computed, action, reaction, makeObservable } from 'decorators';
import { loadLang, enumsValuesPromise } from 'lang/index';
import Error from 'errors';
import { rebindDateFormats } from 'utils/date';
import { rebindCulture } from 'utils/string';
import baseApi from 'utils/baseApi';
import { splitWindowQuery, toQueryString } from 'utils/query';
import dialog from 'services/dialog';
import langEn from 'lang/en';
import langEnUs from 'lang/en-US';
import config from 'config';
import log from 'services/log';

const AUTH_POLL_MIN_SECONDS = 900; // Only allow this amount of time between auth polls

const defaultLang = Object.assign({}, langEn, langEnUs);

// We try to get an access_token before anything else happens
const parts = splitWindowQuery();
let _initSession = parts.hash.init_session;
const _sessionToken = parts.hash.session_token || parts.query.session_token;
const _accessToken = parts.hash.access_token || parts.query.access_token;
const _calendarToken = parts.hash.calendar_token || parts.query.calendar_token;
const _hasFixedToken = !!(_sessionToken || _accessToken || _calendarToken);
const _hasRetryToken = !_sessionToken && !_accessToken;
let expectedOrgId = parts.hash.expectedOrgId || parts.query.expectedOrgId;

// Remove auth related parts of the url and replace history
if (_initSession || expectedOrgId) {
  const h = parts.hash;
  const q = parts.query;

  delete h.expectedOrgId;
  delete q.expectedOrgId;
  delete h.init_session;

  const hashPart = toQueryString(h);
  const queryPart = toQueryString(q);
  history.replaceState(history.state, '', `${window.location.pathname}${queryPart ? '?' + queryPart : ''}${hashPart ? '#' + hashPart : ''}`);
}

export class LoginError extends Error {};
export class UserChangedError extends Error {};

let requestCounter = 0;

class AuthService {
  @observable.ref user = null; // Note: we will avoid setting this back to null so that we don't destroy current state
  @observable enumValues = null;
  @observable needsTerms = null;
  @observable needs2FA = null;
  @observable needsDetails = null;
  @observable globalBlockingError;
  hasFixedToken = _hasFixedToken;
  hasRetryToken = _hasRetryToken;
  calendarToken = _calendarToken;
  accessToken = _accessToken;
  sessionToken = _sessionToken;

  @observable _attempting = true;
  @observable _waitingForLogin = false;
  @observable _quiet = false;

  constructor() {
    makeObservable(this);
    this._retry(false).catch(e => {}).done();
    this.orgRefreshDispose = globalEvents.on('organisations.refresh', () => this.retry(true).done());
    enumsValuesPromise().then(action(val => (this.enumValues = val)));
  }

  @computed get attempting() {
    return this._attempting && !this._quiet && !this._waitingForLogin;
  }

  @computed get isAuthed() {
    return this.user && !this.attempting && !this._waitingForLogin;
  }

  @computed get isClinician() {
    return this.isAuthed && this.user.roles.isClinician;
  }

  @computed get hasMenuAccess() {
    return this.isAuthed && this.user.roles.role !== 'Document' && this.user.roles.role !== 'MultiDoc' && this.user.roles.role !== 'Bill';
  }

  @computed get isLockedOut() {
    return this.isClinician && (this.user.isExpired || this.user.needsMinSubscriptions != null);
  }

  @computed get enums() {
    return this.user ? this.user.meta.enums : null;
  }

  @computed get lang() {
    return this.user ? this.user.lang : defaultLang;
  }

  @computed get userTimezone() {
    if (!this.user) { return null; }
    return this.user.userTimezone || this.user.timezone;
  }

  @computed get isUsBilling() {
    return this.user ? this.user.isUsBilling : false;
  }

  @computed get isAuBilling() {
    return this.user ? this.user.isAuBilling : false;
  }

  @computed get fullscriptSupported() {
    const c = this.user?.country;
    return c === 'US' || c === 'PR' || c === 'CA';
  }

  @computed get rupahealthSupported() {
    return this.isUsBilling;
  }

  @computed get isUsCulture() {
    return this.user ? this.user.isUsCulture : false;
  }

  @computed get sessionId() {
    return (this.user || this.needsTerms || this.needs2FA)?.auth.sessionId;
  }

  // use this like auth.hasFeature(f => f.pro)
  hasFeature(lookup) {
    return (this.user && this.enumValues) ? this.user.roles.features[lookup(this.enumValues.features)] : false;
  }

  isCountry(...countries) {
    return this.user ? !!countries.find(c => this.user.country === c) : false;
  }

  retry(force, throwError) {
    if (this._attempting) {
      // Resolve this when the retry has completed
      return new Promise((resolve) => {
        const observeDispose = reaction(() => this._attempting, () => {
          if (this._attempting) { return; }
          observeDispose();
          resolve();
        });
      });
    }
    if (!force && this.isAuthed) { return Promise.resolve(); }
    return this._retry(true).catch(e => {
      e.customFallbackMessage = 'Could not reload user details, please refresh the page';
      if (throwError) { throw e; } else { log.catchAndNotify(e); }
    });
  }

  resetUser() {
    if (this.attempting) { return Promise.resolve(); }
    return this._retry(false).catch(e => {
      e.customFallbackMessage = 'Could not reset user details, please refresh the page';
      log.catchAndNotify(e);
    });
  }

  login(email, password) {
    const formData = new FormData();
    formData.append('Email', email);
    formData.append('Password', password);
    return baseApi.post('auth/login', formData, { responseType: 'text' })
      .then(action(r => {
        if (r && r.data && r.data.includes && r.data.includes('Username or password is invalid')) {
          const e = new LoginError('Username or password is invalid');
          throw e;
        } else {
          this._retry(false).done();
          return null;
        }
      }))
      .catch(err => {
        if (err?.response?.status === 429) {
          throw new LoginError(err.response.data);
        } else {
          log.error('Login Error', err);
          throw err;
        }
      });
  }

  @action resetManual2FA() {
    this.needs2FA = { user: this.user, needs2FA: false, has2FA: false, auth: this.user.auth, otherOrgs: this.user.ortherOrgs };
    this.user = null;
    return null;
  }

  @action cancel2FA() {
    this.user = this.needs2FA.user;
    this.needs2FA = null;
  }

  getSessionToken() {
    const auth = this.user?.auth;
    if (auth) { return Promise.resolve(auth); }

    return this.retry(true, true).then(() => this.getSessionToken());
  }

  getCSRFConfig() {
    return { headers: { 'X-CSRF-TOKEN': this.sessionId } };
  }

  @action switchOrg(id, quiet) {
    this._quiet = false;
    this._waitingForLogin = false;
    this._userRetryId = requestCounter++;
    this._attempting = true;

    let needsRetry = false;
    return this._switchRequest(id, this.sessionId)
      .catch(err => {
        if (err && err.response && err.response.status === 401) {
          needsRetry = true;
        } else {
          log.catchAndNotify(err);
        }
      })
      .then(r => this._retry(quiet))
      .then(() => {
        if (needsRetry) { return this.switchOrg(id); }
      });
  }

  @action logOut() {
    this._attempting = true;
    this._quiet = false;
    this._waitingForLogin = false;
    this._userRetryId = requestCounter++;

    setTimeout(() => {
      if (this.hasFixedToken) {
        // Remove auth related parts of the url and replace history
        if (_hasFixedToken) {
          const parts = splitWindowQuery();
          const h = parts.hash;
          const q = parts.query;

          if (h.access_token || q.access_token || h.session_token || q.session_token) {
            delete h.access_token;
            delete q.access_token;
            delete h.session_token;
            delete q.session_token;

            const hashPart = toQueryString(h);
            const queryPart = toQueryString(q);
            history.replaceState(history.state, '', `${window.location.pathname}${queryPart ? '?' + queryPart : ''}${hashPart ? '#' + hashPart : ''}`);
          }
        }

        window.location.href = '/logoutcomplete';
      } else {
        window.location.href = config.kalix_auth_path + '/logout';
      }
    }, 0);
  }

  @action setNotValidLink(title, desc) {
    this.globalBlockingError = { title: title || 'Incorrect Access Link', desc: desc || 'The link you used is incorrect or invalid. Please check the link and try again.' };
  }

  authPing() {
    const now = moment();
    if (!this._nextAuthPing || now.isAfter(this._nextAuthPing)) {
      this._nextAuthPing = now.add(AUTH_POLL_MIN_SECONDS, 's');
      this._retry(true, true).catch(() => {}).done();
    }
  }

  @action _retry(isQuiet, skipLoginCheck) {
    if (!isQuiet) {
      this.user = null;
      this.needsTerms = null;
      this.needs2FA = null;
      this.needsDetails = null;
      this._waitingForLogin = false;
    }

    // We might be in the middle of logging in
    if (this._loginDeferred) {
      if (!skipLoginCheck) {
        this._loginDeferred.resolve();
      }
      return this._lastestRetryPromise;
    }

    this._attempting = true;
    this._quiet = isQuiet;
    const counter = this._userRetryId = requestCounter++;
    this._lastestRetryPromise = this._getCurrentUser()
      .then(action(user => {
        if (counter !== this._userRetryId) { return null; }

        this.user = null;
        this.needsTerms = null;
        this.needs2FA = null;
        this.needsDetails = null;

        if (user.needs2FA) {
          // Need to go through 2FA
          this.needs2FA = user;
        } else if (user.needsTerms) {
          // Needs to accept terms and conditions
          this.needsTerms = user;
        } else if (user.needsDetails) {
          // Needs to setup org details
          this.needsDetails = user;
        } else {
          // Now we have an actual user
          moment.tz.setDefault(user.userTimezone || user.timezone);
          rebindDateFormats(user.country, user.isUsCulture);
          rebindCulture(user.culture);
          this.user = user;
        }

        this._attempting = false;
        this._waitingForLogin = false;
        this._quiet = false;

        // Don't need to worry about polling for a while...
        this._nextAuthPing = moment().add(AUTH_POLL_MIN_SECONDS, 's');

        return user;
      }))
      .catch(action((err) => {
        if (counter !== this._userRetryId) { return null; }

        this._attempting = false;
        this._quiet = false;
        this._waitingForLogin = !this.hasFixedToken;
        throw err;
      }))
      .finally(() => {
        this._lastestRetryPromise = null;
      });

    return this._lastestRetryPromise;
  }

  redirectToBrandUrl(domain) {
    const l = window.location;
    let url = `${l.protocol}//${domain}${window.location.pathname}`;
    if (_sessionToken) {
      url += `#session_token=${_sessionToken}`;
    } else if (_accessToken) {
      url += `#access_token=${_accessToken}`;
    } else if (_calendarToken) {
      url += `#calendar_token=${_calendarToken}`;
    } else if (this.user?.auth) {
      const a = this.user.auth;
      url += `#init_session=${a.sessionId}:${a.verification}`;
    }
    window.location.href = url;
  }

  dispose() {
    this.orgRefreshDispose();
    this._loginDeferred = null;
  }

  _getCurrentUser() {
    // Handle cases when a window ends up inside another window
    if (window !== window.top) {
      try {
        window.top.postMessage({ type: 'auth.required', forceRefresh: true }, window.location.origin);
      } catch {}
    }

    let url = 'currentuser';
    let urlPromise = Promise.resolve();
    if (_sessionToken) {
      url = `${url}?session_token=${_sessionToken}`;
    } else if (_accessToken) {
      url = `${url}?access_token=${_accessToken}`;
    } else if (_calendarToken) {
      urlPromise = baseApi.post(`calendar/token/${_calendarToken}`)
        .then(res => {
          url = `${url}?access_token=${res.data.token}`;
        });
    } else if (_initSession) {
      const parts = _initSession.split(':');
      if (parts.length === 2) {
        urlPromise = baseApi.post('auth/login/session', { sessionId: parts[0], verification: parts[1] }).catch(() => {});
      }
      _initSession = null;
    }

    return urlPromise
      .then(() => baseApi.get(url))
      .then(resp => {
        const { orgId, auth } = resp.data;
        // If we are expecting a certain org, then we might need to switch to it
        if (!_hasFixedToken && expectedOrgId && orgId != expectedOrgId) { // eslint-disable-line eqeqeq
          return dialog.show({
            title: 'Switch Account',
            description: 'You are currently signed into the wrong account. Select Switch to continue.',
            primaryButton: 'Switch'
          })
            .then(() => this._switchRequest(expectedOrgId, auth.sessionId))
            .catch(err => {
              if (err && err.response && err.response.status === 401) {
                return this._retry(false)
                  .then(() => this._switchRequest(expectedOrgId, auth.sessionId));
              } else {
                throw err;
              }
            })
            .then(() => baseApi.get(url));
        }
        return resp;
      })
      .then(resp => {
        // Don't remember the orgId after a login
        expectedOrgId = null;

        const user = resp.data;
        return loadLang(user.culture).then((l) => {
          user.lang = l.lang;
          user.meta = l.meta;
          return user;
        });
      })
      .catch(r => {
        if (!_hasFixedToken && r.response && r.response.status === 401) {
          return this._waitForLogin();
        } else {
          if (_hasFixedToken && r.response && r.response.status === 401) { this.setNotValidLink(); }
          throw r;
        }
      });
  }

  @action _waitForLogin() {
    this._waitingForLogin = true;
    this._waitForLoginPromise = this._waitForLoginPromise || new Promise((resolve, reject) => {
      const resolveWrapper = () => {
        this._loginDeferred = null;
        this._waitForLoginPromise = null;
        resolve();
      };
      const rejectWrapper = err => {
        this._loginDeferred = null;
        this._waitForLoginPromise = null;
        reject(err);
      };
      this._loginDeferred = { resolve: resolveWrapper, reject: rejectWrapper };

      // If we are already in the middle of an attempt, or trying to get terms or details,
      // don't retry otherwise it will reset the process
      if (!this._attempting && !this.needsTerms && !this.needsDetails) {
        this._retry(false).catch(e => {}).done();
      }
    });

    // Wrap in a new promise so that cancellations do not propagate down
    // we want to reuse the token for requests that haven't even started yet
    return new Promise((resolve, reject) => this._waitForLoginPromise.then(resolve, reject).done())
      .then(() => this._getCurrentUser());
  }

  _switchRequest(orgId, csrf) {
    return baseApi.post(`auth/switchto/${orgId}`, null, { headers: { 'X-CSRF-TOKEN': csrf } });
  }
}

export default new AuthService();