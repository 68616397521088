import { computed, observable, action, makeObservable, flow } from 'decorators';
import auth from 'services/auth';
import baseApi from 'utils/baseApi';
import { getVideoBlocksPricing, VideoHoursPerBlock } from './utils';
import userService from 'services/data/user';
import Form, { isRequired, isInt } from 'components/form';

import { getPlanInfo } from './subscription.store';

export const getCurrentVideoBlocksPricing = () => {
  const details = userService.orgPaymentDetails.value;
  const plan = getPlanInfo(details);
  if (!details || !plan) { return ''; }
  return getVideoBlocksPricing(details.videoBlocks, plan.isAnnual);
};

export default class VideoBlocksStore {
  @observable videoBlocksForm = null;

  constructor() {
    makeObservable(this);
  }

  dispose() {}

  @computed get details() {
    return userService.orgPaymentDetails.value;
  }

  @computed get plan() {
    return getPlanInfo(this.details);
  }

  @computed get videoBlocksDescription() {
    if (!this.videoBlocksForm) { return ''; }
    const videoBlocks = this.videoBlocksForm.$('blocks').value;
    const { isAnnual } = this.plan;
    return `Press Pay Now to subscribe to ${videoBlocks}x Video Storage Blocks, ${getVideoBlocksPricing(videoBlocks, isAnnual, true)}`;
  }

  @computed get videoBlocksHasChange() {
    if (!this.videoBlocksForm) { return false; }
    return this.details.videoBlocks !== this.videoBlocksForm.$('blocks').value;
  }

  @action editVideoBlocks() {
    this.videoBlocksForm = new Form({
      fields: [
        {
          name: 'blocks',
          label: 'Number of Video Storage Blocks (1 Block = 10 hrs of video)',
          type: 'number',
          value: this.details.videoBlocks,
          validators: [ isRequired, isInt({ gte: 0 }), ({ field }) => {
            if (!field.value) { return [ true, null ]; }
            const minBlocks = Math.ceil((userService.videoStats.value?.stored || 0) / (60 * VideoHoursPerBlock));
            return [ Number(field.value) >= minBlocks, `Must have at least ${minBlocks} blocks due to saved videos` ];
          } ]
        }
      ]
    });
  }

  @action closeVideoBlocksEdit() {
    this.videoBlocksForm = null;
  }

  @flow *saveVideoBlocks() {
    yield this.videoBlocksForm.submitPromise();

    const data = this.videoBlocksForm.values();
    yield baseApi.post('subscription/videoblocks', { number: data.blocks }, auth.getCSRFConfig());
    yield auth.retry(true, true);

    this.closeVideoBlocksEdit();
  }
}