import { cloneElement, memo, forwardRef } from 'react';
import cc from 'classnames';
import { makeStyles } from 'hooks';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  emptyWrapper: {
    maxWidth: theme.spacing(75),
    margin: `${theme.spacing(8)}px auto`
  },
  icon: {
    color: theme.palette.text.disabled,
    width: theme.spacing(16),
    height: theme.spacing(16)
  },
  smallIcon: {
    width: theme.spacing(4),
    height: theme.spacing(4)
  }
}));

export default memo(forwardRef(function EmptyFiller({ center, small, icon, header, subheader, className, ...other }, ref) {
  const classes = useStyles();
  const Icon = icon ? cloneElement(icon, { className: cc(icon.props.className, classes.icon, small && classes.smallIcon) }) : null;

  const res = <Typography ref={center ? undefined : ref} align="center" component="div" className={cc(className, !center && classes.emptyWrapper)} {...other}>
    <Grid container direction={small ? undefined : 'column'} spacing={2} alignItems="center">
      { !!icon &&
        <Grid item>
          { Icon }
        </Grid>
      }
      <Grid>
        { header &&
          <Typography variant={small ? 'h5' : 'h4'}>
            { header }
          </Typography>
        }
        { subheader &&
          <Typography variant="subtitle1">
            { subheader }
          </Typography>
        }
      </Grid>
    </Grid>
  </Typography>;

  return center ?
    <Grid container direction="column" spacing={2} ref={ref}>
      <Grid item xs />
      <Grid item>
        { res }
      </Grid>
      <Grid item xs />
    </Grid>
    :
    res;
}));