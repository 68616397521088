import { makeStyles } from 'hooks';

export default makeStyles(theme => ({
  modalContainer: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
    '-webkit-overflow-scrolling': 'touch',
    padding: theme.spacing(1),

    // This centers the modals, don't do it on mobile
    // It's a bit nicer since you avoid the keyboard more times
    [theme.breakpoints.up('xs')]: {
      display: 'flex'
    }
  },
  modelNoOutline: {
    outline: 'none'
  },
  modal: {
    margin: '0 auto',
    pointerEvents: 'auto',

    [theme.breakpoints.up('md')]: {
      width: 700
    }
  },
  modalHeader: {
    padding: theme.spacing(2),
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    position: 'relative',
    ...theme.typography.headline
  },
  closeContainer: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1)
  },
  modalHeaderClose: {
    color: 'rgba(0, 0, 0, 0.3)'
  },
  modalContent: {
    padding: theme.spacing(2),
    position: 'relative'
  },
  maxWidth: {
    maxWidth: '100%',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  noPadding: {
    padding: '0 !important'
  }
}));