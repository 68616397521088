import { observable, computed, action, makeObservable } from 'decorators';

export default class VirtualListStore {
  @observable heightMapping = new Map();
  @observable itemHeight = 0;
  @observable estimatedItemHeight = 0;
  @observable itemBuffer = 0;
  @observable highlightIndex = null;
  @observable.ref items = [];

  @observable _containerNode = null;
  @observable _innerNode = null;

  @observable _viewTop = null;
  @observable _viewHeight = null;
  @observable _listTop = null;

  _tmpHeightStore = new Map();

  constructor() {
    makeObservable(this);
  }

  dispose() {
    if (this._heightRefreshTm) { clearTimeout(this._heightRefreshTm); this._heightRefreshTm = null; }
  }

  @action setContainerNode(node) {
    this._containerNode = node;
    this._syncElements();
  }

  @action setInnerNode(node) {
    this._innerNode = node;
    this._syncElements();
  }

  setHeight(index, height) {
    this._tmpHeightStore.set(index, height);
    if (!this._heightRefreshTm) {
      this._heightRefreshTm = setTimeout(() => this._refreshHeights(), 1);
    }
  }

  @action syncProps({ items, itemHeight, estimatedItemHeight, itemBuffer, highlightIndex }) {
    this.items = items;
    this.itemHeight = itemHeight;
    this.estimatedItemHeight = estimatedItemHeight;
    this.itemBuffer = itemBuffer || 0;
    this.highlightIndex = highlightIndex;
  }

  @action onScroll() {
    this._syncElements();
  }

  @action scrollToIndex(index) {
    const top = this.itemHeight
      ? index * this.itemHeight
      : this.items.slice(0, index).reduce((acc, i) => acc + (this.heightMapping.get(index) || this.estimatedItemHeight), 0);

    if (this._containerNode === window) {
      window.document.documentElement.scrollTop = top;
    } else {
      this._containerNode.scrollTop = top;
    }
  }

  @computed get styles() {
    const { firstItemIndex, lastItemIndex } = this._bounds || { firstItemIndex: 0, lastItemIndex: -1 };

    const items = this.items;
    const visibleItems = (lastItemIndex > -1 ? items.slice(firstItemIndex, lastItemIndex + 1) : [])
      .map((i, indx) => ({ item: i, index: indx + firstItemIndex, style: { height: this.itemHeight || this.heightMapping.get(indx + firstItemIndex) || this.estimatedItemHeight } }));

    let height, paddingTop;
    if (this.itemHeight) {
      height = items.length * this.itemHeight;
      paddingTop = firstItemIndex * this.itemHeight;
    } else {
      paddingTop = 0;
      height = items.reduce((acc, current, index) => {
        if (index === firstItemIndex) { paddingTop = acc; }
        return acc + (this.heightMapping.get(index) || this.estimatedItemHeight);
      }, 0);
    }

    return {
      items: visibleItems,
      style: {
        height,
        paddingTop
      }
    };
  }

  @computed get highlightTop() {
    if (this.highlightIndex == null) { return null; }

    const index = Math.min(this.items.length, this.highlightIndex);
    if (index === 0) { return 0; }
    return this.itemHeight ? index * this.itemHeight : [ ...Array(index).keys() ].reduce((acc, i) => acc + (this.heightMapping.get(i) || this.estimatedItemHeight));
  }

  @computed get _bounds() {
    // early return if we can't calculate
    if (this._viewTop == null || this._viewHeight == null || (!this.itemHeight && !this.estimatedItemHeight) || this.items.length === 0) {
      return undefined;
    }

    const itemsLength = this.items.length;

    // what the user can see
    const viewBottom = this._viewTop + this._viewHeight;

    const listViewTop = Math.max(0, this._viewTop - this._listTop); // top y-coordinate of list that is visible inside view
    const listViewBottom = Math.max(0, viewBottom - this._listTop); // bottom y-coordinate of list that is visible inside view

    let firstIndex = 0;
    let lastIndex = itemsLength;

    if (this.itemHeight) {
      // visible item indexes
      firstIndex = Math.floor(listViewTop / this.itemHeight);
      lastIndex = Math.ceil(listViewBottom / this.itemHeight);
    } else {
      let currentHeight = 0;
      let hasFirstIndex = false;
      for (let i = 0; i < itemsLength; i++) {
        const itemHeight = this.heightMapping.get(i) || this.estimatedItemHeight;
        currentHeight = currentHeight + itemHeight;

        if (!hasFirstIndex && currentHeight >= listViewTop) {
          firstIndex = i;
          hasFirstIndex = true;
        }

        if (currentHeight >= listViewBottom) {
          lastIndex = i;
          break;
        }
      }
    }

    const firstItemIndex = Math.max(0, firstIndex - this.itemBuffer);
    const lastItemIndex = Math.min(Math.max(0, itemsLength - 1), lastIndex + this.itemBuffer);

    return { firstItemIndex, lastItemIndex };
  }

  @action _refreshHeights() {
    this._heightRefreshTm = null;
    this._tmpHeightStore.forEach((value, key) => {
      this.heightMapping.set(key, value);
    });
    this._tmpHeightStore.clear();
    this._syncElements();
  }

  @action _syncElements() {
    const hasElements = this._containerNode && this._innerNode;
    this._viewTop = hasElements ? getElementTop(this._containerNode) : null; // top y-coordinate of viewport inside container
    this._viewHeight = hasElements ? (this._containerNode.innerHeight || this._containerNode.clientHeight) : null;
    this._listTop = hasElements ? (topFromWindow(this._innerNode) - topFromWindow(this._containerNode)) : null; // top y-coordinate of container inside window
  }
}

function getElementTop(element) {
  if (element.pageYOffset) return element.pageYOffset;

  if (element.document) {
    if (element.document.documentElement && element.document.documentElement.scrollTop) return element.document.documentElement.scrollTop;
    if (element.document.body && element.document.body.scrollTop) return element.document.body.scrollTop;

    return 0;
  }

  return element.scrollY || element.scrollTop || 0;
};

function topFromWindow(element) {
  if (typeof element === 'undefined' || !element) return 0;

  return (element.offsetTop || 0) + topFromWindow(element.offsetParent);
};