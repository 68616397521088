import api from './api';
import auth from './auth';
import store from './store';
import log from './log';
import moment from 'moment';
import config from 'config';
import { autorun } from 'decorators';
import { toQueryString } from 'utils/query';
import userService from 'services/data/user';
import media from './media';

const googleAnalytics = () => import('./googleAnalytics');

const postaffproInputId = 'postaffpro_input';

class PageTracker {
  _currentUser = null;
  _hasInitAffiliate = false;

  constructor() {
    this._watchUserDispose = autorun(() => this._watchUser());
  }

  pageChange(path) {
    // Push this to azure app insights as well
    if (window.appInsights) {
      window.appInsights.trackPageView({ uri: path });
    }
    this._refreshIntercom(false);
  }

  pushGoogleEvent(eventName, data) {
    googleAnalytics().then(ga => ga.logEvent(eventName, data));
  }

  pushFbEvent(eventName, data) {
    const pixelId = config.facebook.pixelId;
    if (IS_DEBUG || !pixelId) { return; }
    if (!this._globalFbEvents) {
      this._globalFbEvents = document.getElementById('global-fb-events');
    }
    if (!this._globalFbEvents) { return; }

    // Use images to track specific with FB, because I don't trust them
    const queryObj = {
      id: pixelId,
      ev: eventName
    };
    if (data) {
      let p;
      for (p in data) {
        if (Object.prototype.hasOwnProperty.call(data, p)) {
          queryObj[`cd[${p}]`] = data[p];
        }
      }
    }
    const img = document.createElement('img');
    img.src = 'https://www.facebook.com/tr?' + toQueryString(queryObj);
    img.height = 1;
    img.width = 1;
    this._globalFbEvents.appendChild(img);
  }

  getSupportDom() {
    return document.getElementById('intercom-container') || document.getElementsByClassName('intercom-lightweight-app')[0];
  }

  hideSupport() {
    const intercom = this.getSupportDom();
    if (!intercom) { return; }

    if (intercom.style.display !== 'none') {
      intercom.style.display = 'none';
      return true;
    }
    return false;
  }

  showSupport() {
    const intercom = this.getSupportDom();
    if (!intercom) { return; }

    intercom.style.display = 'block';
  }

  initAffiliate() {
    if (this._hasInitAffiliate || !window.PostAffTracker) { return; }

    const input = document.createElement('input');
    input.id = postaffproInputId;
    input.type = 'hidden';
    document.body.appendChild(input);
    window.PostAffTracker.writeCookieToCustomField(postaffproInputId);
    this._hasInitAffiliate = true;
  }

  getAffiliateId() {
    if (!this._hasInitAffiliate) {
      log.error('Affiliate has not been initialized');
      return null;
    }

    return document.getElementById(postaffproInputId)?.value;
  }

  dispose() {
    this._globalFbEvents = null;
    this._watchUserDispose();
  }

  _watchUser() {
    const user = auth.user;
    const google = googleAnalytics();
    if (user) {
      // If we are using azure insights then mark the new user here
      if (window.appInsights) {
        window.appInsights.setAuthenticatedUserContext(user.id + '', user.orgId + '');
      }
      google.then(ga => ga.setUser(user.id, user.orgId));
    } else if (this._currentUser) {
      if (window.appInsights) {
        window.appInsights.clearAuthenticatedUserContext();
      }
      google.then(ga => ga.setUser(null, null));
    }

    this._currentUser = user;
    this._refreshIntercom(true);
  }

  _refreshIntercom(force) {
    const user = this._currentUser;
    if (!user || !user.roles.isClinician) {
      if (window.Intercom) { window.Intercom('shutdown'); }
      return;
    }

    this._init();

    let waitFor = Promise.resolve();
    if (force) {
      waitFor = userService.orgPaymentDetails.waitForValue()
        .then(payments => {
          const userData = this._mapUserData(user, payments);
          window.Intercom('boot', userData);
          return null;
        });
    } else {
      window.Intercom('update', { user_id: user.id + '', email: user.email, last_request_at: moment().unix() });
    }

    const globalstatsKey = `kalix_globalstats_${user.id}_${user.orgId}`;
    if (!store.localStore.get(globalstatsKey)) {
      // Short timeout initially to allow a save
      store.localStore.set(globalstatsKey, true, moment().valueOf() + 180000);
      waitFor
        .then(() => api.get('globalstats'))
        .then(res => {
          const stats = res.data?.stats;
          if (stats) {
            // If we get stats don't even bother for another hour...
            store.localStore.set(globalstatsKey, true, moment().valueOf() + 3600000);
            stats.app_id = config.intercom.key;
            stats.user_id = user.id + '';
            stats.email = user.email;
            stats.user_hash = user.intercom_hash;

            window.Intercom('update', stats);
          }
        })
        .done();
    }
  }

  _init() {
    if (this._isInit) { return; }

    (function() {
      const w = window; const ic = w.Intercom;
      if (typeof ic === 'function') {
        ic('reattach_activator');
      } else {
        const d = document;
        const i = function() { i.c(arguments); };
        i.q = [];
        i.c = function(args) { i.q.push(args); };
        w.Intercom = i;
        const s = d.createElement('script'); s.type = 'text/javascript'; s.async = true;
        s.src = `https://widget.intercom.io/widget/${config.intercom.key}`;
        const x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      }
    })();

    // Hide the messenger when starting it up
    window.Intercom('hide');
    this._isInit = true;
  }

  _mapUserData(user, payments) {
    if (!user || !user.roles.isClinician) { return null; }

    let company = {
      id: user.orgId + '',
      name: user.orgName,
      country: user.country,
      timezone: user.timezone,
      brand: user.brand?.name
    };

    if (payments) {
      company = Object.assign(company, {
        plan: payments.plan,
        created_at: moment(payments.firstPayment || user.created).unix(),
        monthly_spend: payments.monthlySpend,
        first_payment_at: payments.firstPayment ? moment(payments.firstPayment).unix() : null,
        next_payment_at: moment(payments.nextPayment).unix(),
        professions: payments.professions,
        hasReferred: payments.hasReferred,
        isReferred: payments.isReferred,
        coupon: payments.coupon,
        lastCancelReason: payments.lastCancelReason,
        lastCancelInfo: payments.lastCancelInfo
      });
    };

    return {
      user_id: user.id + '',
      name: user.name,
      email: user.email,
      created_at: moment(user.created).unix(),
      app_id: config.intercom.key,
      user_hash: user.intercom_hash,
      role: user.roles.role,
      timezone: user.userTimezone || user.timezone,
      company,
      hide_default_launcher: false,
      screen_size: media.current
    };
  }
}

export default new PageTracker();