import { Fragment, memo, useId } from 'react';
import { makeStyles } from 'hooks';
import cc from 'classnames';
import { Grid, Typography, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  outerDiv: {
    textAlign: 'center',
    margin: theme.spacing(4)
  },
  title: {
    padding: theme.spacing(2, 1, 0, 1)
  },
  inline: {
    paddingTop: 0,
    verticalAlign: 'text-bottom'
  }
}));

export default memo(function SpinnerView(props) {
  if (props.inline) { return <Spinner {...props} />; }

  return <Grid container spacing={0} direction="column" alignItems="center" style={{ height: '100%' }}>
    <Grid item xs />
    <Grid item>
      <Spinner {...props} />
    </Grid>
    <Grid item xs />
  </Grid>;
});

const Spinner = memo(function Spinner({ text, size, small, inline, ...other }) {
  const classes = useStyles();
  const id = useId();

  const frag = <Fragment>
    <CircularProgress size={size || (small ? 24 : 100)} aria-labelledby={id} aria-label={text ? undefined : 'Loading...'} {...other} />
    { text && <Typography variant="h5" component={inline ? 'span' : 'div'} className={cc(classes.title, inline && classes.inline)} id={id}>{text}</Typography> }
  </Fragment>;
  if (small) { return frag; }
  return <div className={classes.outerDiv}>
    { frag }
  </div>;
});