import React, { memo, forwardRef, useState, useEffect, useRef } from 'react';
import cc from 'classnames';
import { observer, action } from 'decorators';
import { makeStyles, combineRefs } from 'hooks';
import { Typography, Switch, Checkbox, Radio, Grid, Paper, FormControl, FormHelperText, FormGroup, FormControlLabel, FormLabel, Input as MaterialInput, InputLabel, InputAdornment, IconButton, Tooltip } from '@material-ui/core';
import Select, { SelectAsync, SelectText } from 'components/select';
import DropDownButton from 'components/dropdown';
import AsyncView from 'components/asyncView';
import { fpFix, currencyOnLeft } from 'utils/number';
import auth from 'services/auth';
import media from 'services/media';

import { HelpCircleOutline } from 'mdi-material-ui';
import FilenameInput from './filename';

// We will async load most of the components, since these are large than most
const DatePicker = () => import('components/datepicker');
const TimePicker = () => import('components/timepicker');
const DateTimePicker = () => import('components/datetimePicker');
const TimezonePicker = () => import('components/timezonePicker');
const ColorPicker = () => import('components/colorPicker');
const Redactor = () => import('components/redactor');
const Signature = () => import('components/signature');

export FilenameInput from './filename';
export AddressInput from './address';
export NameInput from './name';

export const Form = memo(forwardRef(function Form({ autoComplete, children, ...other }, ref) {
  return <form ref={ref} noValidate autoComplete={autoComplete ? 'on' : 'off'} role="none" {...other}>
    { !autoComplete && // Auto-complete hack - need to have one input with autocomplete otherwise browsers will autofill
      <input type="text" hidden />
    }
    { children }
  </form>;
}));

export const FormLabelWithInfo = memo(forwardRef(function FormLabelWithInfo({ children, title, ...other }, ref) {
  if (!title) { return <FormLabel ref={ref} {...other}>{children}</FormLabel>; }

  return media.is('xs') ?
    <Grid container alignItems="center">
      <Grid item xs>
        <FormLabel ref={ref} {...other}>{children}</FormLabel>
      </Grid>
      <Grid item>
        <InputInfoIcon title={title} />
      </Grid>
    </Grid>
    :
    <FormLabel ref={ref} {...other}>
      { children }
      <InputInfoIcon inline title={title} />
    </FormLabel>;
}));

const useInputStyles = makeStyles(theme => ({
  infoAdornment: {
    maxHeight: 'none !important'
  },
  checkboxContainer: {
    marginTop: 6,
    marginBottom: -6
  },
  radioContainer: {
    paddingTop: `${theme.spacing(2)}px !important`,
    paddingBottom: '0 !important'
  },
  characterCount: {
    flexShrink: 0,
    marginRight: theme.spacing(1)
  },
  number: {
    '-moz-appearance': 'textfield',
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none'
    }
  }
}));

const noop = () => {};
export default observer(forwardRef(function Input({ form, field, onRefresh: handleRefresh, allowLastPass, ...other }, outerRef) {
  const classes = useInputStyles();
  const finalProps = form?.getFullFieldData(field, other);

  // Only used for selectasync
  const [ currentValue, setCurrentValue ] = useState(finalProps?.initialValue);

  useEffect(handleRefresh || noop, [ finalProps?.error ]); // eslint-disable-line

  if (!finalProps) { return null; }

  let { helperText, label, shrink, InputProps, type, initialValue, autoCalcInitialValue, onValueChanged, info, endAdornment, currency, maxLength, tooltip, autoComplete, ref, onlyControl, ...otherFinal } = finalProps;
  const { error, disabled, fullWidth, margin, required, readOnly, placeholder } = finalProps;
  otherFinal.ref = combineRefs(ref, outerRef);

  // Should use shrink style on all labels in mobile...
  shrink = shrink || media.is('xs');

  endAdornment = InputProps.endAdornment || endAdornment;
  if (info) {
    const infoAdornment = <InputAdornment key="info" position="end"><InputInfoIcon title={info} className={classes.infoAdornment} direction="right" /></InputAdornment>;
    if (Array.isArray(endAdornment)) {
      endAdornment.push(infoAdornment);
    } else {
      endAdornment = endAdornment ? [ React.cloneElement(endAdornment, { key: 'end' }), infoAdornment ] : infoAdornment;
    }
  }
  if (maxLength) {
    const length = (otherFinal.value || '').length || 0;
    const isLonger = length > maxLength;
    const lengthAdornment = <InputAdornment key="length" position="end">
      <Typography color={isLonger ? 'error' : undefined} className={classes.characterCount}>
        {length} / {maxLength}
      </Typography>
    </InputAdornment>;
    if (Array.isArray(endAdornment)) {
      endAdornment.push(lengthAdornment);
    } else {
      endAdornment = endAdornment ? [ React.cloneElement(endAdornment, { key: 'end' }), lengthAdornment ] : lengthAdornment;
    }
  }
  InputProps = Object.assign({}, InputProps, { endAdornment });

  // TODO: Enable phone input type
  if (type === 'phone') { type = 'text'; }

  let control = null;
  let fullControl = null;
  let forceFilled = readOnly ? true : undefined;
  if (typeof type === 'string' && type !== 'div') {
    switch (type) {
      case 'date': {
        control = <AsyncView hideSpinner view={DatePicker} {...otherFinal} {...InputProps} onRefresh={handleRefresh} />;
        if (otherFinal.range) { forceFilled = true; }
        break;
      }
      case 'time': {
        control = <AsyncView hideSpinner view={TimePicker} {...otherFinal} {...InputProps} onRefresh={handleRefresh} />;
        forceFilled = true;
        break;
      }
      case 'datetime': {
        const formControlProps = {
          error,
          disabled,
          fullWidth,
          margin,
          required,
          label,
          id: finalProps.id,
          helperText: form.$(field).error || helperText
        };
        fullControl = <AsyncView hideSpinner view={DateTimePicker} {...otherFinal} {...InputProps} formControlProps={formControlProps} onRefresh={handleRefresh} />;
        break;
      }
      case 'switch': {
        const { text, value, onChange, fullWidth, error, disableUnderline, disabled, readOnly, labelPlacement, labelClasses, ...otherSwitch } = otherFinal;
        const switchDisabled = disabled || readOnly;
        const handleChanged = (e, checked) => onChange && onChange(checked);
        let swi = <Switch checked={value} onChange={handleChanged} disabled={switchDisabled} {...otherSwitch} />;
        if (text || info) {
          swi = <FormControlLabel
            control={swi}
            labelPlacement={labelPlacement}
            classes={labelClasses}
            label={<span>{text}{info && !media.is('xs') && <InputInfoIcon inline title={info} />}</span>}
          />;
        }
        control = <FormGroup>{swi}</FormGroup>;
        forceFilled = true;
        break;
      }
      case 'checkbox': {
        const { text, value, onChange, fullWidth, error, disabled, readOnly, disableUnderline, ...otherCheckbox } = otherFinal;
        const handleChanged = e => onChange && onChange(e.target.checked);
        const swi = <Checkbox checked={value} disabled={disabled || readOnly} onChange={handleChanged} {...otherCheckbox} />;
        control = <FormGroup>
          { text || info ?
            <FormControlLabel control={swi} label={<span>{text}{info && <InputInfoIcon inline title={info} />}</span>} />
            :
            swi
          }
        </FormGroup>;
        forceFilled = true;
        break;
      }
      case 'buttonToggle': {
        const { value, onChange, fullWidth, error, disabled, readOnly, disableUnderline, IconTrue, IconFalse, titleTrue, titleFalse, inputProps, ...otherToggle } = otherFinal;
        let inner = React.createElement(value ? IconTrue : IconFalse);
        if (value && titleTrue) { inner = <Tooltip title={titleTrue}>{inner}</Tooltip>; }
        if (!value && titleFalse) { inner = <Tooltip title={titleFalse}>{inner}</Tooltip>; }
        const handleChanged = e => onChange && onChange(!value);
        control = <IconButton disabled={disabled || readOnly} onClick={handleChanged} {...otherToggle}>{ inner }</IconButton>;
        forceFilled = true;
        break;
      }
      case 'textarea': {
        const { value, ...innerFinal } = otherFinal;
        control = <MaterialInput multiline maxRows="5" autoComplete={autoComplete || 'off'} value={value == null ? '' : value} {...innerFinal} {...InputProps} />;
        break;
      }
      case 'redactor': {
        control = <AsyncView hideSpinner view={Redactor} {...otherFinal} {...InputProps} onRefresh={handleRefresh} />;
        break;
      }
      case 'checkboxes': {
        const handleCheckBoxChanged = action((v) => {
          const current = finalProps.value.slice();
          const index = current.indexOf(v);
          if (index === -1) {
            current.push(v);
          } else {
            current.splice(index, 1);
          }

          form.update({ [field]: current });
        });

        control = <Grid container spacing={2}>
          {finalProps.options.map(i => (
            <Grid key={i.value} item xs>
              <FormControlLabel
                label={i.name}
                className={classes.checkboxContainer}
                control={
                  <Checkbox
                    checked={finalProps.value.includes(i.value)}
                    onChange={() => handleCheckBoxChanged(i.value)}
                    value={'' + i.value}
                  />
                }
              />
            </Grid>
          ))}
        </Grid>;

        forceFilled = true;
        break;
      }
      case 'radios': {
        control = <Grid container spacing={finalProps.spacing == null ? 2 : finalProps.spacing} direction={finalProps.direction}>
          { finalProps.options.map(i => {
            const handleChange = () => finalProps.onChange && finalProps.onChange(i.value);
            return <Grid key={i.value} item xs className={cc(label && classes.radioContainer)}>
              <FormControlLabel
                label={i.name}
                control={
                  <Radio
                    checked={finalProps.value === i.value}
                    onChange={handleChange}
                    value={'' + i.value}
                  />
                }
              />
            </Grid>;
          })}
        </Grid>;

        forceFilled = true;
        break;
      }
      case 'select': {
        let { valueKey, multiple, displayEmpty } = otherFinal;
        valueKey = valueKey || 'value';

        if (otherFinal.onChange) {
          const originalChange = otherFinal.onChange;
          if (multiple) {
            otherFinal.onChange = action(e => {
              form.update({ [field]: e.map(v => v[valueKey]) });
            });
          } else {
            otherFinal.onChange = (e) => {
              originalChange(e[valueKey]);
            };
          }
        }
        control = <Select autoComplete={autoComplete || 'off'} {...otherFinal} {...InputProps} />;

        // If select is displaying empty, then always shrink the label
        if (displayEmpty) { forceFilled = true; }
        break;
      }
      case 'selectasync': {
        const { multiple, onChange, onSelect } = otherFinal;
        if (multiple) {
          otherFinal.onChange = m => {
            onValueChanged && onValueChanged(m);
            form.update({ [field]: m });
          };
          forceFilled = otherFinal.value?.length;
        } else {
          otherFinal.onChange = m => {
            setCurrentValue(m);
            onValueChanged && onValueChanged(m);
            onChange && onChange(m ? m[otherFinal.valueKey || 'value'] : null);
          };
          if (onSelect) {
            otherFinal.onSelect = m => {
              if (m == null) { setCurrentValue(null); }
              onSelect && onSelect(m);
            };
          }
          let current = currentValue;
          if ((current && current[otherFinal.valueKey || 'value'] !== otherFinal.value) || (otherFinal.value && !current)) {
            current = initialValue;
            if (!current && autoCalcInitialValue) {
              current = { [otherFinal.nameKey || 'name']: otherFinal.value, [otherFinal.valueKey || 'value']: otherFinal.value };
            }
          }
          otherFinal.value = current;
        }
        control = <SelectAsync {...otherFinal} {...InputProps} hasLabel={!!label} />;
        break;
      }
      case 'selecttext': {
        otherFinal.onChange = action((m) => {
          onValueChanged && onValueChanged(m);
          form.update({ [field]: m });
        });
        control = <SelectText {...otherFinal} {...InputProps} />;
        break;
      }
      case 'timezone': {
        control = <AsyncView hideSpinner view={TimezonePicker} {...otherFinal} {...InputProps} onRefresh={handleRefresh} />;
        break;
      }
      case 'money': {
        if (otherFinal.onChange) {
          const originalChange = otherFinal.onChange;
          otherFinal.onChange = e => {
            const val = e.target.value ? fpFix(e.target.value) : '';
            originalChange(val);
          };
        }

        const isLeft = currencyOnLeft();
        const posType = isLeft ? 'startAdornment' : 'endAdornment';
        const position = isLeft ? 'start' : 'end';
        if (!InputProps[posType]) {
          const c = currency || auth.user?.currency || '$';
          InputProps[posType] = <InputAdornment position={position}>{c}</InputAdornment>;
        }
        control = <MaterialInput autoComplete={autoComplete || 'off'} type="number" classes={{ input: classes.number }} {...otherFinal} {...InputProps} />;
        break;
      }
      case 'filename': {
        control = <FilenameInput {...otherFinal} {...InputProps} />;
        break;
      }
      case 'signature': {
        control = <AsyncView hideSpinner view={Signature} {...otherFinal} {...InputProps} onRefresh={handleRefresh} />;
        break;
      }
      case 'color': {
        control = <AsyncView hideSpinner view={ColorPicker} {...otherFinal} {...InputProps} onRefresh={handleRefresh} />;
        break;
      }
      default: {
        const classOverride = type === 'number' ? { input: classes.number } : undefined;
        const { value, ...innerFinal } = otherFinal;
        control = <InputWrapper autoComplete={autoComplete || 'off'} value={value == null ? '' : value} type={type} classes={classOverride} {...innerFinal} {...InputProps} allowLastPass={allowLastPass} />;
        break;
      }
    }
  } else {
    const TypeComponent = type;
    if (type === 'div') {
      const { inputProps, inputComponent, fullWidth, error, ...other } = otherFinal;
      otherFinal = other;
    }
    control = <TypeComponent {...otherFinal} {...(type === 'div' ? {} : InputProps)} />;
  }

  if (tooltip && control) {
    control = <Tooltip title={tooltip}><div>{control}</div></Tooltip>;
  }

  if (onlyControl) { return control; }

  fullControl = fullControl || <FormControl error={error} disabled={disabled} fullWidth={fullWidth} margin={margin} required={required}>
    { label &&
      <InputLabel htmlFor={finalProps.id} shrink={(shrink || forceFilled || !!placeholder) ? true : undefined}>
        {label}
      </InputLabel>
    }
    { control }
    { helperText && <FormHelperText>{form.$(field).error || helperText}</FormHelperText> }
  </FormControl>;

  if (type === 'switch' && info && media.is('xs')) {
    fullControl = <Grid container alignItems="center"><Grid item xs>{fullControl}</Grid><Grid item><InputInfoIcon title={info} direction="right" /></Grid></Grid>;
  }

  return fullControl;
}));

export const InputWrapper = memo(forwardRef(function InputWrapper({ type, inputProps, allowLastPass, ...props }, ref) {
  const numRef = useRef();

  if (!allowLastPass) {
    inputProps = inputProps || {};
    // Disable lastpass and 1Password
    inputProps['data-lpignore'] = 'true';
    inputProps['data-1p-ignore'] = true;
  }

  // Manual fix for number types, to prevent scrolling number and up/down keys
  useEffect(() => {
    const input = numRef.current;
    if (type !== 'number' || !input) { return; }

    // Scroll prevent
    const prevDefault = e => e.preventDefault();
    const onFocus = e => input.addEventListener('wheel', prevDefault);
    const onBlur = e => input.removeEventListener('wheel', prevDefault);
    input.addEventListener('focusin', onFocus);
    input.addEventListener('focusout', onBlur);

    // Up/down key prevent
    const onKeyboard = e => {
      if ([ 38, 40 ].indexOf(e.keyCode) > -1) {
        e.preventDefault();
      }
    };
    input.addEventListener('keydown', onKeyboard);

    return () => {
      input.removeEventListener('focusin', onFocus);
      input.removeEventListener('focusout', onBlur);
      input.removeEventListener('wheel', prevDefault);
      input.removeEventListener('keydown', onKeyboard);
    };
  }, [ type ]);

  return <MaterialInput ref={combineRefs(ref, numRef)} type={type} inputProps={inputProps} {...props} />;
}));

const useInfoStyles = makeStyles(theme => ({
  infoDropdown: {
    maxWidth: theme.spacing(40),
    padding: theme.spacing(2)
  },
  infoInline: {
    margin: '-16px -8px -12px'
  }
}));

export const InputInfoIcon = memo(forwardRef(function InputInfoIcon({ title, inline, className, ...other }, ref) {
  const classes = useInfoStyles();

  return <DropDownButton ref={ref} icon buttonContent={<HelpCircleOutline />} className={cc(classes.infoButton, inline && classes.infoInline, className)} {...other}>
    <Paper className={classes.infoDropdown}>
      { title }
    </Paper>
  </DropDownButton>;
}));