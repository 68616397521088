import { memo, forwardRef, useState, useEffect } from 'react';
import Spinner from 'components/spinner';
import Button from 'components/button';
import Modal from 'components/modal';
import ErrorBoundary from 'components/errorBoundary';
import { Typography } from '@material-ui/core';
import log from 'services/log';
import offline from 'services/offline';
import theme from 'theme';

const handleReload = () => window.location.reload();

export default memo(forwardRef(function AsyncView({ view, hideSpinner, spinnerInline, onRefresh: handleRefresh, ...other }, ref) {
  const [ loadedView, setLoadedView ] = useState(null);
  const [ additionalProps, setAdditionalProps ] = useState(null);
  const [ chunkFail, setChunkFail ] = useState(false);

  useEffect(() => {
    let isClosed = false;
    let currentError = null;
    setLoadedView(null);
    setAdditionalProps(null);

    // Wrap inner promise so cancel doesn't propagate into internals
    const promise = new Promise((resolve, reject) => {
      try {
        Promise.resolve(view()).then(resolve, reject).done();
      } catch (err) {
        reject(err);
      }
    })
      .catch(err => {
        if (isClosed) { return; }

        // First failure try to make sure we are connected
        currentError = err;
        return offline.whenUp().then(() => view());
      })
      .then(v => {
        if (isClosed || !v) { return; }

        // For some reason the retry seems to return even though still an error... but with no default
        if (!v.default && currentError) { throw currentError; }

        // the result is a function, so wrap in another function
        setLoadedView(() => v.default);
        if (v.additionalProps) {
          setAdditionalProps(v.additionalProps);
        }
        return null;
      })
      .catch(err => {
        if (isClosed) { return; }

        // We were online and still failed... most likely a new page
        setChunkFail(true);
        log.error('Showed update message', err);
        return null;
      });

    return () => {
      isClosed = true;
      promise.cancel();
    };
  }, [ view ]);

  if (chunkFail) {
    return <Modal
      open disableBackdropClick
      className="text-center"
      label="Update Available"
    >
      <Typography paragraph>
        Looks like there is a newer version of {theme.brandName} available. Please reload your page.
      </Typography>
      <Typography align="right">
        <Button color="primary" onClick={handleReload}>Reload Page</Button>
      </Typography>
    </Modal>;
  }

  if (!loadedView) {
    return hideSpinner ? null : <Spinner inline={!!spinnerInline} text="Loading..." />;
  }

  return <LoadedViewWrapper view={loadedView} ref={ref} {...(additionalProps || {})} {...other} onRefresh={handleRefresh} />;
}));

const noop = () => {};
const LoadedViewWrapper = memo(forwardRef(function LoadedViewWrapper({ onRefresh, view: View, ...props }, ref) {
  useEffect(onRefresh || noop); // eslint-disable-line
  return <ErrorBoundary>
    <View ref={ref} {...props} />
  </ErrorBoundary>;
}));