import auth from 'services/auth';

export default [
  { name: 'appointments.new', path: '/appointments/new', func: router => router.navigateTo('appointments', null, null, { appointmentId: 0 }, { replace: true }) },
  { name: 'appointments', path: '/appointments', view: () => import('./appointments') },
  { name: 'appointments.list', path: '/appointments/list', sameView: 'appointments' },
  { name: 'appointments.sync', path: '/appointments/sync', sameView: 'appointments' },
  { name: 'appointments.tasks', path: '/appointments/tasks', view: () => import('./tasks') },
  { name: 'appointments.tasks.new', path: '/appointments/tasks/new', sameView: 'appointments.tasks' },
  { name: 'appointments.types', path: '/appointments/types', view: () => import('./types') },
  { name: 'appointments.availability', path: '/appointments/availability', view: () => import('./availability/availability') },
  { name: 'appointments.availability.blockout', path: '/appointments/availability/blockout', view: () => import('./availability/blockout') },
  { name: 'appointments.online', path: '/appointments/online', view: () => import('./online') },
  { name: 'appointments.online.settings', path: '/appointments/online/settings', sameView: 'appointments.online' },
  { name: 'appointments.type', path: '/appointments/types/:id', view: () => import('./type') },
  { name: 'appointments.confirm', path: '/appointments/confirm', view: () => import('./confirm'), external: true, anonymous: true },
  { name: 'appointments.appointment', path: '/appointments/:id', view: () => import('./appointment') },
  { name: 'appointments.appointment.tasks', path: '/appointments/:id/tasks', sameView: 'appointments.appointment' },
  { name: 'appointments.appointment.messages', path: '/appointments/:id/messages', sameView: 'appointments.appointment' },
  { name: 'appointments.appointment.logs', path: '/appointments/:id/logs', sameView: 'appointments.appointment' }
];

export function nav(user) {
  if (!user) {
    return [
      { name: 'appointments.confirm', navPath: 'Appointments.Confirmation' }
    ];
  }

  const items = [
    { name: 'appointments.new', title: 'Appointments', navPath: 'Appointments.New.Appointment', showNav: true },
    { name: 'appointments', title: 'Appointments', navPath: 'Appointments.Appointments.Calendar', showNav: true, isNavRoot: true },
    { name: 'appointments.list', title: 'Appointments', navPath: 'Appointments.Appointments.Reports', showNav: true },
    { name: 'appointments.sync', title: 'Appointments', navPath: 'Appointments.Appointments.Calendar Sync', showNav: true },
    { name: 'appointments.tasks', title: 'Appointments', navPath: 'Appointments.Tasks', showNav: true },
    { name: 'appointments.tasks.new', title: 'Appointments', navPath: 'Appointments.New.Task', showNav: true },
    { name: 'appointments.appointment', title: 'Appointment', navPath: 'Appointments.Appointments.Calendar' },
    { name: 'appointments.appointment.tasks', title: 'Appointment', navPath: 'Appointments.Appointments.Calendar' },
    { name: 'appointments.appointment.messages', title: 'Appointment', navPath: 'Appointments.Appointments.Calendar' },
    { name: 'appointments.confirm', navPath: 'Appointments.Confirmation' }
  ];

  if (user.roles.isAdministrator) {
    items.push({ name: 'appointments.types', title: 'Appointment Types', navPath: 'Appointments.Appointment Types', showNav: true });
    items.push({ name: 'appointments.type', title: 'Appointment Type', navPath: 'Appointments.Appointment Types' });
  }

  if (user.roles.isAdministrator || auth.hasFeature(f => f.editSelfAvailability)) {
    items.push({ name: 'appointments.availability', title: 'Availability', navPath: 'Appointments.Availability', showNav: true });
    items.push({ name: 'appointments.availability.blockout', title: 'Blockout Days', navPath: 'Appointments.Blockout Days', showNav: true });
  }

  if (user.roles.isAdministrator) {
    items.push({ name: 'appointments.online', title: 'Online Scheduling', navPath: 'Appointments.Online Scheduling', showNav: true });
    items.push({ name: 'appointments.online.settings', title: 'Online Scheduling', navPath: 'Appointments.Online Scheduling' });
  }

  if (auth.hasFeature(f => f.pro)) {
    items.push({ name: 'appointments.appointment.logs', title: 'Appointment', navPath: 'Appointments.Calendar' });
  }

  return items;
};